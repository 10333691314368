import { Button, Col, Divider, Input, Modal, Row } from 'antd';
import React, { Component } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdModeEdit } from 'react-icons/md';

const { TextArea } = Input

class ProfileInformation extends Component {
    render() {
        return (
            <div>
                <h1 className='profile-section-title'>Profile</h1>
                <Divider className='profile-section-title-divider' />
                <Row className='profile-section-row-information'>
                    <Col lg={4} md={12} sm={12} xs={12}>
                        <label>Description: </label>
                    </Col>
                    <Col lg={20} md={12} sm={12} xs={12}>
                        Lorem ipsum Lorem ipsumLorem ipsum Lorem ipsumLorem ipsum Lorem ipsumLorem ipsum Lorem ipsumLorem ipsum Lorem ipsumLorem ipsum Lorem ipsumLorem ipsum Lorem ipsumLorem ipsum Lorem ipsumLorem ipsum Lorem ipsumLorem ipsum Lorem ipsum
                    </Col>
                </Row>
                <Row className='profile-section-row-information'>
                    <Col lg={4} md={12} sm={12} xs={12}>
                        <label>Employer Legal Status: </label>
                    </Col>
                    <Col lg={20} md={12} sm={12} xs={12}>
                        Private Limited Company
                    </Col>
                </Row>
                <Row className='profile-section-row-information'>
                    <Col lg={4} md={12} sm={12} xs={12}>
                        <label>Economic Sector: </label>
                    </Col>
                    <Col lg={20} md={12} sm={12} xs={12}>
                        Banking
                    </Col>
                </Row>
                <Row className='profile-section-row-information'>
                    <Col lg={4} md={12} sm={12} xs={12}>
                        <label>No of workers: </label>
                    </Col>
                    <Col lg={20} md={12} sm={12} xs={12}>
                        5000+
                    </Col>
                </Row>
                <Row className='profile-section-row-information'>
                    <Col lg={4} md={12} sm={12} xs={12}>
                        <label>Country of issue: </label>
                    </Col>
                    <Col lg={20} md={12} sm={12} xs={12}>
                        Mauritius
                    </Col>
                </Row>
                <Row className='profile-section-row-information'>
                    <Col lg={4} md={12} sm={12} xs={12}>
                        <label>Business Registration Number: </label>
                    </Col>
                    <Col lg={20} md={12} sm={12} xs={12}>
                        BRN-444
                    </Col>
                </Row>
                <Row className='profile-section-row-information'>
                    <Col lg={4} md={12} sm={12} xs={12}>
                        <label>Business Registration Date: </label>
                    </Col>
                    <Col lg={20} md={12} sm={12} xs={12}>
                        25th December 2029
                    </Col>
                </Row>
                <Row className='profile-section-row-information'>
                    <Col lg={4} md={12} sm={12} xs={12}>
                        <label>Designation: </label>
                    </Col>
                    <Col lg={20} md={12} sm={12} xs={12}>
                        Deaushdsaudhu sabdusadb sad
                    </Col>
                </Row>

            </div>
        );
    }
}

export default ProfileInformation;