import { Button, Col, Divider, Modal, Row, Select } from 'antd';
import React, { Component } from 'react';
import { careerLevels, educationLevel, experienceYears } from '../../constants';

const { Option } = Select

class Edit_CareerSummary extends Component {
    render() {
        return (
            <div>
                <Modal
                    title="Edit career summary"
                    visible={this.props.modalEditCareerSummary}
                    destroyOnClose={true}
                    footer={[
                        <Button
                            type='primary'
                            onClick={this.props.triggerModalEditCareerSummary}
                        >
                            Done
                        </Button>
                    ]}
                    width={800}
                    onCancel={this.props.triggerModalEditCareerSummary}
                >



                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>Level: </label>
                        </Col>
                        <Col lg={16} md={24} sm={24} xs={24}>
                            <Select
                                placeholder='Current career level'
                                style={{ width: '90%' }}
                                onChange={this.props.handleChangeCareerLevel}
                                value={this.props.candidateCV.careerLevel}
                                showSearch
                            >
                                {careerLevels.map((level, index) => {
                                    return (
                                        <Option key={index} value={level}>{level}</Option>
                                    )
                                })}
                            </Select>
                        </Col>
                    </Row>
                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>Level of education: </label>
                        </Col>
                        <Col lg={16} md={24} sm={24} xs={24}>
                            <Select
                                placeholder='Level of education'
                                style={{ width: '90%' }}
                                onChange={this.props.handleChangeCareerLevelOfEducation}
                                value={this.props.candidateCV.careerLevelOfEducation}
                                showSearch
                            >
                                {educationLevel.map((level, index) => {
                                    return (
                                        <Option key={index} value={level}>{level}</Option>
                                    )
                                })}
                            </Select>
                        </Col>
                    </Row>
                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>Total years of experience: </label>
                        </Col>
                        <Col lg={16} md={24} sm={24} xs={24}>
                            <Select
                                placeholder='No. of years of experience'
                                style={{ width: '90%' }}
                                onChange={this.props.handleChangeCareerTotalYearsExperience}
                                value={this.props.candidateCV.careerTotalYearsExperience}
                                showSearch
                            >
                                {experienceYears.map((experience, index) => {
                                    return (
                                        <Option key={index} value={experience}>{experience}</Option>
                                    )
                                })}
                            </Select>
                        </Col>
                    </Row>
                </Modal>

            </div>
        );
    }
}

export default Edit_CareerSummary;