import { Avatar, Button, Col, Dropdown, Row } from 'antd';
import react, { Component } from 'react';
import logo from '../images/logo2.png';
import { Menu } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined, DownOutlined } from '@ant-design/icons';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { ImProfile } from 'react-icons/im';
import { IoSettingsSharp } from 'react-icons/io5'
import { IoMdLogOut } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { themeColor } from '../constants';

const { SubMenu } = Menu

const candidateMenu = (
    <Menu style={{ margin: '15px' }}>
        <Menu.Item key='settings' icon={<IoSettingsSharp />}>
            <Link to='/settings/'>
                Settings
            </Link>
        </Menu.Item>
        <Menu.Item key='profile' icon={<ImProfile />}>
            <Link to='/profile/'>
                Profile
            </Link>
        </Menu.Item>
        {/* <Menu.Item key='pricing' icon={<MdOutlineAttachMoney />}>
            <Link to='/pricing/'>
                Pricings
            </Link>
        </Menu.Item> */}
        <Menu.Item key='createcv' icon={<ImProfile />}>
            <Link to='/cv/create/'>
                Create CV
            </Link>
        </Menu.Item>
        <Menu.Item key='appliedjobs' icon={<IoMdLogOut />}>
            Applied Jobs
        </Menu.Item>
        <Menu.Item key='logout' danger icon={<IoMdLogOut />}>
            Logout
        </Menu.Item>
    </Menu>
);

const employerMenu = (
    <Menu style={{ margin: '15px', width: 150 }}>
        <Menu.Item key='profile' icon={<ImProfile />}>
            <Link to='/profile/'>
                Profile
            </Link>
        </Menu.Item>
        <Menu.Item key='createcv' icon={<ImProfile />}>
            <Link to='/dashboard/'>
                Dashboard
            </Link>
        </Menu.Item>
        {/* <Menu.Item key='pricing' icon={<MdOutlineAttachMoney />}>
            <Link to='/pricing/'>
                Pricings
            </Link>
        </Menu.Item> */}
        <Menu.Item key='settings' icon={<IoSettingsSharp />}>
            <Link to='/settings/'>
                Settings
            </Link>
        </Menu.Item>
        <Menu.Item key='logout' danger icon={<IoMdLogOut />}>
            Logout
        </Menu.Item>
    </Menu>
);

const topBarMenuItem = [
    {
        label: (
            <a href="#/">
                Home
            </a>
        ),
        key: 'home'
    },
    {
        label: (
            <a>
                Services
            </a>
        ),
        key: 'services',
        children: [
            {
                label: (
                    <a href="#/services/recruitment/">
                        Recruitment
                    </a>
                ),
                key: 'recruitment'
            },
            {
                label: (
                    <a href="#/services/hr-consulting/">
                        HR Consulting
                    </a>
                ),
                key: 'hr-consulting'
            },
        ],
    },
    {
        label: (
            <a href="#/career-advice/">
                Career Advice
            </a>
        ),
        key: 'career-advice'
    },
    {
        label: (
            <a href="#/pricing/">
                Pricing
            </a>
        ),
        key: 'pricing'
    },
    {
        label: (
            <a href="#/contact-us/">
                Contact us
            </a>
        ),
        key: 'contact-us'
    },
];

class Topbar extends Component {
    render() {
        return (
            <Row style={{ background: 'white' }}>
                <Col lg={3} md={2} sm={2} xs={3}>
                    <a href='/'>
                        <img src={logo} height='60' style={{ transform: 'scale(1.4)' }} />
                    </a>
                </Col>
                <Col lg={18} md={15} sm={15} xs={12} style={{}}>
                    <Menu
                        mode="horizontal"
                        selectedKeys={[this.props.activeTopbarKey]}
                        style={{ textAlign: 'right', background: 'transparent', display: 'flex', justifyContent: 'center' }}
                        items={topBarMenuItem}
                    />
                </Col>
                <Col lg={3} md={7} sm={7} xs={9} style={{ textAlign: 'right' }}>
                    {/* <Dropdown overlay={candidateMenu}>
                        <a style={{ color: 'black' }} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <Avatar style={{ background: 'gray' }} >HM</Avatar>
                            <span style={{ marginLeft: '5px' }}>Hans Maulloo
                                <DownOutlined style={{ marginLeft: '5px' }} />
                            </span>
                        </a>
                    </Dropdown> */}
                    <Dropdown overlay={employerMenu} >
                        <a style={{ color: 'black' }} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <Avatar style={{ background: 'gray' }} >HM</Avatar>
                            <span style={{ marginLeft: '5px' }}>
                                <DownOutlined style={{ marginLeft: '5px' }} />
                            </span>
                        </a>
                    </Dropdown>
                </Col>
            </Row>
        )
    }
}

export default Topbar