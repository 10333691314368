import { Card, Table } from 'antd';
import React, { Component } from 'react';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { scrollUp } from '../../constants';

class MyPackageHist extends Component {
    async componentDidMount() {
        scrollUp()
        this.props.onTopbarKeyChange('')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Settings',
                path: '/settings/'
            },
            {
                item: 'My Package',
                path: '/settings/package/'
            },
            {
                item: 'History',
                path: '/settings/package/history/'
            }
        ])
    }

    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='My Package history' />
                <Card style={{ textAlign: 'center' }}>
                    <Table
                        columns={[
                            {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                                render: (text) => <a>{text}</a>,
                            },
                            {
                                title: 'Duration',
                                dataIndex: 'duration',
                                key: 'duration',
                            },
                            {
                                title: 'Status',
                                dataIndex: 'status',
                                key: 'status',
                            },
                            {
                                title: 'Jobs Entitled',
                                dataIndex: 'numOfJobs',
                                key: 'numOfJobs',
                            },
                            {
                                title: 'Job Used',
                                dataIndex: 'numOfJobsUsed',
                                key: 'numOfJobsUsed',
                            },
                            {
                                title: 'Balance',
                                dataIndex: 'numOfJobsRem',
                                key: 'numOfJobsRem',
                            },
                        ]}
                        dataSource={[
                            {
                                key: '1',
                                name: 'SME - Basic',
                                duration: '18th August 2020 - 18th August 2021',
                                status: 'APPROVED',
                                numOfJobs: 8,
                                numOfJobsUsed: 8,
                                numOfJobsRem: 8,
                            }
                        ]}
                        pagination={false}
                        bordered={false}
                    />

                </Card>

            </div>
        );
    }
}

export default MyPackageHist;