import react, { Component } from 'react';
import { Form, Input, Button, Checkbox, Col, Row, Card, Select } from 'antd';
import { Link } from 'react-router-dom';
import { candidate, employer, scrollUp } from '../../constants';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';

class CandidateRegister extends Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        scrollUp()
        await this.props.onTopbarKeyChange('')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Candidate Registration',
                path: '/register/candidate/'
            }
        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='Candidate Registration' />

                <Row>
                    <Col lg={4} md={0} sm={0} xs={0}></Col>
                    <Col lg={16} md={24} sm={24} xs={24}>
                        <Card
                            style={{ width: '100%' }}
                        >
                            <Form
                                name="basic"
                                layout='horizontal'
                                wrapperCol={{
                                    lg: 8,
                                    md: 12,
                                    sm: 24,
                                    xs: 24
                                }}
                                labelCol={{
                                    lg: 9,
                                    md: 8,
                                    sm: 24,
                                    xs: 24
                                }}
                                labelAlign='left'
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={this.props.onRegisterFinish}
                                onFinishFailed={this.props.onRegisterFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>First name</span>}
                                    name="firstname"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your first name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='First name' />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Last name</span>}
                                    name="lastname"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your last name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Last name' />
                                </Form.Item>

                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Email Address</span>}
                                    name="emailaddress"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email address!',
                                        },
                                    ]}
                                // style={{margin: 0}}
                                >
                                    <Input placeholder='Email address' />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                // style={{ marginBottom: 0 }}
                                >
                                    <Input.Password placeholder='Password' />
                                </Form.Item>
                                <Form.Item
                                    label="Confirm Password"
                                    name="cpassword"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password again!',
                                        },
                                    ]}
                                // style={{ marginBottom: 0 }}
                                >
                                    <Input.Password placeholder='Confirm Password' />
                                </Form.Item>

                                <Row>
                                    <Col lg={10} md={0} sm={0} xs={0}></Col>
                                    <Col lg={4} md={24} sm={24} xs={24}>
                                        <Button
                                            style={{ background: '#2D3691', color: 'white', width: '100%' }}
                                            htmlType="submit"
                                        >
                                            Register
                                        </Button>
                                    </Col>
                                    <Col lg={10} md={0} sm={0} xs={0}></Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={4} md={0} sm={0} xs={0}></Col>
                </Row>
            </div>
        )
    }
}

export default CandidateRegister;