import { Button, Col, Row } from 'antd';
import React, { Component } from 'react';
import { IoAddCircleSharp } from 'react-icons/io5'
import { HiUsers } from 'react-icons/hi'
import { MdDrafts } from 'react-icons/md';
import Post_Job from '../../components/modals/Post_Job';
import SearchResults from '../../components/SearchResults';
import { Link } from 'react-router-dom';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { scrollUp } from '../../constants';

class Settings extends Component {

    async componentDidMount() {
        scrollUp()
        await this.props.onTopbarKeyChange('')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Settings',
                path: '/settings/'
            },
        ])
    }


    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='Settings' />
                <Row gutter={[16, 16]}>
                    <Col>
                        <Link to="/settings/account/" >
                            <Button style={{ height: 150, width: 150 }}>
                                <IoAddCircleSharp size={35} />
                                <br />
                                My Account
                            </Button>
                        </Link>
                        <Post_Job
                            {...this.props}
                        />
                    </Col>
                    <Col>
                        <Link to="/settings/reset/" >
                        <Button style={{ height: 150, width: 150, whiteSpace: 'normal' }}>
                            <MdDrafts size={35} />
                            <br />
                            Reset username & password
                        </Button>
                        </Link>
                        <Post_Job
                            {...this.props}
                        />
                    </Col>
                    <Col>
                        <Link to="/settings/package/" >
                            <Button
                                style={{ height: 150, width: 150, whiteSpace: 'normal' }}
                            >
                                <HiUsers size={35} />
                                <br />
                                My package
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Settings;