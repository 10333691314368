import { Button, Col, Divider, Row, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { Component } from 'react';
import { noticePeriods } from '../../constants';

const { Option } = Select

class Edit_NoticePeriod extends Component {
    render() {
        return (
            <div>
                <Modal
                    title="Edit notice period"
                    visible={this.props.modalEditNoticePeriod}
                    destroyOnClose={true}
                    footer={[
                        <Button
                            type='primary'
                            onClick={this.props.triggerModalEditNoticePeriod}
                        >
                            Done
                        </Button>
                    ]}
                    onCancel={this.props.triggerModalEditNoticePeriod}
                >
                    <Row className='profile-section-row-information'>
                        <Col lg={5} md={24} sm={24} xs={24}>
                            <label>Notice Period: </label>
                        </Col>
                        <Col lg={18} md={24} sm={24} xs={24}>
                            <Select
                                placeholder='Select notice period for your current job'
                                style={{ width: '90%' }}
                                onChange={this.props.handleChangeNoticePeriod}
                                value={this.props.candidateCV.noticePeriod}
                                showSearch
                            >
                                {noticePeriods.map((period, index) => {
                                    return (
                                        <Option key={index} value={period}>{period}</Option>
                                    )
                                })}
                            </Select>
                        </Col>
                    </Row>

                </Modal>


            </div>
        );
    }
}

export default Edit_NoticePeriod;