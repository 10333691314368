import React, { Component } from 'react';
import './App.css';
import 'antd/dist/antd.css';
import 'react-quill/dist/quill.snow.css';
import { Affix, Button, Col, Divider, Layout, List, message, Popover, Row, Space, Tooltip, Typography } from 'antd';
import Topbar from './components/Topbar';
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter
} from "react-router-dom";
import Homepage from './pages/Both/Homepage';
import Profile from './pages/Both/Profile';
import Account from './pages/Both/Account';
import CreateCV from './pages/Candidate/CreateCV';
import moment from 'moment';
import { educationLevel, scrollUp } from './constants';
import Dashboard from './pages/Employer/Dashboard';
import CreateJob from './pages/Employer/CreateJob';
import SearchResultsExpanded from './components/SearchResultsExpanded';
import ATS from './pages/Employer/ATS';
import ATS_Active from './pages/Employer/ATS_Active';
import Drafts from './pages/Employer/Drafts';
import Pricing from './pages/Candidate/Pricing';
import Services from './pages/Both/Services';
import Service1 from './pages/Both/Services1';
import Service2 from './pages/Both/Service2';
import CareerAdvice from './pages/Both/CareerAdvice';
import Contact from './pages/Both/Contact';
import logo from './images/logo.png';
import { GiPhone } from 'react-icons/gi';
import { MdFacebook } from 'react-icons/md';
import { GrFacebook, GrInstagram, GrLinkedin } from 'react-icons/gr';
import Settings from './pages/Both/Settings';
import CandidateRegister from './pages/Candidate/CandidateRegister';
import EmployerRegister from './pages/Employer/EmployerRegister';
import PublicCompany from './pages/Both/PublicCompany';
import ResetPwd from './pages/Employer/ResetPwd';
import MyPackage from './pages/Employer/MyPackage';
import ResetInfo from './pages/Employer/ResetInfo';
import ResetUsrname from './pages/Employer/ResetUsrname';
import MyPackageHist from './pages/Employer/MyPackageHist';
import ActiveJobs from './pages/Employer/ActiveJobs';
import EditJob from './pages/Employer/EditJob';
import { FaWhatsapp } from 'react-icons/fa';

const { Header, Footer, Content } = Layout;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const tempBullets = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n
Nam vestibulum augue quis erat feugiat, vitae sodales lorem dictum.\n
Pellentesque mattis nunc nec elementum sodales.\n
Maecenas tincidunt orci ac nibh bibendum luctus varius a orci.\n
Nunc semper nibh eu dolor viverra feugiat.\n
In rhoncus libero eu volutpat tincidunt.\n
Suspendisse eget sem quis sem aliquet luctus id et dui.\n
Ut lacinia justo vitae gravida sodales.\n
Morbi in nulla bibendum, sollicitudin metus et, tristique odio.\n
Maecenas dictum dolor vel elit pellentesque, mattis interdum erat finibus.\n
Duis ultricies metus quis viverra mollis.\n
Integer aliquet nisi et aliquet sodales.\n
Integer vestibulum elit et felis tempus porttitor.\n
Maecenas sit amet lorem sed leo blandit lacinia eget vitae elit.\n
Curabitur ullamcorper purus eget blandit convallis.\n
Vivamus blandit ligula in nisi fermentum, in pellentesque massa vestibulum.\n 
`

function breakTextToBulletPoints(text) {
  let bulletPoints = text.split('\n')
  return bulletPoints
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTopbarKey: 'recruitment',
      breadcrumbItems: [],
      candidateLogin: {},
      employerLogin: {},
      candidateRegister: {},
      employerRegister: {},
      isLoadingProfilePicture: false,

      // Profile
      modalEditPersonalInformation: false,
      modalEditProfileInformation: false,
      modalEditCareerSummary: false,
      modalEditNoticePeriod: false,
      modalEditReferrals: false,

      // Create CV
      modalAddNewJob: false,
      modalEditNewJob: false,

      modalAddNewEducation: false,
      modalEditNewEducation: false,

      modalAddNewLanguage: false,
      modalEditNewLanguage: false,

      isCreateAnotherJob: false,
      isCreateAnotherEducation: false,
      isCreateAnotherLanguage: false,

      candidateCV: {
        candidateId: '',
        fullName: '',
        dob: null,
        age: '',
        gender: null,
        id: '',
        homeAddress: '',
        district: null,
        homePhoneNo: '',
        mobileNo: '',
        emailAddress: '',
        drivingLicense: null,
        nationality: null,
        linkedIn: '',
        jobPreference: null,
        salaryRange: null,

        profile: '',

        // New Job Modal
        jobCompany: '',
        jobTitle: '',
        isCurrentWork: false,
        jobSector: null,
        jobStartDate: null,
        jobEndDate: null,
        jobDescription: '',
        workExperience: [],


        // New Education Modal
        educationSchoolName: '',
        educationSchoolLevel: null,
        educationSchoolFieldOfStudy: '',
        isCurrentEducation: false,
        educationSchoolStartDate: null,
        educationSchoolEndDate: null,
        educationExperience: [],


        // Career Summary
        careerLevel: null,
        careerLevelOfEducation: null,
        careerTotalYearsExperience: null,



        // Skills
        tempSkill: '',
        skills: [],



        // New Language Modal
        languageName: null,
        languageFluency: null,
        languages: [],


        // Hobby
        tempHobby: '',
        hobbies: [],

        // Notice Period
        noticePeriod: null,


        // Referals
        referalFullName: '',
        referalJobTitle: '',
        referalCompany: '',
        referalMobileNum: ''
      },

      candidate: {
        candidateId: '',
        appliedJobs: []
      },


      // Modals
      modalPostNewJob: false,
      modalUpdateNewJob: false,

      // Create Job
      isPostAnotherJob: false,
      searchResultsTableColumns: [
        {
          title: 'Job title',
          dataIndex: 'jobtitle',
          key: 'jobtitle'
        },
        {
          title: 'Economic Sector',
          dataIndex: 'jobeconomicsector',
          key: 'jobeconomicsector'
        },
        {
          title: 'Company Name',
          dataIndex: 'jobcompanyname',
          key: 'jobcompanyname',
          render: (text, record) => <a target='_blank' href={'#/company/' + record.jobcompanyname + '/'}>{record.jobcompanyname}</a>
        },
        {
          title: 'Location',
          dataIndex: 'joblocation',
          key: 'joblocation'
        },
        {
          title: 'Added on',
          dataIndex: 'jobaddedon',
          key: 'jobaddedon'
        },
        {
          title: 'Salary Range',
          dataIndex: 'jobsalaryrange',
          key: 'jobsalaryrange'
        },
        {
          title: 'Closing date',
          dataIndex: 'jobclosingdate',
          key: 'jobclosingdate'
        }
      ],

      searchResultsTableDetailsColumns: [
        {
          // title: 'Title',
          dataIndex: 'detailstitle',
          key: 'detailstitle'
        },
        {
          // title: 'Description',
          dataIndex: 'detailsdescription',
          key: 'detailsdescription'
        }
      ],
      searchResultsTableData: [
        {
          key: 1,
          jobtitle: 'HR Officer',
          jobeconomicsector: 'HR / Recruiting',
          jobcompanyname: 'Business & Decision',
          joblocation: 'Port Louis',
          jobaddedon: '09/09/22',
          jobsalaryrange: 'Rs 20,000 - Rs 30,000',
          jobclosingdate: '09/09/22',
          applicantsNum: '5',
          jobexpanddetails: [
            {
              key: 'a',
              detailstitle: 'Job Summary',
              detailsdescription: `
              We are looking for an experience HR Officer to join oiu team. The selected candidate will be involve in operation ad administration of HR Principle incuding Payrol
              `
            },
            {
              key: 'c',
              detailstitle: 'Qualification Required',
              detailsdescription: `Diploma / Bac + 2`
            },
            {
              key: 'd',
              detailstitle: 'Qualification and Experience',
              detailsdescription: breakTextToBulletPoints(`Diploma in Human Resource Managment\nMinimum 3 Yrs experience as HR Officer and or HR Generalist`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'e',
              detailstitle: 'Roles/Responsibilites',
              detailsdescription: breakTextToBulletPoints(`Prepare and place advertisements for recruitment.\nReceive applications, record in HR database and send acknowledgement letters to candidates who have applied for positions advertised.\nSchedule interviews in accordance with panel member’s availability and conduct interviews.\nComplete the onboarding process of new recruits\nLiaise with payroll for the processing of payments.\nMaintain and update personnel records for staff (paper and electronic).\nPrepare monthly / ad hoc reports.`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'f',
              detailstitle: 'Other Requirement',
              detailsdescription: breakTextToBulletPoints(`Good Communication Skill\nLeadership and Team Management\nCan do attitude, Solution provider`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'g',
              detailstitle: 'Notes / Remarks',
              detailsdescription: `
                Selected candidate will have the opportunity to work in a fast growing company and enhance he/she career development
              `
            },
          ]
        },
        {
          key: 2,
          jobtitle: 'Software Engineer',
          jobeconomicsector: 'HR / Recruiting',
          jobcompanyname: 'Business & Decision',
          joblocation: 'Port Louis',
          jobaddedon: '09/09/22',
          jobsalaryrange: 'Rs 20,000 - Rs 30,000',
          jobclosingdate: '09/09/22',
          applicantsNum: '8',
          jobexpanddetails: [
            {
              key: 'a',
              detailstitle: 'Job Summary',
              detailsdescription: `
              We are looking for an experience HR Officer to join oiu team. The selected candidate will be involve in operation ad administration of HR Principle incuding Payrol
              `
            },
            {
              key: 'c',
              detailstitle: 'Qualification Required',
              detailsdescription: `Diploma / Bac + 2`
            },
            {
              key: 'd',
              detailstitle: 'Qualification and Experience',
              detailsdescription: breakTextToBulletPoints(`Diploma in Human Resource Managment\nMinimum 3 Yrs experience as HR Officer and or HR Generalist`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'e',
              detailstitle: 'Roles/Responsibilites',
              detailsdescription: breakTextToBulletPoints(`Prepare and place advertisements for recruitment.\nReceive applications, record in HR database and send acknowledgement letters to candidates who have applied for positions advertised.\nSchedule interviews in accordance with panel member’s availability and conduct interviews.\nComplete the onboarding process of new recruits\nLiaise with payroll for the processing of payments.\nMaintain and update personnel records for staff (paper and electronic).\nPrepare monthly / ad hoc reports.`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'f',
              detailstitle: 'Other Requirement',
              detailsdescription: breakTextToBulletPoints(`Good Communication Skill\nLeadership and Team Management\nCan do attitude, Solution provider`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'g',
              detailstitle: 'Notes / Remarks',
              detailsdescription: `
                Selected candidate will have the opportunity to work in a fast growing company and enhance he/she career development
              `
            },
          ]
        },
        {
          key: 3,
          jobtitle: 'Product Specialist',
          jobeconomicsector: 'HR / Recruiting',
          jobcompanyname: 'Business & Decision',
          joblocation: 'Port Louis',
          jobaddedon: '09/09/22',
          jobsalaryrange: 'Rs 20,000 - Rs 30,000',
          jobclosingdate: '09/09/22',
          applicantsNum: '2',
          jobexpanddetails: [
            {
              key: 'a',
              detailstitle: 'Job Summary',
              detailsdescription: `
              We are looking for an experience HR Officer to join oiu team. The selected candidate will be involve in operation ad administration of HR Principle incuding Payrol
              `
            },
            {
              key: 'c',
              detailstitle: 'Qualification Required',
              detailsdescription: `Diploma / Bac + 2`
            },
            {
              key: 'd',
              detailstitle: 'Qualification and Experience',
              detailsdescription: breakTextToBulletPoints(`Diploma in Human Resource Managment\nMinimum 3 Yrs experience as HR Officer and or HR Generalist`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'e',
              detailstitle: 'Roles/Responsibilites',
              detailsdescription: breakTextToBulletPoints(`Prepare and place advertisements for recruitment.\nReceive applications, record in HR database and send acknowledgement letters to candidates who have applied for positions advertised.\nSchedule interviews in accordance with panel member’s availability and conduct interviews.\nComplete the onboarding process of new recruits\nLiaise with payroll for the processing of payments.\nMaintain and update personnel records for staff (paper and electronic).\nPrepare monthly / ad hoc reports.`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'f',
              detailstitle: 'Other Requirement',
              detailsdescription: breakTextToBulletPoints(`Good Communication Skill\nLeadership and Team Management\nCan do attitude, Solution provider`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'g',
              detailstitle: 'Notes / Remarks',
              detailsdescription: `
                Selected candidate will have the opportunity to work in a fast growing company and enhance he/she career development
              `
            },
          ]
        },
        {
          key: 3,
          jobtitle: 'Product Specialist',
          jobeconomicsector: 'HR / Recruiting',
          jobcompanyname: 'Business & Decision',
          joblocation: 'Port Louis',
          jobaddedon: '09/09/22',
          jobsalaryrange: 'Rs 20,000 - Rs 30,000',
          jobclosingdate: '09/09/22',
          applicantsNum: '2',
          jobexpanddetails: [
            {
              key: 'a',
              detailstitle: 'Job Summary',
              detailsdescription: `
              We are looking for an experience HR Officer to join oiu team. The selected candidate will be involve in operation ad administration of HR Principle incuding Payrol
              `
            },
            {
              key: 'c',
              detailstitle: 'Qualification Required',
              detailsdescription: `Diploma / Bac + 2`
            },
            {
              key: 'd',
              detailstitle: 'Qualification and Experience',
              detailsdescription: breakTextToBulletPoints(`Diploma in Human Resource Managment\nMinimum 3 Yrs experience as HR Officer and or HR Generalist`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'e',
              detailstitle: 'Roles/Responsibilites',
              detailsdescription: breakTextToBulletPoints(`Prepare and place advertisements for recruitment.\nReceive applications, record in HR database and send acknowledgement letters to candidates who have applied for positions advertised.\nSchedule interviews in accordance with panel member’s availability and conduct interviews.\nComplete the onboarding process of new recruits\nLiaise with payroll for the processing of payments.\nMaintain and update personnel records for staff (paper and electronic).\nPrepare monthly / ad hoc reports.`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'f',
              detailstitle: 'Other Requirement',
              detailsdescription: breakTextToBulletPoints(`Good Communication Skill\nLeadership and Team Management\nCan do attitude, Solution provider`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'g',
              detailstitle: 'Notes / Remarks',
              detailsdescription: `
                Selected candidate will have the opportunity to work in a fast growing company and enhance he/she career development
              `
            },
          ]
        },
        {
          key: 3,
          jobtitle: 'Product Specialist',
          jobeconomicsector: 'HR / Recruiting',
          jobcompanyname: 'Business & Decision',
          joblocation: 'Port Louis',
          jobaddedon: '09/09/22',
          jobsalaryrange: 'Rs 20,000 - Rs 30,000',
          jobclosingdate: '09/09/22',
          applicantsNum: '2',
          jobexpanddetails: [
            {
              key: 'a',
              detailstitle: 'Job Summary',
              detailsdescription: `
              We are looking for an experience HR Officer to join oiu team. The selected candidate will be involve in operation ad administration of HR Principle incuding Payrol
              `
            },
            {
              key: 'c',
              detailstitle: 'Qualification Required',
              detailsdescription: `Diploma / Bac + 2`
            },
            {
              key: 'd',
              detailstitle: 'Qualification and Experience',
              detailsdescription: breakTextToBulletPoints(`Diploma in Human Resource Managment\nMinimum 3 Yrs experience as HR Officer and or HR Generalist`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'e',
              detailstitle: 'Roles/Responsibilites',
              detailsdescription: breakTextToBulletPoints(`Prepare and place advertisements for recruitment.\nReceive applications, record in HR database and send acknowledgement letters to candidates who have applied for positions advertised.\nSchedule interviews in accordance with panel member’s availability and conduct interviews.\nComplete the onboarding process of new recruits\nLiaise with payroll for the processing of payments.\nMaintain and update personnel records for staff (paper and electronic).\nPrepare monthly / ad hoc reports.`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'f',
              detailstitle: 'Other Requirement',
              detailsdescription: breakTextToBulletPoints(`Good Communication Skill\nLeadership and Team Management\nCan do attitude, Solution provider`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'g',
              detailstitle: 'Notes / Remarks',
              detailsdescription: `
                Selected candidate will have the opportunity to work in a fast growing company and enhance he/she career development
              `
            },
          ]
        },
        {
          key: 3,
          jobtitle: 'Product Specialist',
          jobeconomicsector: 'HR / Recruiting',
          jobcompanyname: 'Business & Decision',
          joblocation: 'Port Louis',
          jobaddedon: '09/09/22',
          jobsalaryrange: 'Rs 20,000 - Rs 30,000',
          jobclosingdate: '09/09/22',
          applicantsNum: '2',
          jobexpanddetails: [
            {
              key: 'a',
              detailstitle: 'Job Summary',
              detailsdescription: `
              We are looking for an experience HR Officer to join oiu team. The selected candidate will be involve in operation ad administration of HR Principle incuding Payrol
              `
            },
            {
              key: 'c',
              detailstitle: 'Qualification Required',
              detailsdescription: `Diploma / Bac + 2`
            },
            {
              key: 'd',
              detailstitle: 'Qualification and Experience',
              detailsdescription: breakTextToBulletPoints(`Diploma in Human Resource Managment\nMinimum 3 Yrs experience as HR Officer and or HR Generalist`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'e',
              detailstitle: 'Roles/Responsibilites',
              detailsdescription: breakTextToBulletPoints(`Prepare and place advertisements for recruitment.\nReceive applications, record in HR database and send acknowledgement letters to candidates who have applied for positions advertised.\nSchedule interviews in accordance with panel member’s availability and conduct interviews.\nComplete the onboarding process of new recruits\nLiaise with payroll for the processing of payments.\nMaintain and update personnel records for staff (paper and electronic).\nPrepare monthly / ad hoc reports.`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'f',
              detailstitle: 'Other Requirement',
              detailsdescription: breakTextToBulletPoints(`Good Communication Skill\nLeadership and Team Management\nCan do attitude, Solution provider`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'g',
              detailstitle: 'Notes / Remarks',
              detailsdescription: `
                Selected candidate will have the opportunity to work in a fast growing company and enhance he/she career development
              `
            },
          ]
        },
        {
          key: 3,
          jobtitle: 'Product Specialist',
          jobeconomicsector: 'HR / Recruiting',
          jobcompanyname: 'Business & Decision',
          joblocation: 'Port Louis',
          jobaddedon: '09/09/22',
          jobsalaryrange: 'Rs 20,000 - Rs 30,000',
          jobclosingdate: '09/09/22',
          applicantsNum: '2',
          jobexpanddetails: [
            {
              key: 'a',
              detailstitle: 'Job Summary',
              detailsdescription: `
              We are looking for an experience HR Officer to join oiu team. The selected candidate will be involve in operation ad administration of HR Principle incuding Payrol
              `
            },
            {
              key: 'c',
              detailstitle: 'Qualification Required',
              detailsdescription: `Diploma / Bac + 2`
            },
            {
              key: 'd',
              detailstitle: 'Qualification and Experience',
              detailsdescription: breakTextToBulletPoints(`Diploma in Human Resource Managment\nMinimum 3 Yrs experience as HR Officer and or HR Generalist`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'e',
              detailstitle: 'Roles/Responsibilites',
              detailsdescription: breakTextToBulletPoints(`Prepare and place advertisements for recruitment.\nReceive applications, record in HR database and send acknowledgement letters to candidates who have applied for positions advertised.\nSchedule interviews in accordance with panel member’s availability and conduct interviews.\nComplete the onboarding process of new recruits\nLiaise with payroll for the processing of payments.\nMaintain and update personnel records for staff (paper and electronic).\nPrepare monthly / ad hoc reports.`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'f',
              detailstitle: 'Other Requirement',
              detailsdescription: breakTextToBulletPoints(`Good Communication Skill\nLeadership and Team Management\nCan do attitude, Solution provider`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'g',
              detailstitle: 'Notes / Remarks',
              detailsdescription: `
                Selected candidate will have the opportunity to work in a fast growing company and enhance he/she career development
              `
            },
          ]
        },
        {
          key: 3,
          jobtitle: 'Product Specialist',
          jobeconomicsector: 'HR / Recruiting',
          jobcompanyname: 'Business & Decision',
          joblocation: 'Port Louis',
          jobaddedon: '09/09/22',
          jobsalaryrange: 'Rs 20,000 - Rs 30,000',
          jobclosingdate: '09/09/22',
          applicantsNum: '2',
          jobexpanddetails: [
            {
              key: 'a',
              detailstitle: 'Job Summary',
              detailsdescription: `
              We are looking for an experience HR Officer to join oiu team. The selected candidate will be involve in operation ad administration of HR Principle incuding Payrol
              `
            },
            {
              key: 'c',
              detailstitle: 'Qualification Required',
              detailsdescription: `Diploma / Bac + 2`
            },
            {
              key: 'd',
              detailstitle: 'Qualification and Experience',
              detailsdescription: breakTextToBulletPoints(`Diploma in Human Resource Managment\nMinimum 3 Yrs experience as HR Officer and or HR Generalist`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'e',
              detailstitle: 'Roles/Responsibilites',
              detailsdescription: breakTextToBulletPoints(`Prepare and place advertisements for recruitment.\nReceive applications, record in HR database and send acknowledgement letters to candidates who have applied for positions advertised.\nSchedule interviews in accordance with panel member’s availability and conduct interviews.\nComplete the onboarding process of new recruits\nLiaise with payroll for the processing of payments.\nMaintain and update personnel records for staff (paper and electronic).\nPrepare monthly / ad hoc reports.`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'f',
              detailstitle: 'Other Requirement',
              detailsdescription: breakTextToBulletPoints(`Good Communication Skill\nLeadership and Team Management\nCan do attitude, Solution provider`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'g',
              detailstitle: 'Notes / Remarks',
              detailsdescription: `
                Selected candidate will have the opportunity to work in a fast growing company and enhance he/she career development
              `
            },
          ]
        },
        {
          key: 3,
          jobtitle: 'Product Specialist',
          jobeconomicsector: 'HR / Recruiting',
          jobcompanyname: 'Business & Decision',
          joblocation: 'Port Louis',
          jobaddedon: '09/09/22',
          jobsalaryrange: 'Rs 20,000 - Rs 30,000',
          jobclosingdate: '09/09/22',
          applicantsNum: '2',
          jobexpanddetails: [
            {
              key: 'a',
              detailstitle: 'Job Summary',
              detailsdescription: `
              We are looking for an experience HR Officer to join oiu team. The selected candidate will be involve in operation ad administration of HR Principle incuding Payrol
              `
            },
            {
              key: 'c',
              detailstitle: 'Qualification Required',
              detailsdescription: `Diploma / Bac + 2`
            },
            {
              key: 'd',
              detailstitle: 'Qualification and Experience',
              detailsdescription: breakTextToBulletPoints(`Diploma in Human Resource Managment\nMinimum 3 Yrs experience as HR Officer and or HR Generalist`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'e',
              detailstitle: 'Roles/Responsibilites',
              detailsdescription: breakTextToBulletPoints(`Prepare and place advertisements for recruitment.\nReceive applications, record in HR database and send acknowledgement letters to candidates who have applied for positions advertised.\nSchedule interviews in accordance with panel member’s availability and conduct interviews.\nComplete the onboarding process of new recruits\nLiaise with payroll for the processing of payments.\nMaintain and update personnel records for staff (paper and electronic).\nPrepare monthly / ad hoc reports.`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'f',
              detailstitle: 'Other Requirement',
              detailsdescription: breakTextToBulletPoints(`Good Communication Skill\nLeadership and Team Management\nCan do attitude, Solution provider`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'g',
              detailstitle: 'Notes / Remarks',
              detailsdescription: `
                Selected candidate will have the opportunity to work in a fast growing company and enhance he/she career development
              `
            },
          ]
        },
        {
          key: 3,
          jobtitle: 'Product Specialist',
          jobeconomicsector: 'HR / Recruiting',
          jobcompanyname: 'Business & Decision',
          joblocation: 'Port Louis',
          jobaddedon: '09/09/22',
          jobsalaryrange: 'Rs 20,000 - Rs 30,000',
          jobclosingdate: '09/09/22',
          applicantsNum: '2',
          jobexpanddetails: [
            {
              key: 'a',
              detailstitle: 'Job Summary',
              detailsdescription: `
              We are looking for an experience HR Officer to join oiu team. The selected candidate will be involve in operation ad administration of HR Principle incuding Payrol
              `
            },
            {
              key: 'c',
              detailstitle: 'Qualification Required',
              detailsdescription: `Diploma / Bac + 2`
            },
            {
              key: 'd',
              detailstitle: 'Qualification and Experience',
              detailsdescription: breakTextToBulletPoints(`Diploma in Human Resource Managment\nMinimum 3 Yrs experience as HR Officer and or HR Generalist`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'e',
              detailstitle: 'Roles/Responsibilites',
              detailsdescription: breakTextToBulletPoints(`Prepare and place advertisements for recruitment.\nReceive applications, record in HR database and send acknowledgement letters to candidates who have applied for positions advertised.\nSchedule interviews in accordance with panel member’s availability and conduct interviews.\nComplete the onboarding process of new recruits\nLiaise with payroll for the processing of payments.\nMaintain and update personnel records for staff (paper and electronic).\nPrepare monthly / ad hoc reports.`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'f',
              detailstitle: 'Other Requirement',
              detailsdescription: breakTextToBulletPoints(`Good Communication Skill\nLeadership and Team Management\nCan do attitude, Solution provider`)
                .map((point, index) => <li>&#8226; {point}</li>)
            },
            {
              key: 'g',
              detailstitle: 'Notes / Remarks',
              detailsdescription: `
                Selected candidate will have the opportunity to work in a fast growing company and enhance he/she career development
              `
            },
          ]
        },
      ],
      employer: {
        employerId: '',
        applicants: [

        ],
        postNewJob: {
          jobId: '',
          jobTitle: '',
          jobDescription: '',
          jobCategory: null,
          jobType: null,
          jobLocation: null,
          jobSalary: null,
          jobCareerLevel: null,
          jobQualificationsRequired: null,
          qualificationsAndExperience: '',
          rolesAndResponsibilities: '',
          otherRequirements: '',
          remarks: '',
          visibility: false,
          jobCompany: '',
          jobAddedDate: '',
          jobClosingDate: '',
          jobContactPerson: ''
        },
        jobs: []
      },

      entityType: 'Employer',
    }
  }

  componentDidMount() {
    scrollUp()
  }

  // Create Job
  convertParagraphToBulletPoint = async (paragraph) => {
    console.log(paragraph.split('\n'))
  }

  handleChangeEmployerJobTitle = async (e) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.jobTitle = e.target.value;
      return { employer };
    })
  }

  handleChangeEmployerJobDescription = async (e) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.jobDescription = e.target.value;
      return { employer };
    })
  }

  handleChangeEmployerJobCategory = async (value) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.jobCategory = value;
      return { employer };
    })
  }

  handleChangeEmployerJobType = async (value) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.jobType = value;
      return { employer };
    })
  }

  handleChangeEmployerJobLocation = async (value) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.jobLocation = value;
      return { employer };
    })
  }

  handleChangeEmployerJobSalary = async (value) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.jobSalary = value;
      return { employer };
    })
  }

  handleChangeEmployerJobCareerLevel = async (value) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.jobCareerLevel = value;
      return { employer };
    })
  }

  handleChangeEmployerJobQualificationsRequired = async (value) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.jobQualificationsRequired = value;
      return { employer };
    })
  }

  handleBulletPointInput = async (previousLength, e) => {
    const bullet = "\u2022";
    const newLength = e.target.value.length;
    const characterCode = e.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      console.log(previousLength)
      console.log(newLength)
      if (characterCode === 10) {
        console.log('1')
        e.target.value = `${e.target.value}${bullet} `;
      } else if (newLength === 1) {
        console.log('2')
        e.target.value = `${bullet} ${e.target.value}`;
      }
    }

    previousLength = newLength
  }

  handleChangeEmployerQualificationsAndExperience = async (e) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      this.handleBulletPointInput(employer.postNewJob.qualificationsAndExperience.length, e)
      employer.postNewJob.qualificationsAndExperience = e.target.value;
      return { employer };
    })
  }

  handleChangeEmployerRolesAndResponsibilities = async (e) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      this.handleBulletPointInput(employer.postNewJob.rolesAndResponsibilities.length, e);
      employer.postNewJob.rolesAndResponsibilities = e.target.value;
      return { employer };
    })
  }

  handleChangeEmployerOtherRequirements = async (e) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      this.handleBulletPointInput(employer.postNewJob.otherRequirements.length, e);
      employer.postNewJob.otherRequirements = e.target.value;
      return { employer };
    })
  }

  handleChangeEmployerRemarks = async (e) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.remarks = e.target.value;
      return { employer };
    })
  }

  handleChangeEmployerJobVisibility = async (value) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.visibility = value;
      return { employer };
    })
  }

  handleChangeEmployerJobStartDate = async (date) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.jobAddedDate = date;
      return { employer };
    })
  }

  handleChangeEmployerJobEndDate = async (date) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.jobClosingDate = date;
      return { employer };
    })
  }

  handleChangeEmployerJobContactPerson = async (e) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.jobContactPerson = e.target.value;
      return { employer };
    })
  }


  handleChangeEmployerJobCompany = async (e) => {
    await this.setState(prevState => {
      let employer = Object.assign({}, prevState.employer)
      employer.postNewJob.jobCompany = e.target.value;
      return { employer };
    })
  }

  handleSubmitPostNewJob = async () => {
    console.log(this.state.employer.postNewJob)
  }

  onTopbarKeyChange = (key) => {
    this.setState({
      activeTopbarKey: key
    })
  }


  onLoginFinish = (values) => {
    console.log('Success:', values);
  };

  onLoginFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  onRegisterFinish = (values) => {
    console.log('Success:', values);
  };

  onRegisterFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // CV Creation
  // Profile
  handleUpdateProfilePic = info => {
    console.log(info)
    if (info.file.status === 'uploading') {
      this.setState({ isLoadingProfilePicture: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          isLoadingProfilePicture: false,
        }),
      );
    }
  };

  handleBeforeUploadProfilePic = (file) => {
    console.log(file)
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  handleChangeFullName = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.fullName = e.target.value;
      return { candidateCV };
    })
  }

  handleChangeDOB = async (value) => {
    let age = await this.handleChangeAge(value)
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.dob = value ? value.format('DD MMM YYYY') : null;
      candidateCV.age = value ? age : ''
      return { candidateCV };
    })
    console.log(this.state.candidateCV.age)
  }

  handleChangeAge = async (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  }


  handleChangeGender = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.gender = value;
      return { candidateCV };
    })
  }

  handleChangeID = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.id = e.target.value;
      return { candidateCV };
    })
  }

  handleChangeHomeAddress = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.homeAddress = e.target.value;
      return { candidateCV };
    })
  }

  handleChangeDistrict = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.district = value;
      return { candidateCV };
    })
  }

  handleChangeHomePhoneNum = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.homePhoneNo = value;
      return { candidateCV };
    })
  }

  handleChangeMobileNum = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.mobileNo = value;
      return { candidateCV };
    })
  }

  handleChangeEmailAddress = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.emailAddress = e.target.value;
      return { candidateCV };
    })
  }

  handleChangeDrivingLicense = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.drivingLicense = value;
      return { candidateCV };
    })
  }

  handleChangeNationality = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.nationality = value;
      return { candidateCV };
    })
  }

  handleChangeLinkedIn = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.linkedIn = e.target.value;
      return { candidateCV };
    })
  }

  handleChangeJobPreference = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.jobPreference = value;
      return { candidateCV };
    })
  }

  handleChangeSalaryRange = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.salaryRange = value;
      return { candidateCV };
    })
  }

  // Profile
  handleChangeProfileDescription = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.profile = e.target.value;
      return { candidateCV };
    })
  }

  // Add Work
  handleChangeJobCompany = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.jobCompany = e.target.value;
      return { candidateCV };
    })
  }

  handleChangeJobTitle = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.jobTitle = e.target.value;
      return { candidateCV };
    })
  }

  handleChangeIsCurrentWork = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      if (e.target.checked) {
        candidateCV.isCurrentWork = e.target.checked;
        candidateCV.jobEndDate = 'Present'
      } else {
        candidateCV.isCurrentWork = e.target.checked;
        candidateCV.jobEndDate = null
      }
      return { candidateCV };
    })
  }

  handleChangeJobSector = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.jobSector = value;
      return { candidateCV };
    })
  }

  handleChangeJobStartDate = async (date) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.jobStartDate = date;
      return { candidateCV };
    })
  }

  handleChangeJobEndDate = async (date) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.jobEndDate = date;
      return { candidateCV };
    })
  }

  handleChangeJobDescription = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.jobDescription = e.target.value;
      return { candidateCV };
    })
  }

  resetAddJob = async () => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.jobCompany = '';
      candidateCV.jobTitle = '';
      candidateCV.jobSector = null;
      candidateCV.isCurrentWork = false;
      candidateCV.jobStartDate = null;
      candidateCV.jobEndDate = null;
      candidateCV.jobDescription = '';
      return { candidateCV };
    })
  }

  handleChangeIsCreateAnotherJob = async () => {
    await this.setState({
      isCreateAnotherJob: !this.state.isCreateAnotherJob
    })
  }

  handleAddNewJob = async () => {
    await this.setState(async prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      await candidateCV.workExperience.unshift({
        jobCompany: this.state.candidateCV.jobCompany,
        jobTitle: this.state.candidateCV.jobTitle,
        jobSector: this.state.candidateCV.jobSector,
        isCurrentWork: this.state.candidateCV.isCurrentWork,
        jobStartDate: this.state.candidateCV.jobStartDate,
        jobEndDate: this.state.candidateCV.jobEndDate,
        jobDescription: this.state.candidateCV.jobDescription
      });
      if (!this.state.isCreateAnotherJob) {
        this.triggerModalAddNewJob()
      }
      this.state.candidateCV.workExperience.sort(function compare(a, b) {
        var dateA;
        var dateB;
        if (a.jobEndDate === 'Present') {
          dateA = new Date(moment());
          dateB = new Date(b.jobEndDate);
        } else if (b.jobEndDate === 'Present') {
          dateB = new Date(moment());
          dateA = new Date(a.jobEndDate);
        } else {
          dateA = new Date(a.jobEndDate);
          dateB = new Date(b.jobEndDate);
        }
        return dateB - dateA;
      });
      await this.resetAddJob();
      return { candidateCV };
    })
  }

  handleEditNewJob = async () => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.workExperience[this.state.tempIndexEditNewJob].jobCompany = this.state.candidateCV.jobCompany;
      candidateCV.workExperience[this.state.tempIndexEditNewJob].jobTitle = this.state.candidateCV.jobTitle;
      candidateCV.workExperience[this.state.tempIndexEditNewJob].jobSector = this.state.candidateCV.jobSector;
      candidateCV.workExperience[this.state.tempIndexEditNewJob].isCurrentWork = this.state.candidateCV.isCurrentWork;
      candidateCV.workExperience[this.state.tempIndexEditNewJob].jobStartDate = this.state.candidateCV.jobStartDate;
      candidateCV.workExperience[this.state.tempIndexEditNewJob].jobEndDate = this.state.candidateCV.jobEndDate;
      candidateCV.workExperience[this.state.tempIndexEditNewJob].jobDescription = this.state.candidateCV.jobDescription;
      return { candidateCV };
    })
    this.state.candidateCV.workExperience.sort(function compare(a, b) {
      var dateA;
      var dateB;
      if (a.jobEndDate === 'Present') {
        dateA = new Date(moment());
        dateB = new Date(b.jobEndDate);
      } else if (b.jobEndDate === 'Present') {
        dateB = new Date(moment());
        dateA = new Date(a.jobEndDate);
      } else {
        dateA = new Date(a.jobEndDate);
        dateB = new Date(b.jobEndDate);
      }
      return dateB - dateA;
    });
    await this.setState({
      modalEditNewJob: !this.state.modalEditNewJob
    })
    await this.resetAddJob()
  }

  handleDeleteNewJob = async (index) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.workExperience.splice(index, 1);
      return { candidateCV };
    })
  }

  triggerModalEditNewJob = async (index) => {
    await this.setState({
      modalEditNewJob: !this.state.modalEditNewJob,
      tempIndexEditNewJob: index
    })
    if (this.state.modalEditNewJob) {
      await this.setState(prevState => {
        let candidateCV = Object.assign({}, prevState.candidateCV)
        candidateCV.jobCompany = this.state.candidateCV.workExperience[index].jobCompany;
        candidateCV.jobTitle = this.state.candidateCV.workExperience[index].jobTitle;
        candidateCV.jobSector = this.state.candidateCV.workExperience[index].jobSector;
        candidateCV.isCurrentWork = this.state.candidateCV.workExperience[index].isCurrentWork;
        candidateCV.jobStartDate = this.state.candidateCV.workExperience[index].jobStartDate;
        candidateCV.jobEndDate = this.state.candidateCV.workExperience[index].jobEndDate;
        candidateCV.jobDescription = this.state.candidateCV.workExperience[index].jobDescription;
        return { candidateCV };
      })
    } else {
      await this.resetAddJob()
    }
  }

  triggerModalAddNewJob = async () => {
    await this.setState({
      modalAddNewJob: !this.state.modalAddNewJob
    })
  }

  // Add Education
  handleChangeEducationSchoolName = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.educationSchoolName = e.target.value;
      return { candidateCV };
    })
  }

  handleChangeIsCurrentEducation = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      if (e.target.checked) {
        candidateCV.isCurrentEducation = e.target.checked;
        candidateCV.educationSchoolEndDate = 'Present'
      } else {
        candidateCV.isCurrentEducation = e.target.checked;
        candidateCV.educationSchoolEndDate = null
      }
      return { candidateCV };
    })
  }


  handleChangeEducationSchoolStartDate = async (date) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.educationSchoolStartDate = date;
      return { candidateCV };
    })
  }

  handleChangeEducationSchoolEndDate = async (date) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.educationSchoolEndDate = date;
      return { candidateCV };
    })
  }

  handleChangeEducationSchoolLevel = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.educationSchoolLevel = value;
      return { candidateCV };
    })
  }

  handleChangeEducationSchoolFieldOfStudy = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.educationSchoolFieldOfStudy = e.target.value;
      return { candidateCV };
    })
  }

  resetAddEducation = async () => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.educationSchoolName = '';
      candidateCV.isCurrentEducation = false;
      candidateCV.educationSchoolLevel = null;
      candidateCV.educationSchoolFieldOfStudy = '';
      candidateCV.educationSchoolStartDate = null;
      candidateCV.educationSchoolEndDate = null;
      return { candidateCV };
    })
  }

  handleChangeIsCreateAnotherEducation = async () => {
    await this.setState({
      isCreateAnotherEducation: !this.state.isCreateAnotherEducation
    })
  }

  handleAddNewEducation = async () => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.educationExperience.unshift({
        educationSchoolName: this.state.candidateCV.educationSchoolName,
        educationSchoolLevel: this.state.candidateCV.educationSchoolLevel,
        educationSchoolFieldOfStudy: this.state.candidateCV.educationSchoolFieldOfStudy,
        isCurrentEducation: this.state.candidateCV.isCurrentEducation,
        educationSchoolStartDate: this.state.candidateCV.educationSchoolStartDate,
        educationSchoolEndDate: this.state.candidateCV.educationSchoolEndDate
      });
      if (!this.state.isCreateAnotherEducation) {
        this.triggerModalAddNewEducation()
      }
      const itemPositions = {};
      for (const [index, id] of educationLevel.entries()) {
        itemPositions[id] = index;
      }

      this.state.candidateCV.educationExperience.sort((a, b) => itemPositions[a.educationSchoolLevel] - itemPositions[b.educationSchoolLevel]).reverse();

      this.resetAddEducation()
      return { candidateCV };
    })
  }

  handleEditNewEducation = async () => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.educationExperience[this.state.tempIndexEditNewEducation].educationSchoolName = this.state.candidateCV.educationSchoolName;
      candidateCV.educationExperience[this.state.tempIndexEditNewEducation].educationSchoolLevel = this.state.candidateCV.educationSchoolLevel;
      candidateCV.educationExperience[this.state.tempIndexEditNewEducation].educationSchoolFieldOfStudy = this.state.candidateCV.educationSchoolFieldOfStudy;
      candidateCV.educationExperience[this.state.tempIndexEditNewEducation].isCurrentEducation = this.state.candidateCV.isCurrentEducation;
      candidateCV.educationExperience[this.state.tempIndexEditNewEducation].educationSchoolStartDate = this.state.candidateCV.educationSchoolStartDate;
      candidateCV.educationExperience[this.state.tempIndexEditNewEducation].educationSchoolEndDate = this.state.candidateCV.educationSchoolEndDate;
      return { candidateCV };
    })
    const itemPositions = {};
    for (const [index, id] of educationLevel.entries()) {
      itemPositions[id] = index;
    }

    this.state.candidateCV.educationExperience.sort((a, b) => itemPositions[a.educationSchoolLevel] - itemPositions[b.educationSchoolLevel]).reverse();
    await this.setState({
      modalEditNewEducation: !this.state.modalEditNewEducation
    })
    await this.resetAddEducation()
  }

  handleDeleteNewEducation = async (index) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.educationExperience.splice(index, 1);
      return { candidateCV };
    })
  }

  triggerModalEditNewEducation = async (index) => {
    await this.setState({
      modalEditNewEducation: !this.state.modalEditNewEducation,
      tempIndexEditNewEducation: index
    })
    if (this.state.modalEditNewEducation) {
      await this.setState(prevState => {
        let candidateCV = Object.assign({}, prevState.candidateCV)
        candidateCV.educationSchoolName = this.state.candidateCV.educationExperience[index].educationSchoolName;
        candidateCV.educationSchoolLevel = this.state.candidateCV.educationExperience[index].educationSchoolLevel;
        candidateCV.educationSchoolFieldOfStudy = this.state.candidateCV.educationExperience[index].educationSchoolFieldOfStudy;
        candidateCV.isCurrentEducation = this.state.candidateCV.educationExperience[index].isCurrentEducation;
        candidateCV.educationSchoolStartDate = this.state.candidateCV.educationExperience[index].educationSchoolStartDate;
        candidateCV.educationSchoolEndDate = this.state.candidateCV.educationExperience[index].educationSchoolEndDate;
        return { candidateCV };
      })
    } else {
      await this.resetAddEducation()
    }
  }

  triggerModalAddNewEducation = async () => {
    await this.setState({
      modalAddNewEducation: !this.state.modalAddNewEducation
    })
  }


  // Career Summary
  handleChangeCareerLevel = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.careerLevel = value;
      return { candidateCV };
    })
  }

  handleChangeCareerLevelOfEducation = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.careerLevelOfEducation = value;
      return { candidateCV };
    })
  }

  handleChangeCareerTotalYearsExperience = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.careerTotalYearsExperience = value;
      return { candidateCV };
    })
  }


  // Skills
  handleChangeTempSkill = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.tempSkill = e.target.value;
      return { candidateCV };
    })
  }

  handleAddNewSkill = async () => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.skills.push(this.state.candidateCV.tempSkill);
      candidateCV.tempSkill = '';
      return { candidateCV };
    })
  }

  handleDeleteNewSkill = async (index) => {
    await this.setState({
      loadingSkill: true
    })
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.skills.splice(index, 1);
      return { candidateCV };
    })
    await this.setState({
      loadingSkill: false
    })
  }

  handleUpdateNewSkill = async (index, e) => {
    await this.setState({
      loadingSkill: true
    })
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.skills[index] = e;
      return { candidateCV };
    })
    await this.setState({
      loadingSkill: false
    })
  }

  // Languages
  handleChangeLanguageName = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.languageName = value;
      return { candidateCV };
    })
  }

  handleChangeLanguageFluency = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.languageFluency = value;
      return { candidateCV };
    })
  }

  handleChangeIsCreateAnotherLanguage = async () => {
    await this.setState({
      isCreateAnotherLanguage: !this.state.isCreateAnotherLanguage
    })
  }

  resetAddLanguages = async () => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.languageName = null;
      candidateCV.languageFluency = null;
      return { candidateCV };
    })
  }

  handleAddNewLanguage = async () => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.languages.push({
        languageName: this.state.candidateCV.languageName,
        languageFluency: this.state.candidateCV.languageFluency
      });
      if (!this.state.isCreateAnotherLanguage) {
        this.triggerModalAddNewLanguage();
      }
      this.resetAddLanguages()
      return { candidateCV };
    })
  }

  handleUpdateNewLanguage = async () => {
    await this.setState({
      loadingLanguages: true
    })
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.languages[this.state.tempIndexEditNewELanguage].languageName = this.state.candidateCV.languageName;
      candidateCV.languages[this.state.tempIndexEditNewELanguage].languageFluency = this.state.candidateCV.languageFluency;
      return { candidateCV };
    })
    this.resetAddLanguages();
    this.triggerModalEditNewLanguage();
    await this.setState({
      loadingLanguages: false
    })
  }

  handleDeleteNewLanguage = async (index) => {
    await this.setState({
      loadingLanguages: true
    })
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.languages.splice(index, 1);
      return { candidateCV };
    })
    await this.setState({
      loadingLanguages: false
    })
  }

  triggerModalEditNewLanguage = async (index) => {
    await this.setState({
      modalEditNewLanguage: !this.state.modalEditNewLanguage,
      tempIndexEditNewELanguage: index
    })
    if (this.state.modalEditNewLanguage) {
      await this.setState(prevState => {
        let candidateCV = Object.assign({}, prevState.candidateCV)
        candidateCV.languageName = this.state.candidateCV.languages[index].languageName;
        candidateCV.languageFluency = this.state.candidateCV.languages[index].languageFluency;
        return { candidateCV };
      })
    } else {
      await this.resetAddLanguages()
    }
  }

  triggerModalAddNewLanguage = async () => {
    await this.setState({
      modalAddNewLanguage: !this.state.modalAddNewLanguage
    })
  }


  // Hobby
  handleChangeTempHobby = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.tempHobby = e.target.value;
      return { candidateCV };
    })
  }

  handleAddNewHobby = async () => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.hobbies.push(this.state.candidateCV.tempHobby);
      candidateCV.tempHobby = '';
      return { candidateCV };
    })
  }

  handleDeleteNewHobby = async (index) => {
    await this.setState({
      loadingHobby: true
    })
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.hobbies.splice(index, 1);
      return { candidateCV };
    })
    await this.setState({
      loadingHobby: false
    })
  }

  handleUpdateNewHobby = async (index, e) => {
    await this.setState({
      loadingHobby: true
    })
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.hobbies[index] = e;
      return { candidateCV };
    })
    await this.setState({
      loadingHobby: false
    })
  }


  // Notice Period
  handleChangeNoticePeriod = async (value) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.noticePeriod = value;
      return { candidateCV };
    })
  }


  // Referals
  handleReferalFullName = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.referalFullName = e.target.value;
      return { candidateCV };
    })
  }

  handleReferalJobTitle = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.referalJobTitle = e.target.value;
      return { candidateCV };
    })
  }

  handleReferalCompanyName = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.referalCompany = e.target.value;
      return { candidateCV };
    })
  }

  handleReferalMobileNum = async (e) => {
    await this.setState(prevState => {
      let candidateCV = Object.assign({}, prevState.candidateCV)
      candidateCV.referalMobileNum = e.target.value;
      return { candidateCV };
    })
  }


  validateAndCreateCV = async () => {
    console.log(this.state.candidateCV)
  }



  // Edit Profile
  // Personal Information
  triggerModalEditPersonalInformation = async () => {
    await this.setState({
      modalEditPersonalInformation: !this.state.modalEditPersonalInformation
    })
  }

  // Profile Information
  triggerModalEditProfileInformation = async () => {
    await this.setState({
      modalEditProfileInformation: !this.state.modalEditProfileInformation
    })
  }

  // Career Summary
  triggerModalEditCareerSummary = async () => {
    await this.setState({
      modalEditCareerSummary: !this.state.modalEditCareerSummary
    })
  }

  // Notice Period
  triggerModalEditNoticePeriod = async () => {
    await this.setState({
      modalEditNoticePeriod: !this.state.modalEditNoticePeriod
    })
  }


  // Referrals
  triggerModalEditReferrals = async () => {
    await this.setState({
      modalEditReferrals: !this.state.modalEditReferrals
    })
  }



  // Employer
  // Post new job
  // handleChangeEducationSchoolName = async (e) => {
  //   await this.setState(prevState => {
  //     let candidateCV = Object.assign({}, prevState.candidateCV)
  //     candidateCV.educationSchoolName = e.target.value;
  //     return { candidateCV };
  //   })
  // }

  handleChangeIsPostAnotherJob = async () => {
    await this.setState({
      isPostAnotherJob: !this.state.isPostAnotherJob
    })
  }

  triggerModalPostNewJob = async () => {
    await this.setState({
      modalPostNewJob: !this.state.modalPostNewJob
    })
  }

  handleBreadCrumbItems = async (newBreadcrumbItems) => {
    await this.setState({
      breadcrumbItems: newBreadcrumbItems
    })
  }

  render() {
    return (
      <HashRouter>
        <Layout>
          <div style={{ zIndex: 999, position: 'fixed', bottom: 0, right: 0, margin: 20 }}>
            <Tooltip title='Chat with us'>
              
              <Button
                href='https://wa.link/cuk14j'
                target='_blank'
                className='whatsapp-btn'
                type='icon'
                style={{
                  height: '53px',
                  width: '53px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: 'none'
                }}
                icon={
                  <FaWhatsapp
                    className='whatapp-btn-icon'
                    size={34}
                    color='white'
                  />}
              />
            </Tooltip>
          </div>
          <Affix offsetTop={0}>
            <Header style={{ minHeight: '5vh', background: 'white' }}>
              <Topbar {...this.state} />
            </Header>
          </Affix>
          <Content style={{ minHeight: '90vh', margin: '20px 45px 12px 45px', padding: 20 }}>
            {/* style={{ margin: '12px 45px 12px 45px' }} */}
            <Routes>


              <Route path={"/"} element={
                <Homepage
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onLoginFinish={this.onLoginFinish.bind(this)}
                  onLoginFinishFailed={this.onLoginFinishFailed.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />


              <Route path={"/register/candidate/"} element={
                <CandidateRegister
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                  onRegisterFinish={this.onRegisterFinish.bind(this)}
                  onRegisterFinishFailed={this.onRegisterFinishFailed.bind(this)}
                />
              } />


              <Route path={"/register/employer/"} element={
                <EmployerRegister
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                  onRegisterFinish={this.onRegisterFinish.bind(this)}
                  onRegisterFinishFailed={this.onRegisterFinishFailed.bind(this)}
                />
              } />


              <Route path={"/profile/"} element={
                <Profile
                  {...this.state}

                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                  triggerModalEditPersonalInformation={this.triggerModalEditPersonalInformation.bind(this)}
                  triggerModalEditProfileInformation={this.triggerModalEditProfileInformation.bind(this)}

                  // Personal Description
                  handleUpdateProfilePic={this.handleUpdateProfilePic.bind(this)}
                  handleBeforeUploadProfilePic={this.handleBeforeUploadProfilePic.bind(this)}
                  handleChangeFullName={this.handleChangeFullName.bind(this)}
                  handleChangeDOB={this.handleChangeDOB.bind(this)}
                  handleChangeGender={this.handleChangeGender.bind(this)}
                  handleChangeID={this.handleChangeID.bind(this)}
                  handleChangeHomeAddress={this.handleChangeHomeAddress.bind(this)}
                  handleChangeDistrict={this.handleChangeDistrict.bind(this)}
                  handleChangeSalaryRange={this.handleChangeSalaryRange.bind(this)}
                  handleChangeHomePhoneNum={this.handleChangeHomePhoneNum.bind(this)}
                  handleChangeMobileNum={this.handleChangeMobileNum.bind(this)}
                  handleChangeEmailAddress={this.handleChangeEmailAddress.bind(this)}
                  handleChangeDrivingLicense={this.handleChangeDrivingLicense.bind(this)}
                  handleChangeNationality={this.handleChangeNationality.bind(this)}
                  handleChangeLinkedIn={this.handleChangeLinkedIn.bind(this)}
                  handleChangeJobPreference={this.handleChangeJobPreference.bind(this)}


                  // Profile
                  handleChangeProfileDescription={this.handleChangeProfileDescription.bind(this)}


                  // Work Experience
                  handleAddNewJob={this.handleAddNewJob.bind(this)}
                  handleEditNewJob={this.handleEditNewJob.bind(this)}
                  handleDeleteNewJob={this.handleDeleteNewJob.bind(this)}
                  handleChangeJobCompany={this.handleChangeJobCompany.bind(this)}
                  handleChangeJobTitle={this.handleChangeJobTitle.bind(this)}
                  handleChangeIsCurrentWork={this.handleChangeIsCurrentWork.bind(this)}
                  handleChangeJobSector={this.handleChangeJobSector.bind(this)}
                  handleChangeJobStartDate={this.handleChangeJobStartDate.bind(this)}
                  handleChangeJobEndDate={this.handleChangeJobEndDate.bind(this)}
                  handleChangeJobDescription={this.handleChangeJobDescription.bind(this)}
                  triggerModalAddNewJob={this.triggerModalAddNewJob.bind(this)}
                  triggerModalEditNewJob={this.triggerModalEditNewJob.bind(this)}
                  handleChangeIsCreateAnotherJob={this.handleChangeIsCreateAnotherJob.bind(this)}


                  // Education experience
                  handleAddNewEducation={this.handleAddNewEducation.bind(this)}
                  handleEditNewEducation={this.handleEditNewEducation.bind(this)}
                  handleDeleteNewEducation={this.handleDeleteNewEducation.bind(this)}
                  handleChangeEducationSchoolName={this.handleChangeEducationSchoolName.bind(this)}
                  handleChangeEducationSchoolLevel={this.handleChangeEducationSchoolLevel.bind(this)}
                  handleChangeEducationSchoolFieldOfStudy={this.handleChangeEducationSchoolFieldOfStudy.bind(this)}
                  handleChangeIsCurrentEducation={this.handleChangeIsCurrentEducation.bind(this)}
                  handleChangeEducationSchoolStartDate={this.handleChangeEducationSchoolStartDate.bind(this)}
                  handleChangeEducationSchoolEndDate={this.handleChangeEducationSchoolEndDate.bind(this)}
                  triggerModalAddNewEducation={this.triggerModalAddNewEducation.bind(this)}
                  triggerModalEditNewEducation={this.triggerModalEditNewEducation.bind(this)}
                  handleChangeIsCreateAnotherEducation={this.handleChangeIsCreateAnotherEducation.bind(this)}


                  // Career Summary
                  triggerModalEditCareerSummary={this.triggerModalEditCareerSummary.bind(this)}
                  handleChangeCareerLevel={this.handleChangeCareerLevel.bind(this)}
                  handleChangeCareerLevelOfEducation={this.handleChangeCareerLevelOfEducation.bind(this)}
                  handleChangeCareerTotalYearsExperience={this.handleChangeCareerTotalYearsExperience.bind(this)}


                  // Skills
                  handleChangeTempSkill={this.handleChangeTempSkill.bind(this)}
                  handleAddNewSkill={this.handleAddNewSkill.bind(this)}
                  handleDeleteNewSkill={this.handleDeleteNewSkill.bind(this)}
                  handleUpdateNewSkill={this.handleUpdateNewSkill.bind(this)}


                  // Languages
                  handleChangeLanguageName={this.handleChangeLanguageName.bind(this)}
                  handleChangeLanguageFluency={this.handleChangeLanguageFluency.bind(this)}
                  handleAddNewLanguage={this.handleAddNewLanguage.bind(this)}
                  handleUpdateNewLanguage={this.handleUpdateNewLanguage.bind(this)}
                  handleDeleteNewLanguage={this.handleDeleteNewLanguage.bind(this)}
                  triggerModalAddNewLanguage={this.triggerModalAddNewLanguage.bind(this)}
                  triggerModalEditNewLanguage={this.triggerModalEditNewLanguage.bind(this)}
                  handleChangeIsCreateAnotherLanguage={this.handleChangeIsCreateAnotherLanguage.bind(this)}


                  // Hobby
                  handleChangeTempHobby={this.handleChangeTempHobby.bind(this)}
                  handleAddNewHobby={this.handleAddNewHobby.bind(this)}
                  handleDeleteNewHobby={this.handleDeleteNewHobby.bind(this)}
                  handleUpdateNewHobby={this.handleUpdateNewHobby.bind(this)}


                  // Notice Period
                  triggerModalEditNoticePeriod={this.triggerModalEditNoticePeriod.bind(this)}
                  handleChangeNoticePeriod={this.handleChangeNoticePeriod.bind(this)}


                  // Referals
                  handleReferalFullName={this.handleReferalFullName.bind(this)}
                  handleReferalJobTitle={this.handleReferalJobTitle.bind(this)}
                  handleReferalCompanyName={this.handleReferalCompanyName.bind(this)}
                  handleReferalMobileNum={this.handleReferalMobileNum.bind(this)}
                  triggerModalEditReferrals={this.triggerModalEditReferrals.bind(this)}

                />
              } />

              <Route path={"/cv/create/"} element={
                <CreateCV
                  {...this.state}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}


                  // Personal Information
                  handleUpdateProfilePic={this.handleUpdateProfilePic.bind(this)}
                  handleBeforeUploadProfilePic={this.handleBeforeUploadProfilePic.bind(this)}
                  handleChangeFullName={this.handleChangeFullName.bind(this)}
                  handleChangeDOB={this.handleChangeDOB.bind(this)}
                  handleChangeGender={this.handleChangeGender.bind(this)}
                  handleChangeID={this.handleChangeID.bind(this)}
                  handleChangeHomeAddress={this.handleChangeHomeAddress.bind(this)}
                  handleChangeDistrict={this.handleChangeDistrict.bind(this)}
                  handleChangeSalaryRange={this.handleChangeSalaryRange.bind(this)}
                  handleChangeHomePhoneNum={this.handleChangeHomePhoneNum.bind(this)}
                  handleChangeMobileNum={this.handleChangeMobileNum.bind(this)}
                  handleChangeEmailAddress={this.handleChangeEmailAddress.bind(this)}
                  handleChangeDrivingLicense={this.handleChangeDrivingLicense.bind(this)}
                  handleChangeNationality={this.handleChangeNationality.bind(this)}
                  handleChangeLinkedIn={this.handleChangeLinkedIn.bind(this)}
                  handleChangeJobPreference={this.handleChangeJobPreference.bind(this)}


                  // Profile
                  handleChangeProfileDescription={this.handleChangeProfileDescription.bind(this)}


                  // Work experience
                  handleAddNewJob={this.handleAddNewJob.bind(this)}
                  handleEditNewJob={this.handleEditNewJob.bind(this)}
                  handleDeleteNewJob={this.handleDeleteNewJob.bind(this)}
                  handleChangeJobCompany={this.handleChangeJobCompany.bind(this)}
                  handleChangeJobTitle={this.handleChangeJobTitle.bind(this)}
                  handleChangeIsCurrentWork={this.handleChangeIsCurrentWork.bind(this)}
                  handleChangeJobSector={this.handleChangeJobSector.bind(this)}
                  handleChangeJobStartDate={this.handleChangeJobStartDate.bind(this)}
                  handleChangeJobEndDate={this.handleChangeJobEndDate.bind(this)}
                  handleChangeJobDescription={this.handleChangeJobDescription.bind(this)}


                  // Education experience
                  handleAddNewEducation={this.handleAddNewEducation.bind(this)}
                  handleEditNewEducation={this.handleEditNewEducation.bind(this)}
                  handleDeleteNewEducation={this.handleDeleteNewEducation.bind(this)}
                  handleChangeEducationSchoolName={this.handleChangeEducationSchoolName.bind(this)}
                  handleChangeEducationSchoolLevel={this.handleChangeEducationSchoolLevel.bind(this)}
                  handleChangeEducationSchoolFieldOfStudy={this.handleChangeEducationSchoolFieldOfStudy.bind(this)}
                  handleChangeIsCurrentEducation={this.handleChangeIsCurrentEducation.bind(this)}
                  handleChangeEducationSchoolStartDate={this.handleChangeEducationSchoolStartDate.bind(this)}
                  handleChangeEducationSchoolEndDate={this.handleChangeEducationSchoolEndDate.bind(this)}


                  // Career Summary
                  handleChangeCareerLevel={this.handleChangeCareerLevel.bind(this)}
                  handleChangeCareerLevelOfEducation={this.handleChangeCareerLevelOfEducation.bind(this)}
                  handleChangeCareerTotalYearsExperience={this.handleChangeCareerTotalYearsExperience.bind(this)}


                  // Skills
                  handleChangeTempSkill={this.handleChangeTempSkill.bind(this)}
                  handleAddNewSkill={this.handleAddNewSkill.bind(this)}
                  handleDeleteNewSkill={this.handleDeleteNewSkill.bind(this)}
                  handleUpdateNewSkill={this.handleUpdateNewSkill.bind(this)}


                  // Languages
                  handleChangeLanguageName={this.handleChangeLanguageName.bind(this)}
                  handleChangeLanguageFluency={this.handleChangeLanguageFluency.bind(this)}
                  handleAddNewLanguage={this.handleAddNewLanguage.bind(this)}
                  handleUpdateNewLanguage={this.handleUpdateNewLanguage.bind(this)}
                  handleDeleteNewLanguage={this.handleDeleteNewLanguage.bind(this)}


                  // Modals 
                  triggerModalAddNewJob={this.triggerModalAddNewJob.bind(this)}
                  triggerModalEditNewJob={this.triggerModalEditNewJob.bind(this)}
                  triggerModalAddNewEducation={this.triggerModalAddNewEducation.bind(this)}
                  triggerModalEditNewEducation={this.triggerModalEditNewEducation.bind(this)}
                  triggerModalAddNewLanguage={this.triggerModalAddNewLanguage.bind(this)}
                  triggerModalEditNewLanguage={this.triggerModalEditNewLanguage.bind(this)}
                  handleChangeIsCreateAnotherJob={this.handleChangeIsCreateAnotherJob.bind(this)}
                  handleChangeIsCreateAnotherEducation={this.handleChangeIsCreateAnotherEducation.bind(this)}
                  handleChangeIsCreateAnotherLanguage={this.handleChangeIsCreateAnotherLanguage.bind(this)}


                  // Hobby
                  handleChangeTempHobby={this.handleChangeTempHobby.bind(this)}
                  handleAddNewHobby={this.handleAddNewHobby.bind(this)}
                  handleDeleteNewHobby={this.handleDeleteNewHobby.bind(this)}
                  handleUpdateNewHobby={this.handleUpdateNewHobby.bind(this)}


                  // Notice Period
                  handleChangeNoticePeriod={this.handleChangeNoticePeriod.bind(this)}


                  // Referals
                  handleReferalFullName={this.handleReferalFullName.bind(this)}
                  handleReferalJobTitle={this.handleReferalJobTitle.bind(this)}
                  handleReferalCompanyName={this.handleReferalCompanyName.bind(this)}
                  handleReferalMobileNum={this.handleReferalMobileNum.bind(this)}

                  // Validate CV
                  validateAndCreateCV={this.validateAndCreateCV.bind(this)}
                />
              } />


              <Route path={"/settings/"} element={
                <Settings
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  convertParagraphToBulletPoint={this.convertParagraphToBulletPoint.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />

              <Route path={"/settings/account/"} element={
                <Account
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />

              <Route path={'/settings/reset/'} element={
                <ResetInfo
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              }
              />

              <Route path={'/settings/reset/username/'} element={
                <ResetUsrname
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              }
              />

              <Route path={'/settings/reset/password/'} element={
                <ResetPwd
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              }
              />

              <Route path={'/settings/package/'} element={
                <MyPackage
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              }
              />

              <Route path={'/settings/package/history/'} element={
                <MyPackageHist
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              }
              />

              <Route path={"/pricing/"} element={
                <Pricing
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />

              {/* <Route path={"/services/"} element={
                <Services
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } /> */}

              <Route path={"/services/recruitment/"} element={
                <Service1
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />

              <Route path={"/services/hr-consulting/"} element={
                <Service2
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />

              <Route path={"/career-advice/"} element={
                <CareerAdvice
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />

              <Route path={"/contact-us/"} element={
                <Contact
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />

              <Route path={"/dashboard/"} element={
                <Dashboard
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  convertParagraphToBulletPoint={this.convertParagraphToBulletPoint.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />

              <Route path={"/dashboard/job/drafts/"} element={
                <Drafts
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  convertParagraphToBulletPoint={this.convertParagraphToBulletPoint.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />
              <Route path={"/dashboard/job/active/"} element={
                <ActiveJobs
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  convertParagraphToBulletPoint={this.convertParagraphToBulletPoint.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />

              <Route path={"/ats/"} element={
                <ATS
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  convertParagraphToBulletPoint={this.convertParagraphToBulletPoint.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />
              \
              <Route exact path={"/ats/active/:id/"} element={
                <ATS_Active
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  convertParagraphToBulletPoint={this.convertParagraphToBulletPoint.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />

              <Route exact path={"/company/:id/"} element={
                <PublicCompany
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  convertParagraphToBulletPoint={this.convertParagraphToBulletPoint.bind(this)}
                  onTopbarKeyChange={this.onTopbarKeyChange.bind(this)}
                />
              } />

              <Route path={'/job/create/'} element={
                <CreateJob
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  handleBulletPointInput={this.handleBulletPointInput.bind(this)}
                  handleChangeEmployerJobTitle={this.handleChangeEmployerJobTitle.bind(this)}
                  handleChangeEmployerJobDescription={this.handleChangeEmployerJobDescription.bind(this)}
                  handleChangeEmployerJobCategory={this.handleChangeEmployerJobCategory.bind(this)}
                  handleChangeEmployerJobType={this.handleChangeEmployerJobType.bind(this)}
                  handleChangeEmployerJobLocation={this.handleChangeEmployerJobLocation.bind(this)}
                  handleChangeEmployerJobSalary={this.handleChangeEmployerJobSalary.bind(this)}
                  handleChangeEmployerJobCareerLevel={this.handleChangeEmployerJobCareerLevel.bind(this)}
                  handleChangeEmployerJobQualificationsRequired={this.handleChangeEmployerJobQualificationsRequired.bind(this)}
                  handleChangeEmployerQualificationsAndExperience={this.handleChangeEmployerQualificationsAndExperience.bind(this)}
                  handleChangeEmployerRolesAndResponsibilities={this.handleChangeEmployerRolesAndResponsibilities.bind(this)}
                  handleChangeEmployerOtherRequirements={this.handleChangeEmployerOtherRequirements.bind(this)}
                  handleChangeEmployerRemarks={this.handleChangeEmployerRemarks.bind(this)}
                  handleChangeEmployerJobVisibility={this.handleChangeEmployerJobVisibility.bind(this)}

                  handleChangeEmployerJobCompany={this.handleChangeEmployerJobCompany.bind(this)}
                  handleChangeEmployerJobContactPerson={this.handleChangeEmployerJobContactPerson.bind(this)}
                  handleChangeEmployerJobStartDate={this.handleChangeEmployerJobStartDate.bind(this)}
                  handleChangeEmployerJobEndDate={this.handleChangeEmployerJobEndDate.bind(this)}

                  handleSubmitPostNewJob={this.handleSubmitPostNewJob.bind(this)}
                />
              }>

              </Route>

              <Route path={'/dashboard/job/active/edit/:id'} element={
                <EditJob
                  {...this.state}
                  handleBreadCrumbItems={this.handleBreadCrumbItems.bind(this)}
                  handleBulletPointInput={this.handleBulletPointInput.bind(this)}
                  handleChangeEmployerJobTitle={this.handleChangeEmployerJobTitle.bind(this)}
                  handleChangeEmployerJobDescription={this.handleChangeEmployerJobDescription.bind(this)}
                  handleChangeEmployerJobCategory={this.handleChangeEmployerJobCategory.bind(this)}
                  handleChangeEmployerJobType={this.handleChangeEmployerJobType.bind(this)}
                  handleChangeEmployerJobLocation={this.handleChangeEmployerJobLocation.bind(this)}
                  handleChangeEmployerJobSalary={this.handleChangeEmployerJobSalary.bind(this)}
                  handleChangeEmployerJobCareerLevel={this.handleChangeEmployerJobCareerLevel.bind(this)}
                  handleChangeEmployerJobQualificationsRequired={this.handleChangeEmployerJobQualificationsRequired.bind(this)}
                  handleChangeEmployerQualificationsAndExperience={this.handleChangeEmployerQualificationsAndExperience.bind(this)}
                  handleChangeEmployerRolesAndResponsibilities={this.handleChangeEmployerRolesAndResponsibilities.bind(this)}
                  handleChangeEmployerOtherRequirements={this.handleChangeEmployerOtherRequirements.bind(this)}
                  handleChangeEmployerRemarks={this.handleChangeEmployerRemarks.bind(this)}
                  handleChangeEmployerJobVisibility={this.handleChangeEmployerJobVisibility.bind(this)}

                  handleChangeEmployerJobCompany={this.handleChangeEmployerJobCompany.bind(this)}
                  handleChangeEmployerJobContactPerson={this.handleChangeEmployerJobContactPerson.bind(this)}
                  handleChangeEmployerJobStartDate={this.handleChangeEmployerJobStartDate.bind(this)}
                  handleChangeEmployerJobEndDate={this.handleChangeEmployerJobEndDate.bind(this)}

                  handleSubmitPostNewJob={this.handleSubmitPostNewJob.bind(this)}
                />
              }>

              </Route>


            </Routes>

          </Content>

          <Footer style={{ minHeight: '5vh', textAlign: 'center', background: 'white' }}>
            <Row>
              <Col xs={24} sm={24} md={16} lg={8} style={{ textAlign: 'left' }}>
                <Typography.Title level={3}>
                  Get in touch
                </Typography.Title>
                <Typography.Text>
                  <GiPhone /> +230 59097777
                </Typography.Text>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} style={{ textAlign: 'left' }}>
                <Typography.Title level={3}>
                  Resources
                </Typography.Title>
                <Typography.Title level={5}>
                  <a href='#'>Privacy Policy</a>
                </Typography.Title>
                <Typography.Title level={5}>
                  <a href='#'>Terms of service</a>
                </Typography.Title>
                <Typography.Title level={5}>
                  <a href='#'>About us</a>
                </Typography.Title>
              </Col>
              <Col xs={24} sm={24} md={16} lg={8}>
                <Space direction='horizontal' style={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                  <img src={logo} height={40} style={{ margin: 0 }} />
                  <Typography.Title level={2}>
                    boulot.mu
                  </Typography.Title>
                </Space>
                <div style={{ textAlign: 'left' }}>
                  <GrFacebook size={22} style={{ margin: 10 }} />
                  <GrInstagram size={22} style={{ margin: 10 }} />
                  <GrLinkedin size={22} style={{ margin: 10 }} />
                </div>
              </Col>
            </Row>
            <Divider />
            <Typography.Text>
              Copyright 2022. boulot.mu. All Rights Reserved
            </Typography.Text>
          </Footer>
        </Layout>
      </HashRouter>
    )
  }
}

export default App;
