import { Button, Card, Col, Divider, Form, Input, Progress, Row, Table, Tag, Typography } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { scrollUp, themeColor } from '../../constants';

class MyPackage extends Component {
    async componentDidMount() {
        scrollUp()
        this.props.onTopbarKeyChange('')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Settings',
                path: '/settings/'
            },
            {
                item: 'My Package',
                path: '/settings/package/'
            },

        ])
    }

    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='My Package' />
                <Row>
                    <Col lg={7} md={0} sm={0} xs={0} />
                    <Col xs={24} sm={24} md={24} lg={10}>
                        <div style={{ background: 'white', borderRadius: 20, textAlign: 'center' }}>
                            <div style={{ position: 'absolute', top: 10, right: 10 }}>
                                <Tag color="#87d068" style={{ borderRadius: 20 }}>Approved</Tag>
                            </div>

                            <div style={{ background: themeColor, width: '100%', textAlign: 'center', padding: 20, borderRadius: '10px 10px 0 0' }} >
                                <Typography.Title style={{ color: 'white', margin: -15 }}>
                                    SME Basic
                                </Typography.Title>
                            </div>

                            <div style={{ paddingTop: 10, paddingBottom: 20 }}>
                                <Row gutter={[16, 16]}>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <Typography.Text>
                                            Jobs
                                        </Typography.Text>
                                        <Typography.Title level={3} style={{ marginTop: 0 }}>
                                            Entitled
                                        </Typography.Title>
                                        <Progress
                                            type="circle"
                                            strokeColor={themeColor}
                                            percent={100}
                                            width={100}
                                            format={() => <div>
                                                <Typography.Text style={{ color: themeColor, margin: 2 }}>
                                                    12
                                                </Typography.Text>
                                            </div>
                                            } />
                                    </Col>

                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <Typography.Text>
                                            Jobs
                                        </Typography.Text>
                                        <Typography.Title level={3} style={{ marginTop: 0 }}>
                                            Used
                                        </Typography.Title>
                                        <Progress
                                            type="circle"
                                            strokeColor={themeColor}
                                            percent={100}
                                            width={100}
                                            format={() => <div>
                                                <Typography.Text level={3} style={{ color: themeColor, margin: 2 }}>
                                                    4
                                                </Typography.Text>
                                            </div>
                                            } />
                                    </Col>

                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <Typography.Text>
                                            Jobs
                                        </Typography.Text>
                                        <Typography.Title level={3} style={{ marginTop: 0 }}>
                                            Balance
                                        </Typography.Title>
                                        <Progress
                                            type="circle"
                                            strokeColor={themeColor}
                                            percent={100}
                                            width={100}
                                            format={() => <div>
                                                <Typography.Text level={3} style={{ color: themeColor, margin: 2 }}>
                                                    8
                                                </Typography.Text>
                                            </div>
                                            } />
                                    </Col>
                                </Row>

                                {/* <Divider />

                                <Row>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <Typography.Text style={{ fontSize: 20 }}>
                                            Jobs entitled:
                                        </Typography.Text>
                                        <Typography.Title level={2} style={{ margin: 0 }}>
                                            12
                                        </Typography.Title>
                                    </Col>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <Typography.Text style={{ fontSize: 20 }}>
                                            Jobs used:
                                        </Typography.Text>
                                        <Typography.Title level={2} style={{ margin: 0 }}>
                                            4
                                        </Typography.Title>
                                    </Col>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <Typography.Text style={{ fontSize: 20 }}>
                                            Jobs balance:
                                        </Typography.Text>
                                        <Typography.Title level={2} style={{ margin: 0 }}>
                                            8
                                        </Typography.Title>
                                    </Col>
                                </Row> */}

                                <br />
                                <Typography.Text>Valid from 6th August 2021 to <span style={{ color: 'red', fontWeight: 'bold' }}>6th August 2022</span></Typography.Text>
                                <br />
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', padding: 15 }}>
                            <Link to={'/settings/package/history/'}>
                                <Typography.Text style={{ fontWeight: 'bold', color: 'blue' }}>
                                    View package history
                                </Typography.Text>
                            </Link>
                        </div>
                    </Col>
                    <Col lg={7} md={0} sm={0} xs={0} />

                </Row>
            </div>
        );
    }
}

export default MyPackage;