import react, { Component } from 'react';
import { Form, Input, Button, Checkbox, Col, Row, Card, Typography } from 'antd';
import { Link } from 'react-router-dom';

class CandidateLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div style={{ padding: '10px', textAlign: 'center' }}>
                <Card
                    style={{ width: '100%' }}
                    title={<div style={{ textAlign: 'left' }}>Candidate Login</div>}
                    extra={<Link to={"/register/candidate/"}>Register</Link>}
                >
                    <Form
                        name="basic"
                        layout='horizontal'
                        labelWrap={true}
                        labelAlign='left'
                        labelCol={{ span: 8 }}
                        wrapperCol={{
                            span: 24,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={this.props.onLoginFinish}
                        onFinishFailed={this.props.onLoginFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label={<span style={{ padding: 0, margin: 0 }}>Email</span>}
                            name="emailaddress"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email address!',
                                },
                            ]}
                        // style={{margin: 0}}
                        >
                            <Input placeholder='Email address' />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                            style={{ marginBottom: 0 }}
                        >
                            <Input.Password placeholder='Password' />
                        </Form.Item>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <Form.Item
                                    name="remember"
                                    valuePropName="checked"
                                    style={{ margin: '5px 0 0 0', textAlign: 'left' }}
                                >
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12}
                                style={{ margin: '10px 0 0 0' }}
                            >
                                <Link to={'#'}>
                                    <Typography.Text>
                                        Forgot Password
                                    </Typography.Text>
                                </Link>
                            </Col>
                        </Row>


                        <Row>
                            <Col span={24}>
                                <Form.Item style={{ marginTop: 5, marginBottom: 0 }}>
                                    <Button type='primary' style={{ background: '#2D3691', color: 'white', width: '100%', borderRadius: 2 }} htmlType="submit">
                                        Login
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Card>
            </div>
        )
    }
}

export default CandidateLogin;