import { Button, Table } from 'antd';
import react, { Component } from 'react';
import { CgArrowDown, CgArrowUp } from 'react-icons/cg';
import SearchResultsExpanded from './SearchResultsExpanded';

class SearchResults extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expandedKeys: []
        }
    }

    onExpand = (expanded, { key }) => {
        const keys = this.state.expandedKeys
        const expandedKeys = expanded
            ? keys.concat(key)
            : keys.filter(k => k !== key)
        this.setState({ expandedKeys })
    }
    onRow = ({ key }) => this.state.expandedKeys.includes(key) && { className: "expand-parent" }

    customExpandIcon(props) {
        if (props.expanded) {
            return <a style={{ color: 'black' }} onClick={e => {
                props.onExpand(props.record, e);
            }}>
                <Button
                    icon={
                        <CgArrowUp style={{ borderRadius: 20, margin: 0 }} />
                    }
                    style={{ background: 'white' }}
                    type="plus"
                />
            </a>
        } else {
            return <a style={{ color: 'black' }} onClick={e => {
                props.onExpand(props.record, e);
            }}>
                <Button icon={<CgArrowDown />} type="minus" />
            </a>
        }
    }



    render() {
        return (
            <Table
                columns={this.props.searchResultsTableColumns}
                expandable={{
                    expandedRowRender: record => <SearchResultsExpanded
                        style={{ margin: 0 }}
                        {...this.props}
                        details={record.jobexpanddetails}
                    />,
                }}
                scroll={{ x: 400 }}
                onExpand={this.onExpand}
                expandIcon={(props) => this.customExpandIcon(props)}
                expandedRowKeys={this.state.expandedKeys}
                expandRowByClick={true}
                onRow={this.onRow}
                // onRow={(record, rowIndex) => {
                //     return {
                //         onClick: event => { }, // click row
                //         onDoubleClick: event => { }, // double click row
                //         onContextMenu: event => { }, // right button click row
                //         onMouseEnter: event => { }, // mouse enter row
                //         onMouseLeave: event => { }, // mouse leave row
                //     };
                // }}
                dataSource={this.props.searchResultsTableData}
            />
        )
    }
}

export default SearchResults