import { Button, Col, Row } from 'antd';
import React, { Component } from 'react';
import { IoAddCircleSharp } from 'react-icons/io5';
import { MdDrafts } from 'react-icons/md';
import { Link } from 'react-router-dom';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { scrollUp } from '../../constants';


class ResetInfo extends Component {
    async componentDidMount() {
        scrollUp()
        this.props.onTopbarKeyChange('')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Settings',
                path: '/settings/'
            },
            {
                item: 'Reset Info',
                path: '/settings/reset/'
            },

        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='Reset Info' />
                <Row gutter={[16, 16]}>
                    <Col>
                        <Link to="/settings/reset/password/" >
                            <Button style={{ height: 150, width: 150 }}>
                                <IoAddCircleSharp size={35} />
                                <br />
                                Reset password
                            </Button>
                        </Link>
                    </Col>
                    <Col>
                        <Link to="/settings/reset/username/" >
                            <Button style={{ height: 150, width: 150, whiteSpace: 'normal' }}>
                                <MdDrafts size={35} />
                                <br />
                                Reset username
                            </Button>
                        </Link>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default ResetInfo;