import React, { Component } from 'react';
import moment from 'moment';
import { Modal, Button, Checkbox, Row, Col, Input, Select, DatePicker, Card, Typography, } from 'antd';
import { bulletWithSpace, careerLevels, districts, educationLevel, employerJobSalaryRange, jobTypes, scrollUp, sectors } from '../../constants';
import { MuiBulletedTextArea } from 'react-bulleted-textarea'
import BreadcrumbNav from '../../components/BreadcrumbNav';

const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

class CreateJob extends Component {
    async componentDidMount() {
        scrollUp()
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Dashboard',
                path: '/dashboard/'
            },
            {
                item: 'Post a Job',
                path: '/job/create/'
            },
        ])
    }
    disabledEndDate = (current) => {
        return (current && current < moment().endOf('day')) || (current < moment(this.props.employer.postNewJob.jobAddedDate).endOf('day')) || (current > moment(this.props.employer.postNewJob.jobAddedDate).endOf('day').add(30, 'days'));
    }

    disabledStartDate = (current) => {
        return current && current < moment().endOf('day');
    }

    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <h1 style={{ textAlign: 'center', fontSize: 30 }}>
                    Post a new job
                </h1>
                <Row className='create-job-section-information' style={{color: 'red'}}>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>No. of job remaining: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <Text style={{color: 'red'}}>
                            6
                        </Text>
                    </Col>
                </Row>

                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Job Title: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <Input
                            autoFocus
                            placeholder='Please enter the job title'
                            // style={{ width: '90%' }}
                            value={this.props.employer.postNewJob.jobTitle}
                            onChange={this.props.handleChangeEmployerJobTitle}
                        />
                    </Col>
                </Row>

                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Job Summary: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <TextArea
                            placeholder='Please enter the job description'
                            rows={3}
                            maxLength={2000}
                            showCount
                            // style={{ width: '90%' }}
                            value={this.props.employer.postNewJob.jobDescription}
                            onChange={this.props.handleChangeEmployerJobDescription}
                        />
                    </Col>
                </Row>

                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Job Category: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <Select
                            placeholder='Please select category of job'
                            style={{ width: '90%' }}
                            onChange={this.props.handleChangeEmployerJobCategory}
                            value={this.props.employer.postNewJob.jobCategory}
                            showSearch
                        >
                            {sectors.map((sector, index) => {
                                return (
                                    <Option key={index} value={sector}>{sector}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>

                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Job type: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <Select
                            placeholder='Please select type of job'
                            style={{ width: '90%' }}
                            onChange={this.props.handleChangeEmployerJobType}
                            value={this.props.employer.postNewJob.jobType}
                            showSearch
                        >
                            {jobTypes.map((type, index) => {
                                return (
                                    <Option key={index} value={type}>{type}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>

                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Job Location: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <Select
                            placeholder='Please select the location of the job'
                            style={{ width: '90%' }}
                            value={this.props.employer.postNewJob.jobLocation}
                            onChange={this.props.handleChangeEmployerJobLocation}
                            showSearch
                        >
                            {districts.map((district, index) => {
                                return (
                                    <Option key={index} value={district}>{district}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>

                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Salary: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <Select
                            placeholder='Please select the location of the job'
                            style={{ width: '90%' }}
                            value={this.props.employer.postNewJob.jobSalary}
                            onChange={this.props.handleChangeEmployerJobSalary}
                            showSearch
                        >
                            {employerJobSalaryRange.map((range, index) => {
                                return (
                                    <Option key={index} value={range}>{range}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>

                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Career level: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <Select
                            placeholder='Please select the career level of the job'
                            style={{ width: '90%' }}
                            value={this.props.employer.postNewJob.jobCareerLevel}
                            onChange={this.props.handleChangeEmployerJobCareerLevel}
                            showSearch
                        >
                            {careerLevels.map((level, index) => {
                                return (
                                    <Option key={index} value={level}>{level}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>

                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Qualifications required: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <Select
                            placeholder='Please select qualifications required for the job'
                            style={{ width: '90%' }}
                            onChange={this.props.handleChangeEmployerJobQualificationsRequired}
                            value={this.props.employer.postNewJob.jobQualificationsRequired}
                            showSearch
                        >
                            {educationLevel.map((level, index) => {
                                return (
                                    <Option key={index} value={level}>{level}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>


                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Qualifications/Experience: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <TextArea
                            placeholder='Please type in the required qualifications and experiences needed'
                            style={{ width: '100%' }}
                            rows={5}
                            value={this.props.employer.postNewJob.qualificationsAndExperience}
                            onChange={this.props.handleChangeEmployerQualificationsAndExperience}
                        />
                    </Col>
                </Row>

                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Roles/Responsibilites: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <TextArea
                            placeholder='Please type in roles & responsibilities'
                            style={{ width: '100%' }}
                            rows={10}
                            value={this.props.employer.postNewJob.rolesAndResponsibilities}
                            onChange={this.props.handleChangeEmployerRolesAndResponsibilities}
                        />
                    </Col>
                </Row>

                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Other requirements: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <TextArea
                            placeholder='Please type in any other requirements'
                            style={{ width: '100%' }}
                            rows={5}
                            value={this.props.employer.postNewJob.otherRequirements}
                            onChange={this.props.handleChangeEmployerOtherRequirements}
                        />
                        {/* <MuiBulletedTextArea
                            className='bulletpointtextarea'
                            style={{ width: '100%' }}
                            bulletChar={bulletWithSpace}
                        /> */}
                    </Col>
                </Row>

                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Remarks: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <TextArea
                            placeholder='Please enter additional remarks'
                            rows={3}
                            // style={{ width: '90%' }}
                            value={this.props.employer.postNewJob.remarks}
                            onChange={this.props.handleChangeEmployerRemarks}
                        />
                    </Col>
                </Row>

                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Visibility: </label>
                    </Col>
                    <Col lg={18} md={24} sm={24} xs={24}>
                        <Select
                            placeholder='Please select the career level of the job'
                            style={{ width: '90%' }}
                            value={this.props.employer.postNewJob.visibility}
                            onChange={this.props.handleChangeEmployerJobVisibility}
                            showSearch
                        >
                            <Option key={'t'} value={true}>Show company name</Option>
                            <Option key={'f'} value={false}>Hidden company name</Option>
                        </Select>
                    </Col>
                </Row>


                <Row className='create-job-section-information'>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Date added: </label>
                    </Col>
                    <Col lg={4} md={24} sm={24} xs={24}>
                        <DatePicker
                            format='DD MMM YYYY'
                            // picker='month'
                            disabledDate={this.disabledStartDate}
                            onChange={this.props.handleChangeEmployerJobStartDate}
                            value={this.props.employer.postNewJob.jobAddedDate}
                        />
                    </Col>

                    <Col lg={6} md={24} sm={24} xs={24}>
                        <label>Closing date: </label>
                    </Col>

                    <Col lg={4} md={24} sm={24} xs={24}>
                        <DatePicker
                            format='DD MMM YYYY'
                            // picker='month'
                            disabledDate={this.disabledEndDate}
                            onChange={this.props.handleChangeEmployerJobEndDate}
                            value={this.props.employer.postNewJob.jobEndDate}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} md={24} sm={24} xs={24}></Col>
                    <Col lg={6} md={24} sm={24} xs={24} style={{ textAlign: 'right' }}>
                        <Button
                            style={{ width: '100%', padding: 5, height: 'auto', fontSize: 22 }}
                            type='default'
                        // onClick={this.props.handleSubmitPostNewJob}
                        >
                            Save as draft
                        </Button>
                    </Col>
                    <Col lg={4} md={24} sm={24} xs={24}></Col>
                    <Col lg={6} md={24} sm={24} xs={24} style={{ textAlign: 'right' }}>
                        <Button
                            style={{ width: '100%', padding: 5, height: 'auto', fontSize: 22 }}
                            type='primary'
                            onClick={this.props.handleSubmitPostNewJob}
                        >
                            Create Job
                        </Button>
                    </Col>

                </Row>
            </div>
        );
    }
}

export default CreateJob;