import React, { Component } from 'react';
import { Col, Divider, Row } from 'antd';
import PersonalInformation from '../profile/Employer/PersonalInformation';
import ProfileInformation from '../profile/Employer/ProfileInformation';

class EmployerProfile extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '10px' }}>
                        <PersonalInformation
                            {...this.props}
                        />
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <ProfileInformation
                            {...this.props}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default EmployerProfile;