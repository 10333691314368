import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd';
import React, { Component } from 'react';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { scrollUp } from '../../constants';

class ResetUsrname extends Component {
    async componentDidMount() {
        scrollUp()
        this.props.onTopbarKeyChange('')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Settings',
                path: '/settings/'
            },
            {
                item: 'Reset Info',
                path: '/settings/reset/'
            },
            {
                item: 'Username',
                path: '/settings/reset/username/'
            },

        ])
    }

    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='Reset username' />
                <Row>
                    <Col lg={7} md={0} sm={0} xs={0} style={{ textAlign: 'center' }} />
                    <Col lg={10} md={24} sm={24} xs={24} style={{ textAlign: 'center' }}>
                        <Divider />
                        <Form
                            name="basic"
                            labelCol={{
                                span: 8,
                            }}
                            labelAlign={'left'}
                            wrapperCol={{
                                span: 16,
                            }}

                        >
                            <Form.Item
                                label="Current Username"
                                name="currentUsername"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="New username"
                                name="newPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your new username!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password to continue!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Button htmlType='submit' type='primary'>
                                Reset username
                            </Button>
                        </Form>
                    </Col>
                    <Col lg={7} md={0} sm={0} xs={0} style={{ textAlign: 'center' }} />
                </Row>


            </div>
        );
    }
}

export default ResetUsrname;