import react, { Component } from 'react';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import CandidateProfile from '../../components/Candidate/CandidateProfile';
import EmployerProfile from '../../components/Employer/EmployerProfile';
import HeaderTitle from '../../components/HeaderTitle';
import { scrollUp } from '../../constants';

class Profile extends Component {

    async componentDidMount() {
        scrollUp()
        this.props.onTopbarKeyChange('')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Profile',
                path: '/profile/'
            },
        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='My Profile' />
                {this.props.entityType === 'Employer' ?
                    <EmployerProfile
                        {...this.props}
                    />
                    : this.props.entityType === 'Candidate' ?
                        <CandidateProfile
                            {...this.props}
                        />
                        :
                        null
                }
            </div>
        )
    }
}

export default Profile