import React, { Component } from 'react';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import EmployerAccount from '../../components/Employer/EmployerAccount';
import CandidateAccount from '../../components/Candidate/CandidateAccount';
import { scrollUp } from '../../constants';

class Account extends Component {
    async componentDidMount() {
        this.props.onTopbarKeyChange('')
        scrollUp()
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Settings',
                path: '/settings/'
            },
            {
                item: 'My Account',
                path: '/settings/account/'
            },
        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                {this.props.entityType === 'Employer' ?
                    <EmployerAccount {...this.props} />
                    :
                    <CandidateAccount {...this.props} />
                }
            </div>
        );
    }
}

export default Account;