import { Button, Card, Col, List, Row, Typography } from 'antd';
import React, { Component } from 'react';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { scrollUp, services_recruitment_list } from '../../constants';
import { BsCheck } from 'react-icons/bs';

const { Title, Text } = Typography

class Services extends Component {
    async componentDidMount() {
        scrollUp()
        await this.props.onTopbarKeyChange('services')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Services',
                path: '/services/'
            }
        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='Our Services' />
                <Row gutter={[10, 10]}>
                    <Col xs={24} sm={24} md={24} lg={8}>
                        <Card title='Recruitment' hoverable>
                            <List
                                dataSource={services_recruitment_list}
                                renderItem={(item) => (
                                    <List.Item>
                                        <BsCheck /> {item}
                                    </List.Item>
                                )}
                            />

                            <Button href='#/services/recruitment/'>See More</Button>

                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} hoverable>
                        <Card title='HR Consulting'>
                            <List
                                dataSource={services_recruitment_list}
                                renderItem={(item) => (
                                    <List.Item>
                                        <BsCheck /> {item}
                                    </List.Item>
                                )}
                            />

                            <Button href='#/services/hr-consulting/'>See More</Button>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Services;