import React, { Component } from 'react';
import { Button, Col, Divider, Row, Popconfirm } from 'antd';
import { AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import Create_Education from '../../modals/Create_Education';
import Edit_Education from '../../modals/Edit_Education';
import moment from 'moment';
import { MdModeEdit } from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri';

class Education extends Component {
    render() {
        return (
            <div>
                <h1 className='profile-section-title'>
                    Education
                    <Button
                        type='primary'
                        style={{ marginLeft: 10 }}
                        size='small'
                        onClick={this.props.triggerModalAddNewEducation}
                    >
                        Add Education
                    </Button>
                </h1>

                <Create_Education {...this.props} />
                <Edit_Education {...this.props} />

                <Divider className='profile-section-title-divider' />
                {this.props.candidateCV.educationExperience.length === 0 ?
                    <div style={{ minHeight: 100, textAlign: 'center' }}>
                        <p style={{ color: 'gray', fontSize: 25 }}>You have not entered any education experience</p>
                    </div>
                    :
                    this.props.candidateCV.educationExperience.map((experience, index) => {
                        return (
                            <Row className='cv-work-experience'>
                                <Col lg={19} md={24} sm={24} xs={24}>
                                    <b>{experience.educationSchoolName} ({experience.educationSchoolStartDate.format('MMM YYYY')} - {moment.isMoment(experience.educationSchoolEndDate) ? experience.educationSchoolEndDate.format('MMM YYYY') : experience.educationSchoolEndDate}) </b>
                                    <br />
                                    <p>

                                        {experience.educationSchoolLevel}, {experience.educationSchoolFieldOfStudy}
                                    </p>
                                    <br />
                                    <br />
                                </Col>
                                <Col lg={5} md={24} sm={24} xs={24} style={{ display: 'flex', alignItems: 'end', justifyContent: 'start', flexDirection: 'column' }}>
                                    <Button
                                        // size='small'
                                        size='small'
                                        className='cv-work-experience-action-button'
                                        onClick={() => this.props.triggerModalEditNewEducation(index)}
                                        style={{ display: 'none' }}

                                    >
                                        Edit <MdModeEdit style={{ marginLeft: 10 }} />
                                    </Button>
                                    <Popconfirm
                                        placement='bottomRight'
                                        title='Are you sure you want to delete this education?'
                                        onConfirm={() => this.props.handleDeleteNewEducation(index)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            size='small'
                                            className='cv-work-experience-action-button'
                                            style={{ display: 'none', background: 'red', color: 'white' }}
                                        >
                                            Remove <RiDeleteBin2Line style={{ marginLeft: 10 }} />
                                        </Button>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        )
                    })}
            </div>
        );
    }
}

export default Education;