import { Breadcrumb } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BreadcrumbNav extends Component {
    render() {
        return (
            <div>
                <Breadcrumb>
                    {this.props.breadcrumbItems.map((item, index) => {
                        return (
                            <Breadcrumb.Item>
                                <Link to={item.path}>
                                    {item.item}
                                </Link>
                            </Breadcrumb.Item>
                        )
                    })}
                </Breadcrumb>
            </div>
        );
    }
}

export default BreadcrumbNav;