import { Button, Card, Col, Row, Typography } from 'antd';
import React, { Component } from 'react';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import { FcCheckmark } from 'react-icons/fc';
import HeaderTitle from '../../components/HeaderTitle';
import { pricings_packages_gold, pricings_packages_platinum, pricings_packages_sme, scrollUp, themeColor } from '../../constants';

const { Title, Text } = Typography;

class Pricing extends Component {
    async componentDidMount() {
        scrollUp()
        await this.props.onTopbarKeyChange('pricing')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Pricings',
                path: '/pricing/'
            }
        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='Pricing & Packages' />
                <Title level={4} style={{ fontWeight: 'bold' }}>SMEs</Title>
                <Row gutter={[10, 10]} style={{ padding: 10 }}>
                    {pricings_packages_sme.map((mypackage, index) =>
                        <Col xs={24} sm={24} md={24} lg={6} >
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: 'white', borderRadius: 10 }}>
                                <div style={{ background: themeColor, width: '100%', textAlign: 'center', padding: 10, borderRadius: '10px 10px 0 0' }} >
                                    <Title level={5} style={{ color: 'white' }}>{mypackage.name}</Title>
                                </div>
                                <Title level={3} style={{ margin: 5 }}>{mypackage.price}</Title>
                                <div style={{ padding: 15 }}>
                                    {mypackage.description.map((desc, index) =>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                            <FcCheckmark size={22} style={{ marginRight: 5 }} />
                                            <Text>
                                                {desc}
                                            </Text>
                                        </div>
                                    )}

                                </div>
                                <Button type='primary' style={{ width: '50%', margin: '10px', borderRadius: 10 }}>Select</Button>
                            </div>
                        </Col>
                    )}
                </Row>

                <Title level={4} style={{ fontWeight: 'bold' }}>Platinum</Title>
                <Row gutter={[10, 10]} style={{ padding: 10 }}>
                    {pricings_packages_platinum.map((mypackage, index) =>
                        <Col xs={24} sm={24} md={24} lg={6} >
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: 'white', borderRadius: 10 }}>
                                <div style={{ background: themeColor, width: '100%', textAlign: 'center', padding: 10, borderRadius: '10px 10px 0 0' }} >
                                    <Title level={5} style={{ color: 'white' }}>{mypackage.name}</Title>
                                </div>
                                <Title level={3} style={{ margin: 5 }}>{mypackage.price}</Title>
                                <div style={{ padding: 15 }}>
                                    {mypackage.description.map((desc, index) =>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                            <FcCheckmark size={22} style={{ marginRight: 5 }} />
                                            <Text>
                                                {desc}
                                            </Text>
                                        </div>
                                    )}

                                </div>
                                <Button type='primary' style={{ width: '50%', margin: '10px', borderRadius: 10 }}>Select</Button>
                            </div>
                        </Col>

                    )}
                </Row>

            </div>
        );
    }
}

export default Pricing;