import { Button, Col, DatePicker, Input, Row, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from 'antd/lib/modal/Modal';
import React, { Component } from 'react';
import { educationLevel } from '../../constants';
import moment from 'moment';

const { Option } = Select

class Create_Education extends Component {
    disabledEndDate = (current) => {
        // Can not select days before today and today
        return (current && current > moment().endOf('month')) || (current < moment(this.props.candidateCV.educationSchoolStartDate).endOf('month'));
    }

    disabledStartDate = (current) => {
        // Can not select days before today and today
        return current && current > moment().endOf('month');
    }
    render() {
        return (
            <div>
                <Modal
                    title="Add new education experience"
                    visible={this.props.modalAddNewEducation}
                    destroyOnClose={true}
                    footer={[
                        <Checkbox
                            defaultChecked={this.props.isCreateAnotherEducation}
                            onChange={this.props.handleChangeIsCreateAnotherEducation}
                        >
                            Create another
                        </Checkbox>,
                        <Button
                            type='default'
                            onClick={this.props.triggerModalAddNewEducation}
                        >
                            Cancel
                        </Button>,
                        <Button
                            type='primary'
                            onClick={this.props.handleAddNewEducation}
                            disabled={
                                this.props.candidateCV.educationSchoolName !== '' &&
                                    this.props.candidateCV.educationSchoolLevel !== null &&
                                    this.props.candidateCV.educationSchoolStartDate !== '' &&
                                    this.props.candidateCV.educationSchoolEndDate !== '' ?
                                    false
                                    :
                                    true
                            }
                        >
                            Add Education
                        </Button>
                    ]}
                    onCancel={this.props.triggerModalAddNewEducation}
                >
                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>School Name: </label>
                        </Col>
                        <Col lg={18} md={24} sm={24} xs={24}>
                            <Input
                                autoFocus
                                placeholder='Please enter the name of the school'
                                // style={{ width: '90%' }}
                                value={this.props.candidateCV.educationSchoolName}
                                onChange={this.props.handleChangeEducationSchoolName}
                            />
                        </Col>
                    </Row>

                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>Education Level: </label>
                        </Col>
                        <Col lg={18} md={24} sm={24} xs={24}>
                            <Select
                                placeholder='Please select your education level'
                                style={{ width: '90%' }}
                                onChange={this.props.handleChangeEducationSchoolLevel}
                                value={this.props.candidateCV.educationSchoolLevel}
                                showSearch
                            >
                                {educationLevel.map((level, index) => {
                                    return (
                                        <Option key={index} value={level}>{level}</Option>
                                    )
                                })}
                            </Select>
                        </Col>
                    </Row>

                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>Field of study: </label>
                        </Col>
                        <Col lg={18} md={24} sm={24} xs={24}>
                            <Input
                                placeholder='Please enter your field of study'
                                // style={{ width: '90%' }}
                                value={this.props.candidateCV.educationSchoolFieldOfStudy}
                                onChange={this.props.handleChangeEducationSchoolFieldOfStudy}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={0} sm={0} xs={0}></Col>
                        <Col lg={18} md={24} sm={24} xs={24}>
                            <Checkbox
                                defaultChecked={this.props.candidateCV.isCurrentEducation}
                                onChange={this.props.handleChangeIsCurrentEducation}
                            >
                                I am still at this school
                            </Checkbox>
                        </Col>
                    </Row>

                    <br />

                    <Row className='profile-section-row-information'>
                        <Col lg={4} md={24} sm={24} xs={24}>
                            <label>Start date: </label>
                        </Col>
                        <Col lg={8} md={24} sm={24} xs={24}>
                            <DatePicker
                                format='MMM YYYY'
                                picker='month'
                                disabledDate={this.disabledStartDate}
                                onChange={this.props.handleChangeEducationSchoolStartDate}
                                value={this.props.candidateCV.educationSchoolStartDate}
                            />
                        </Col>

                        <Col lg={4} md={24} sm={24} xs={24}>
                            <label>End date: </label>
                        </Col>
                        {this.props.candidateCV.isCurrentEducation ?
                            <p>Present</p>
                            :
                            <Col lg={8} md={24} sm={24} xs={24}>
                                <DatePicker
                                    format='MMM YYYY'
                                    picker='month'
                                    disabledDate={this.disabledEndDate}
                                    onChange={this.props.handleChangeEducationSchoolEndDate}
                                    value={this.props.candidateCV.educationSchoolEndDate}
                                />
                            </Col>
                        }
                    </Row>
                </Modal>

            </div>
        );
    }
}

export default Create_Education;