import React, { Component } from 'react';
import { Button, Col, Divider, Input, Popconfirm, Popover, Row, Spin, Tag, Typography } from 'antd';
import { TiTick } from 'react-icons/ti';
import { AiFillCloseCircle } from 'react-icons/ai';
import { skillsLimit } from '../../../constants';

const { Paragraph } = Typography;

class Skills extends Component {
    render() {
        return (
            <div className='create-cv-skills'>
                <h1 className='profile-section-title'>
                    Skills & Competencies
                    <Popover
                        content={
                            this.props.candidateCV.skills.length >= skillsLimit ?
                                <Row>
                                    <Col lg={20} md={20} sm={20} xs={20}>
                                        <p>
                                            Maximum number of skills reached!
                                        </p>
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col lg={20} md={12} sm={18} xs={18}>
                                        <Input
                                            className='new-skill-input'
                                            value={this.props.candidateCV.tempSkill}
                                            onChange={this.props.handleChangeTempSkill}
                                            placeholder='Enter skill'
                                            disabled={this.props.candidateCV.skills.length >= skillsLimit ? true : false}
                                            maxLength={35}
                                            onPressEnter={this.props.handleAddNewSkill}
                                        />
                                    </Col>
                                    <Col lg={4} md={2} sm={2} xs={2}>
                                        <Button
                                            disabled={this.props.candidateCV.tempSkill === '' ? true : false}
                                            icon={<TiTick style={this.props.candidateCV.tempSkill === '' ? true : { color: 'green' }} />}
                                            onClick={this.props.handleAddNewSkill}
                                        />
                                    </Col>
                                </Row>
                        }
                        title="Add skill"
                        trigger="click"
                    >
                        <Button
                            type='primary'
                            style={{ marginLeft: 10 }}
                            size='small'
                        >
                            Add Skill
                        </Button>
                    </Popover>
                </h1>
                <Divider className='profile-section-title-divider' />
                <Row>
                    {this.props.loadingSkill ?
                        <Spin />
                        :
                        this.props.candidateCV.skills.length === 0 ?
                            <Col lg={24} md={24} sm={24} xs={24} style={{ minHeight: 100, textAlign: 'center' }}>
                                <p style={{ color: 'gray', fontSize: 25 }}>You have not entered any skills</p>
                            </Col>
                            :
                            this.props.candidateCV.skills.map((skill, index) => {
                                return (
                                    <Col lg={8} md={12} sm={24} xs={24} style={{ padding: '0 20px 20px 0' }}>
                                        <Tag
                                            className='skill-tag'
                                            closable
                                            closeIcon={
                                                <Popconfirm
                                                    title='Are you sure you want to delete this skill?'
                                                    onConfirm={() => this.props.handleDeleteNewSkill(index)}
                                                    okText="Delete"
                                                    cancelText="No"
                                                    className='skill-delete-confirm'
                                                >
                                                    <AiFillCloseCircle size={18} color='red' style={{ marginLeft: 10, paddingTop: 3, position: 'absolute', right: 10, top: 10, display: 'none' }} />
                                                </Popconfirm>
                                            }>
                                            <Paragraph
                                                editable={{ onChange: (e) => this.props.handleUpdateNewSkill(index, e) }}
                                                style={{ margin: 0, display: 'flex', alignItems: 'center' }}
                                            >
                                                {skill}
                                            </Paragraph>
                                        </Tag>
                                    </Col>
                                )
                            })}
                </Row>
            </div>
        );
    }
}

export default Skills;