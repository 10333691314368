import React, { Component } from 'react';
import { Col, Divider, Input, Row } from 'antd';

const { TextArea } = Input

class ProfileInformation extends Component {
    render() {
        return (
            <div>
                <h1 className='profile-section-title'>Profile</h1>
                <Divider className='profile-section-title-divider' />
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24} style={{ textAlign: 'justify' }}>
                        <TextArea
                            rows={4}
                            placeholder="Tell us a little bit about yourself"
                            maxLength={1200}
                            showCount
                            onChange={this.props.handleChangeProfileDescription}
                        />
                    </Col>
                </Row>

            </div>
        );
    }
}

export default ProfileInformation;