import { Button, Col, DatePicker, Divider, Input, Row, Select, Popconfirm } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from 'antd/lib/modal/Modal';
import React, { Component } from 'react';
import { sectors } from '../../constants';
import moment from 'moment';
import { MdModeEdit } from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri'

const { TextArea } = Input
const { Option } = Select

class Create_Work extends Component {
    disabledEndDate = (current) => {
        // Can not select days before today and today
        return (current && current > moment().endOf('month')) || (current < moment(this.props.candidateCV.jobStartDate).endOf('month'));
    }

    disabledStartDate = (current) => {
        // Can not select days before today and today
        return current && current > moment().endOf('month');
    }

    render() {
        return (
            <div>
                <Modal
                    title="Add new work experience"
                    visible={this.props.modalAddNewJob}
                    destroyOnClose={true}
                    footer={[
                        <Checkbox
                            defaultChecked={this.props.isCreateAnotherJob}
                            onChange={this.props.handleChangeIsCreateAnotherJob}
                        >
                            Create another
                        </Checkbox>,
                        <Button
                            type='default'
                            onClick={this.props.triggerModalAddNewJob}
                        >
                            Cancel
                        </Button>,
                        <Button
                            type='primary'
                            onClick={this.props.handleAddNewJob}
                            disabled={
                                this.props.candidateCV.jobCompany !== '' &&
                                    this.props.candidateCV.jobTitle !== '' &&
                                    this.props.candidateCV.jobSector !== null &&
                                    this.props.candidateCV.jobStartDate !== null &&
                                    this.props.candidateCV.jobEndDate ?
                                    false
                                    :
                                    true
                            }
                        >
                            Add Job
                        </Button>
                    ]}
                    onCancel={this.props.triggerModalAddNewJob}
                >
                    <Row className='profile-section-row-information'>
                        <Col lg={4} md={24} sm={24} xs={24}>
                            <label>Company: </label>
                        </Col>
                        <Col lg={20} md={24} sm={24} xs={24}>
                            <Input
                                autoFocus
                                placeholder='Please enter the name of the company'
                                // style={{ width: '90%' }}
                                value={this.props.candidateCV.jobCompany}
                                onChange={this.props.handleChangeJobCompany}
                            />
                        </Col>
                    </Row>
                    <Row className='profile-section-row-information'>
                        <Col lg={4} md={24} sm={24} xs={24}>
                            <label>Job Title: </label>
                        </Col>
                        <Col lg={20} md={24} sm={24} xs={24}>
                            <Input
                                placeholder='Please enter the name of the company'
                                // style={{ width: '90%' }}
                                value={this.props.candidateCV.jobTitle}
                                onChange={this.props.handleChangeJobTitle}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={0} sm={0} xs={0}></Col>
                        <Col lg={20} md={24} sm={24} xs={24}>
                            <Checkbox
                                defaultChecked={this.props.candidateCV.isCurrentWork}
                                onChange={this.props.handleChangeIsCurrentWork}
                            >
                                I am still working here
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row className='profile-section-row-information'>
                        <Col lg={4} md={24} sm={24} xs={24}>
                            <label>Sector: </label>
                        </Col>
                        <Col lg={20} md={24} sm={24} xs={24}>
                            <Select
                                placeholder='Please select your job preference'
                                style={{ width: '90%' }}
                                onChange={this.props.handleChangeJobSector}
                                value={this.props.candidateCV.jobSector}
                                showSearch
                            >
                                {sectors.map((sector, index) => {
                                    return (
                                        <Option key={index} value={sector}>{sector}</Option>
                                    )
                                })}
                            </Select>
                        </Col>
                    </Row>
                    <Row className='profile-section-row-information'>
                        <Col lg={4} md={24} sm={24} xs={24}>
                            <label>Start date: </label>
                        </Col>
                        <Col lg={8} md={24} sm={24} xs={24}>
                            <DatePicker
                                format='MMM YYYY'
                                picker='month'
                                disabledDate={this.disabledStartDate}
                                onChange={this.props.handleChangeJobStartDate}
                                value={this.props.candidateCV.jobStartDate}
                            />
                        </Col>

                        <Col lg={4} md={24} sm={24} xs={24}>
                            <label>End date: </label>
                        </Col>
                        {this.props.candidateCV.isCurrentWork ?
                            <p>Present</p>
                            :
                            <Col lg={8} md={24} sm={24} xs={24}>
                                <DatePicker
                                    format='MMM YYYY'
                                    picker='month'
                                    disabledDate={this.disabledEndDate}
                                    onChange={this.props.handleChangeJobEndDate}
                                    value={this.props.candidateCV.jobEndDate}
                                />
                            </Col>
                        }
                    </Row>
                    <Row className='profile-section-row-information'>
                        <Col lg={4} md={24} sm={24} xs={24}>
                            <label>Job description: </label>
                        </Col>
                        <Col lg={20} md={24} sm={24} xs={24}>
                            <TextArea
                                rows={3}
                                placeholder="Please describe your role at the company"
                                maxLength={1200}
                                onChange={this.props.handleChangeJobDescription}
                                value={this.props.candidateCV.jobDescription}
                            // style={{ width: '90%' }}
                            />
                        </Col>
                    </Row>

                </Modal>

            </div>
        );
    }
}

export default Create_Work;