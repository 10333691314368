import { Button, Card, Checkbox, Col, Divider, Form, Input, List, Row, Typography } from 'antd';
import React, { Component } from 'react';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { scrollUp, services_recruitment_list, themeColor } from '../../constants';
import { BsCheck } from 'react-icons/bs';
import ReactQuill from "react-quill"

const { Title, Text } = Typography

class Service2 extends Component {
    async componentDidMount() {
        scrollUp()
        await this.props.onTopbarKeyChange('services')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Services',
                path: '#'
            },
            {
                item: 'HR Consulting',
                path: '/services/hr-consulting/'
            }
        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='HR Consulting' />
                <Card style={{ padding: '0 65px 0 65px' }}>
                    <Title level={4} style={{ color: themeColor }}>
                        Managing your employees can be very challenging and frustrating, you can outsource your HR
                        Department while you focus on your core business.Our highly experience consultants are equipped with knowledge, expertise and best practices in Human
                        Resource Management. We can audit your organisation and recommend HR Consultancy services
                        namely in:
                    </Title>

                    <Text>
                    </Text>

                    <br />
                    <br />

                    <List
                        size='small'
                        bordered={false}
                        dataSource={[
                            'Contract of Employment',
                            'Employment laws advisory',
                            'Employment Relations, Disciplinary Issues and Committees',
                            'Separation and Termination of Employment',
                            'Affordable and efficient Payroll system',
                            'Attendance and Leave Management',
                            'Performance Appraisal System',
                            'Employee engagement survey',
                            'Policies and Procedures',
                            'Training and Development'
                        ]}
                        renderItem={(item) => (
                            <List.Item style={{ borderBottom: 0 }}>
                                <BsCheck /> {item}
                            </List.Item>
                        )}
                    />

                    <Divider />

                    <Title
                        style={{ textAlign: 'center', color: themeColor }}
                        level={3}
                    >
                        Request a Quote
                    </Title>

                    {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}


                    <Form
                        name="hr-consulting-quotation"
                        layout='horizontal'
                        wrapperCol={{
                            lg: 8,
                            md: 12,
                            sm: 24,
                            xs: 24
                        }}
                        labelCol={{
                            lg: 5,
                            md: 8,
                            sm: 24,
                            xs: 24
                        }}
                        labelAlign='left'

                    // onFinish={}
                    >
                        <Form.Item label='Company Name' name={'companyname'}>
                            <Input placeholder='Enter company name' />
                        </Form.Item>

                        <Form.Item label='Contact Name' name={'contactname'}>
                            <Input placeholder='Enter contact name' />
                        </Form.Item>

                        <Form.Item label='Email address' name={'emailaddr'}>
                            <Input placeholder='Enter email address' />
                        </Form.Item>

                        <Form.Item label='Phone No.' name={'phoneno'}>
                            <Input placeholder='Enter Phone No.' />
                        </Form.Item>


                        <Form.Item label='Select HR Consultancy Services: ' name={'ConsultancyServicesInquiring'}>
                            <Checkbox>
                                Contract of Employment
                            </Checkbox>
                            <br />
                            <Checkbox>
                                Employment laws advisory
                            </Checkbox>
                            <br />
                            <Checkbox>
                                Employment Relations, Disciplinary Issues and Committees
                            </Checkbox>
                            <br />
                            <Checkbox>
                                Separation and Termination of Employment
                            </Checkbox>
                            <br />
                            <Checkbox>
                                Affordable and efficient Payroll system
                            </Checkbox>
                            <br />
                            <Checkbox>
                                Attendance and Leave Management
                            </Checkbox>
                            <br />
                            <Checkbox>
                                Performance Appraisal System
                            </Checkbox>
                            <br />
                            <Checkbox>
                                Employee engagement survey
                            </Checkbox>
                            <br />
                            <Checkbox>
                                Policies and Procedures
                            </Checkbox>
                            <br />
                            <Checkbox>
                                Training and Development
                            </Checkbox>
                        </Form.Item>

                        <Form.Item label='Describe your request' name={'description'}>
                            <Input.TextArea rows={4} />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                            >
                                Submit Request
                            </Button>
                        </Form.Item>


                    </Form>
                    {/* </div> */}

                </Card>
            </div>
        );
    }
}

export default Service2;