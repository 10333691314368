import React, { Component } from 'react';
import { Button, Col, Divider, Input, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';

class Edit_Referrals extends Component {
    render() {
        return (
            <div>
                <Modal
                    title="Edit notice period"
                    visible={this.props.modalEditReferrals}
                    destroyOnClose={true}
                    footer={[
                        <Button
                            type='primary'
                            onClick={this.props.triggerModalEditReferrals}
                        >
                            Done
                        </Button>
                    ]}
                    width={800}
                    onCancel={this.props.triggerModalEditReferrals}
                >
                    <Row className='profile-section-row-information'>
                        <Col lg={5} md={24} sm={24} xs={24}>
                            <label style={{ fontWeight: 'bold' }}>Full name: </label>
                        </Col>
                        <Col lg={14} md={24} sm={24} xs={24}>
                            <Input
                                placeholder='Enter full name of contact person'
                                style={{ width: '90%' }}
                                value={this.props.candidateCV.referalFullName}
                                onChange={this.props.handleReferalFullName}
                            />
                        </Col>
                    </Row>
                    <Row className='profile-section-row-information'>
                        <Col lg={5} md={24} sm={24} xs={24}>
                            <label style={{ fontWeight: 'bold' }}>Title: </label>
                        </Col>
                        <Col lg={14} md={24} sm={24} xs={24}>
                            <Input
                                placeholder='Enter job title'
                                style={{ width: '90%' }}
                                value={this.props.candidateCV.referalJobTitle}
                                onChange={this.props.handleReferalJobTitle}
                            />
                        </Col>
                    </Row>
                    <Row className='profile-section-row-information'>
                        <Col lg={5} md={24} sm={24} xs={24}>
                            <label style={{ fontWeight: 'bold' }}>Company name: </label>
                        </Col>
                        <Col lg={14} md={24} sm={24} xs={24}>
                            <Input
                                placeholder='Enter company name'
                                style={{ width: '90%' }}
                                value={this.props.candidateCV.referalCompany}
                                onChange={this.props.handleReferalCompanyName}
                            />
                        </Col>
                    </Row>
                    <Row className='profile-section-row-information'>
                        <Col lg={5} md={24} sm={24} xs={24}>
                            <label style={{ fontWeight: 'bold' }}>Contact No.: </label>
                        </Col>
                        <Col lg={14} md={24} sm={24} xs={24}>
                            <Input
                                placeholder='Enter mobile no.'
                                style={{ width: '90%' }}
                                value={this.props.candidateCV.referalMobileNum}
                                onChange={this.props.handleReferalMobileNum}
                            />
                        </Col>
                    </Row>

                </Modal>

            </div>
        );
    }
}

export default Edit_Referrals;