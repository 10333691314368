import react, { Component } from 'react';
import { Form, Input, Button, Checkbox, Col, Row, Card, Select, DatePicker, Upload } from 'antd';
import { districts, legal_status, scrollUp, sectors } from '../../constants';
import { HiUpload } from 'react-icons/hi'
import BreadcrumbNav from '../../components/BreadcrumbNav';
import { Link } from 'react-router-dom';
import HeaderTitle from '../../components/HeaderTitle';
const { TextArea } = Input;
const { Option } = Select;

class EmployerRegister extends Component {
    async componentDidMount() {
        scrollUp()
        await this.props.onTopbarKeyChange('')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Employer Registration',
                path: '/register/employer/'
            }
        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='Employer Registration' />
                <Row>
                    <Col lg={4} md={0} sm={0} xs={0}></Col>
                    <Col lg={16} md={24} sm={24} xs={24}>
                        <Card
                            style={{ width: '100%' }}
                        >

                            <Form
                                name="basic"
                                layout='horizontal'
                                wrapperCol={{
                                    lg: 8,
                                    md: 12,
                                    sm: 24,
                                    xs: 24
                                }}
                                labelCol={{
                                    lg: 9,
                                    md: 8,
                                    sm: 24,
                                    xs: 24
                                }}
                                labelAlign='left'
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={this.props.onRegisterFinish}
                                onFinishFailed={this.props.onRegisterFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Company Name</span>}
                                    name="organisationName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your organisations name!',
                                        },
                                    ]}
                                // style={{margin: 0}}
                                >
                                    <Input placeholder='Organisation name' />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Employer Legal Status</span>}
                                    name="organisationLegalStatus"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your legal status!',
                                        },
                                    ]}
                                // style={{margin: 0}}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a legal status"
                                        optionFilterProp="children"
                                        // onChange={onChange}
                                        // onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {legal_status.map((status) => {
                                            return (<Option value={status}>{status}</Option>)
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Address</span>}
                                    name="organisationAddress"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your organisations location!',
                                        },
                                    ]}
                                >
                                    <TextArea rows={2} placeholder='Address of organisation' />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>District</span>}
                                    name="organisationDistrict"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please choose organisations district!',
                                        },
                                    ]}
                                // style={{margin: 0}}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a district"
                                        optionFilterProp="children"
                                        // onChange={onChange}
                                        // onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {districts.map((district) => {
                                            return (<Option value={district}>{district}</Option>)
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Economic Sector</span>}
                                    name="organisationEconomicSector"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please choose a sector!',
                                        },
                                    ]}
                                // style={{margin: 0}}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select an economic sector"
                                        optionFilterProp="children"
                                        // onChange={onChange}
                                        // onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {sectors.map((sect) => {
                                            return (<Option value={sect}>{sect}</Option>)
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Phone no.</span>}
                                    name="organisationPhone"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input organisations contact!',
                                        },
                                    ]}
                                // style={{margin: 0}}
                                >
                                    <Input placeholder='Phone no.' />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Fax no.</span>}
                                    name="organisationFax"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input organisations fax number!',
                                        },
                                    ]}
                                // style={{margin: 0}}
                                >
                                    <Input placeholder='Fax no.' />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Name of user</span>}
                                    name="organisationUser"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your users name!',
                                        },
                                    ]}
                                // style={{margin: 0}}
                                >
                                    <Input placeholder='Name of user' />
                                </Form.Item>

                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Email Address of user</span>}
                                    name="emailaddress"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email address!',
                                        },
                                    ]}
                                // style={{margin: 0}}
                                >
                                    <Input placeholder='Email address' />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Password</span>}
                                    name="organisationPwd"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder='Password' />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Confirm Password</span>}
                                    name="organisationPwdConfirm"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password again!',
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder='Confirm password' />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Designation</span>}
                                    name="organisationUsersDesignation"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your users designation!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Designation' />
                                </Form.Item>

                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Description</span>}
                                    name="organisationDescription"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your organisations description!',
                                        },
                                    ]}
                                >
                                    <TextArea rows={2} placeholder='Description of organisation' />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Website</span>}
                                    name="organisationWebsite"
                                // style={{margin: 0}}
                                >
                                    <Input placeholder='Website' />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Country of issue</span>}
                                    name="organisationCountry"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your organisations country!',
                                        },
                                    ]}
                                // style={{margin: 0}}
                                >
                                    <Input placeholder='Country' />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Business Registration Number</span>}
                                    name="organisationBRN"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your organisations BRN!',
                                        },
                                    ]}
                                // style={{margin: 0}}
                                >
                                    <Input placeholder='BRN' />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>Business Registration Date</span>}
                                    name="organisationBRNDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your organisations BRN Date!',
                                        },
                                    ]}
                                // style={{margin: 0}}
                                >
                                    <DatePicker />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{ padding: 0, margin: 0 }}>BRN Attachments</span>}
                                    name="organisationBRNUploads"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please input your organisations BRN License!',
                                        },
                                    ]}
                                >
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture"
                                        maxCount={3}
                                        multiple
                                    >
                                        <Button icon={<HiUpload />}>Upload (Max: 3)</Button>
                                    </Upload>
                                </Form.Item>


                                <Row>
                                    <Col lg={10} md={0} sm={0} xs={0}></Col>
                                    <Col lg={4} md={24} sm={24} xs={24}>
                                        <Button
                                            style={{ background: '#2D3691', color: 'white', width: '100%' }}
                                            htmlType="submit"
                                        >
                                            Register
                                        </Button>
                                    </Col>
                                    <Col lg={10} md={0} sm={0} xs={0}></Col>
                                </Row>

                            </Form>


                        </Card>
                    </Col>
                    <Col lg={4} md={0} sm={0} xs={0}></Col>
                </Row>
            </div>
        )
    }
}


export default EmployerRegister