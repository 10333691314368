import { Button, Card, Col, Row } from 'antd';
import react, { Component } from 'react';
import Analytics from '../../components/Analytics';
import CandidateLogin from '../../components/Candidate/CandidateLogin';
import EmployerLogin from '../../components/Employer/EmployerLogin';
import Search from '../../components/Search';
import SearchResults from '../../components/SearchResults';
import { scrollUp } from '../../constants';

class Homepage extends Component {
    componentDidMount() {
        this.props.onTopbarKeyChange('home')
        scrollUp()
    }
    render() {
        return (
            <div>
                <Row>

                    <Col lg={6} md={24} sm={24} xs={24}>
                        <EmployerLogin
                            {...this.props}
                        />
                    </Col>

                    <Col lg={12} md={24} sm={24} xs={24} style={{ textAlign: 'center', padding: '10px' }}>
                        <Card>
                            <Row>
                                <Col lg={24} md={24} sm={24} xs={24}>
                                    <Analytics />
                                </Col>
                                <Col lg={24} md={24} sm={24} xs={24}>
                                    <Search {...this.props} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <CandidateLogin
                            {...this.props}
                        />
                    </Col>
                </Row>
                <Row style={{ textAlign: 'center', margin: 8 }}>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <SearchResults {...this.props} />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Homepage