import React, { Component } from 'react';
import { Button, Col, Divider, Row } from 'antd';
import { AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import { MdModeEdit } from 'react-icons/md';
import Edit_NoticePeriod from '../../modals/Edit_NoticePeriod';

class NoticePeriod extends Component {
    render() {
        return (
            <div>
                <Edit_NoticePeriod {...this.props} />
                <Row>
                    <Col lg={18} md={24} sm={10} xs={10}>
                        <h1 className='profile-section-title'>Notice Period</h1>
                    </Col>
                    <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        <Button
                            onClick={this.props.triggerModalEditNoticePeriod}
                            style={{ fontSize: 18, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            Edit <MdModeEdit style={{ marginLeft: 10 }} />
                        </Button>

                    </div>

                    <Divider className='profile-section-title-divider' />
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <h4>{this.props.candidateCV.noticePeriod}</h4>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default NoticePeriod;