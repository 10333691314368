import React, { Component } from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

class HeaderTitle extends Component {
    render() {
        return (
            <div>
                <Title level={2} style={{ textAlign: 'center'}}>
                    {this.props.title}
                </Title>
            </div>
        );
    }
}

export default HeaderTitle;