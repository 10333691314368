import React, { Component } from 'react';
import { Button, Col, Divider, Row } from 'antd';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import Edit_Referrals from '../../modals/Edit_Referrals';

class Referral extends Component {
    render() {
        return (
            <div>
                <Edit_Referrals {...this.props} />
                <Row>
                    <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        <Button
                            onClick={this.props.triggerModalEditReferrals}
                            style={{ fontSize: 18, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            Edit <MdEdit style={{ marginLeft: 10 }} />
                        </Button>

                    </div>

                    <Col lg={18} md={24} sm={10} xs={10}>
                        <h1 className='profile-section-title'>Notice Period</h1>
                    </Col>
                    <Divider className='profile-section-title-divider' />
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <h3>{this.props.candidateCV.referalFullName}</h3>
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <h4>{this.props.candidateCV.referalJobTitle}</h4>
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <h4>{this.props.candidateCV.referalCompany}</h4>
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <h4>{this.props.candidateCV.referalMobileNum}</h4>
                    </Col>
                </Row>

            </div>

        );
    }
}

export default Referral;