import { Avatar, Button, Card, Col, Divider, Input, Radio, Row, Select, Space, Typography } from 'antd';
import React, { Component } from 'react';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import { ATS_Status, Candidate_Rating, Candidate_Status, scrollUp } from '../../constants';
import { ImAttachment } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { MdOutlineEmail } from 'react-icons/md';
import HeaderTitle from '../../components/HeaderTitle';
const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;


class ATS_Active extends Component {
    async componentDidMount() {
        scrollUp()
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Dashboard',
                path: '/dashboard/'
            },
            {
                item: 'ATS',
                path: '/ats/'
            },
            {
                item: 'Active Jobs',
                path: '.'
            },
        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <br />

                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={4}>
                        <Title level={4}>
                            Stage:
                        </Title>
                        <div style={{ height: '500px', overflowY: 'scroll' }}>
                            {ATS_Status.map((status, index) => {
                                return (
                                    <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                        {status} (5)
                                    </Card>
                                )
                            })}
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={5}>
                        <Title level={4}>
                            Candidates:
                        </Title>

                        <div style={{ minWidth: '150px', height: '500px', overflowY: 'scroll' }}>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                            <Card size='small' hoverable style={{ marginTop: 4, border: '1px solid lightgray', borderRadius: 5 }}>
                                ALex smith
                            </Card>
                        </div>
                    </Col>
                    {/* <Col xs={0} sm={0} md={0} lg={1}> */}
                    {/* <Divider orientation='vertical' style={{ height: '100vh' }} /> */}
                    {/* </Col> */}
                    <Col xs={24} sm={24} md={24} lg={15}>
                        <Row>
                            <Col xs={0} sm={0} md={0} lg={8}></Col>
                            <Col xs={24} sm={24} md={24} lg={8}>
                                <Title level={4} style={{ textAlign: 'center' }}>
                                    Software Developer (Active)
                                </Title>

                            </Col>
                            <Col xs={24} sm={24} md={24} lg={8} style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                                <Text
                                    style={{ fontWeight: 'normal', fontSize: 14, display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                                    Application date: 19 Jun 2022
                                </Text>
                            </Col>
                        </Row>

                        <Card
                            title={
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <Title level={4}>
                                            John Smith
                                        </Title>
                                        <Text>
                                            29 years old, Male
                                            <br />
                                            Cite Anushka, 16eme mille, forest side
                                        </Text>

                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <Row gutter={[1, 1]}>
                                            <Col xs={24} sm={24} md={24} lg={4}>
                                                <Text style={{ fontSize: 14, fontWeight: 'normal' }}>Rating: </Text>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={20}>
                                                <Select
                                                    placeholder='Rate'
                                                    style={{ width: '100%' }}
                                                >
                                                    {Candidate_Rating.map((rate, index) => {
                                                        return (
                                                            <Option value={rate}>{rate}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={4}>
                                                <Text style={{ fontSize: 14, fontWeight: 'normal' }}>Status: </Text>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={20}>
                                                <Select
                                                    placeholder='Status'
                                                    style={{ width: '100%' }}
                                                >
                                                    {Candidate_Status.map((status, index) => {
                                                        return (
                                                            <Option value={status}>{status}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={4}>
                                                <Text style={{ fontSize: 14, fontWeight: 'normal' }}>Move to: </Text>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={20}>
                                                <Select
                                                    placeholder='Move to'
                                                    style={{ marginRight: 10, width: '100%' }}
                                                >
                                                    {ATS_Status.map((status, index) => {
                                                        return (
                                                            <Option value={status}>{status}</Option>
                                                        )
                                                    })}
                                                </Select>

                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            }
                            extra={
                                <Row>
                                </Row>
                            }
                        >
                            <Row>
                                <Col lg={8} md={12} sm={24} xs={24}>
                                    <Text strong>
                                        Level: 
                                    </Text>
                                    <Text>
                                        Operatives/Manual Worker
                                    </Text>
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={24}>
                                    <Text strong>
                                        Education: 
                                    </Text>
                                    <Text>
                                        Master's Degree/Bac +5
                                    </Text>
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={24}>
                                    <Text strong>
                                        Work experience: 
                                    </Text>
                                    <Text>
                                        10 years
                                    </Text>
                                </Col>
                            </Row>

                            <Divider style={{ marginTop: 15, marginBottom: 15 }} />

                            <Row style={{ marginTop: 5 }}>
                                <Col xs={24} sm={24} md={24} lg={18}>
                                    <Text strong>Experience</Text>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} style={{ textAlign: 'center' }}>
                                    <Text strong>Duration(Months)</Text>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={18}>
                                    <Text>Software Developer, Accenture</Text>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} style={{ textAlign: 'center' }}>
                                    <Text >24</Text>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={18}>
                                    <Text>Graphic Designer, Ceridien</Text>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} style={{ textAlign: 'center' }}>
                                    <Text>18</Text>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={18}>
                                    <Text>Software Engineer, SICOM</Text>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} style={{ textAlign: 'center' }}>
                                    <Text >48</Text>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={18}>
                                    <Text>Software Engineer, SICOM</Text>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} style={{ textAlign: 'center' }}>
                                    <Text >48</Text>
                                </Col>

                            </Row>


                            <br />



                            <Row style={{ textAlign: 'center' }}>
                                <Col xs={24} sm={24} md={24} lg={8}>
                                    <Link to='#' style={{ fontWeight: 'bold' }}>
                                        <ImAttachment /> View CV
                                    </Link>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={8}>
                                    <Link to='#' style={{ fontWeight: 'bold' }}>
                                        <ImAttachment /> View application letter
                                    </Link>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={8}>
                                    <Link to='#' style={{ fontWeight: 'bold' }}>
                                        <MdOutlineEmail /> Send email
                                    </Link>
                                </Col>
                            </Row>


                        </Card>
                        {/* <br /> */}
                        <Title level={5}>Notes</Title>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={20}>
                                <TextArea rows={4} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                                <Button>
                                    Save Note
                                </Button>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </div >
        );
    }
}

export default ATS_Active;