import React, { Component } from 'react';
import { Button, Col, Divider, Row, Spin, Select, Modal, Checkbox, Popconfirm, Tooltip } from 'antd';
import { languagesList, languageFluencyList, languagesLimit } from '../../constants';
import { AiFillCloseCircle, AiOutlineEdit } from 'react-icons/ai';

const { Option } = Select

class Create_Language extends Component {
    render() {
        return (
            <div>
                <Modal
                    title="Add new language"
                    visible={this.props.modalAddNewLanguage}
                    destroyOnClose={true}
                    footer={[
                        <Checkbox
                            defaultChecked={this.props.isCreateAnotherLanguage}
                            onChange={this.props.handleChangeIsCreateAnotherLanguage}
                        >
                            Create another
                        </Checkbox>,
                        <Button
                            type='default'
                            onClick={this.props.triggerModalAddNewLanguage}
                        >
                            Cancel
                        </Button>,
                        <Button
                            type='primary'
                            onClick={this.props.handleAddNewLanguage}
                            disabled={
                                this.props.candidateCV.languageName !== null &&
                                    this.props.candidateCV.languageFluency !== null ?
                                    false
                                    :
                                    true
                            }
                        >
                            Add Language
                        </Button>
                    ]}
                    onCancel={this.props.triggerModalAddNewLanguage}
                >
                    {this.props.candidateCV.languages.length >= languagesLimit ?
                        <p>Max limit reached for languages!</p>
                        :
                        <div>

                            <Row className='profile-section-row-information'>
                                <Col lg={6} md={24} sm={24} xs={24}>
                                    <label>Language: </label>
                                </Col>
                                <Col lg={18} md={24} sm={24} xs={24}>
                                    <Select
                                        placeholder='Please select the language'
                                        style={{ width: '90%' }}
                                        onChange={this.props.handleChangeLanguageName}
                                        value={this.props.candidateCV.languageName}
                                        showSearch
                                    >
                                        {languagesList.map((language, index) => {
                                            return (
                                                <Option key={index} value={language}>{language}</Option>
                                            )
                                        })}
                                    </Select>
                                </Col>
                            </Row>
                            <Row className='profile-section-row-information'>
                                <Col lg={6} md={24} sm={24} xs={24}>
                                    <label>Language Fluency: </label>
                                </Col>
                                <Col lg={18} md={24} sm={24} xs={24}>
                                    <Select
                                        placeholder='Please select your fluency'
                                        style={{ width: '90%' }}
                                        onChange={this.props.handleChangeLanguageFluency}
                                        value={this.props.candidateCV.languageFluency}
                                        showSearch
                                    >
                                        {languageFluencyList.map((fluency, index) => {
                                            return (
                                                <Option key={index} value={fluency}>{fluency}</Option>
                                            )
                                        })}
                                    </Select>
                                </Col>
                            </Row>
                        </div>

                    }
                </Modal>

            </div>
        );
    }
}

export default Create_Language;