import { Button, Col, Row, Table } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { activeJobs, scrollUp } from '../../constants';

class Drafts extends Component {
    async componentDidMount() {
        scrollUp()
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Dashboard',
                path: '/dashboard/'
            },
            {
                item: 'Drafts Jobs',
                path: '/dashboard/job/drafts/'
            },
        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='Drafts Jobs' />
                <Row style={{ textAlign: 'center', marginTop: '22px' }}>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Table
                            columns={activeJobs}
                            dataSource={this.props.searchResultsTableData}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        window.location.href = `#/job/create/`;
                                    }, // click row
                                    onDoubleClick: event => { }, // double click row
                                    onContextMenu: event => { }, // right button click row
                                    onMouseEnter: event => { }, // mouse enter row
                                    onMouseLeave: event => { }, // mouse leave row
                                };
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Drafts;