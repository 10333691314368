import { Button, Col, DatePicker, Divider, Input, Row, Select, Popconfirm } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from 'antd/lib/modal/Modal';
import React, { Component } from 'react';
import { sectors } from '../../constants';
import moment from 'moment';
import { MdModeEdit } from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri'
import Create_Work from '../modals/Create_Work';
import Edit_Work from '../modals/Edit_Work';

const { TextArea } = Input
const { Option } = Select



class WorkExperience extends Component {
    render() {
        return (
            <div>
                <h1 className='profile-section-title'>
                    Work Experience
                    <Button
                        type='primary'
                        style={{ marginLeft: 10 }}
                        size='small'
                        onClick={this.props.triggerModalAddNewJob}
                    >
                        Add Work
                    </Button>
                </h1>

                {/* Create Job */}
                <Create_Work {...this.props} />


                {/* Edit Job */}
                <Edit_Work {...this.props} />




                <Divider className='profile-section-title-divider' />

                {this.props.candidateCV.workExperience.length === 0 ?
                    <div style={{ minHeight: 100, textAlign: 'center' }}>
                        <p style={{ color: 'gray', fontSize: 25 }}>You have not entered any work experience</p>
                    </div>
                    :
                    this.props.candidateCV.workExperience.map((experience, index) => {
                        return (
                            <Row className='cv-work-experience'>
                                <Col lg={19} md={24} sm={24} xs={24}>
                                    <b>{experience.jobTitle} ({experience.jobStartDate.format('MMM YYYY')} - {moment.isMoment(experience.jobEndDate) ? experience.jobEndDate.format('MMM YYYY') : experience.jobEndDate}) </b>
                                    <br />
                                    {experience.jobCompany}
                                    <p>Sector: {experience.jobSector}</p>
                                </Col>
                                <Col lg={5} md={24} sm={24} xs={24} style={{ display: 'flex', alignItems: 'end', justifyContent: 'start', flexDirection: 'column' }}>
                                    <Button
                                        // size='small'
                                        size='small'
                                        className='cv-work-experience-action-button'
                                        onClick={() => this.props.triggerModalEditNewJob(index)}
                                        style={{ marginLeft: 10, display: 'flex', alignItems: 'center', marginBottom: 4 }}
                                    >
                                        Edit <MdModeEdit style={{ marginLeft: 10 }} size={18} />
                                    </Button>
                                    <Popconfirm
                                        placement='bottomRight'
                                        title='Are you sure you want to delete this work?'
                                        onConfirm={() => this.props.handleDeleteNewJob(index)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            size='small'
                                            className='cv-work-experience-action-button'
                                            style={{ marginLeft: 10, background: 'red', color: 'white', display: 'flex', alignItems: 'center' }}
                                        >
                                            <Divider style={{ margin: 2 }} type='vertical' />
                                            Remove <RiDeleteBin2Line style={{ marginLeft: 10 }} size={18} />
                                        </Button>
                                    </Popconfirm>
                                </Col>
                                <Col lg={24} md={24} sm={24} xs={24}>
                                    <p>{experience.jobDescription}</p>
                                    <br />

                                </Col>
                            </Row>
                        )
                    })}
            </div>
        );
    }
}

export default WorkExperience;