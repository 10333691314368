import { Button, Col, Row } from 'antd';
import React, { Component } from 'react';
import { IoAddCircleSharp, IoPencilOutline } from 'react-icons/io5'
import { HiUsers } from 'react-icons/hi'
import { MdDrafts, MdEdit } from 'react-icons/md';
import Post_Job from '../../components/modals/Post_Job';
import SearchResults from '../../components/SearchResults';
import { Link } from 'react-router-dom';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { scrollUp } from '../../constants';

class Dashboard extends Component {

    async componentDidMount() {
        scrollUp()
        await this.props.onTopbarKeyChange('')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Dashboard',
                path: '/dashboard/'
            },
        ])
    }


    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='Employer Dashboard' />
                <Row gutter={[16, 16]}>
                    <Col>
                        <Link to="/job/create/" >
                            <Button style={{ height: 150, width: 150 }}>
                                <IoAddCircleSharp size={35} />
                                <br />
                                Post a job
                            </Button>
                        </Link>
                        <Post_Job
                            {...this.props}
                        />
                    </Col>
                    <Col>
                        <Link to="/dashboard/job/drafts/" >
                            <Button style={{ height: 150, width: 150 }}>
                                <MdDrafts size={35} />
                                <br />
                                Drafts
                            </Button>
                        </Link>
                        <Post_Job
                            {...this.props}
                        />
                    </Col>
                    <Col>
                        <Link to="/dashboard/job/active/" >
                            <Button style={{ height: 150, width: 150 }}>
                                <MdEdit size={35} />
                                <br />
                                Edit Active Jobs
                            </Button>
                        </Link>
                        <Post_Job
                            {...this.props}
                        />
                    </Col>
                    <Col>
                        <Link to="/ats/" >
                            <Button
                                style={{ height: 150, width: 150, whiteSpace: 'normal' }}
                            >
                                <HiUsers size={35} />
                                <br />
                                View & Manage Applicants
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Dashboard;