import { Button, Card, Col, List, Row, Typography } from 'antd';
import React, { Component } from 'react';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { after_interview_tips, before_interview_tips, during_interview_tips, scrollUp, services_recruitment_list, themeColor } from '../../constants';
import { BsCheck } from 'react-icons/bs';

const { Title, Text } = Typography

class CareerAdvice extends Component {
    async componentDidMount() {
        await this.props.onTopbarKeyChange('career-advice')
        scrollUp()
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Career Advice',
                path: '/career-advice/'
            }
        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='Career Advices' />
                <Row gutter={[10, 10]}>
                    <Col xs={24} sm={24} md={24} lg={15}>
                        <Card>
                            <Title level={3} style={{color: themeColor}}>Before an interview</Title>
                            <List
                                dataSource={before_interview_tips}
                                bordered={false}
                                renderItem={(item) => (
                                    <List.Item>
                                        <BsCheck /> {item}
                                    </List.Item>
                                )}
                            />

                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8}>
                        <Card
                            cover={<img src='https://images.unsplash.com/photo-1616434116710-c45ce99c1a77?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80' />}
                        >
                            <Title level={4}>If you fail to plan, then you are planning to fail</Title>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8}>
                        <Card
                            // title='Firm Handshake'
                            cover={<img src='https://images.unsplash.com/photo-1518135714426-c18f5ffb6f4d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1196&q=80' />}
                        >
                            <Title level={3}>Firm Handshake</Title>
                            {/* <Text>
                                The researchers found that a "firm handshake" corresponded to personality traits that included extroversion and "openness to new experiences," while those with a weak handshake were more likely to show higher levels of shyness and anxiety on their psychological reports.
                            </Text> */}

                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={15}>
                        <Card>
                            <Title level={3} style={{color: themeColor}}>
                                During an interview
                            </Title>
                            <List
                                dataSource={during_interview_tips}
                                bordered={false}
                                renderItem={(item) => (
                                    <List.Item>
                                        <BsCheck /> {item}
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={15}>
                        <Card>
                            <Title level={3} style={{color: themeColor}}>
                                After an interview
                            </Title>
                            <List
                                dataSource={after_interview_tips}
                                renderItem={(item) => (
                                    <List.Item>
                                        <BsCheck /> {item}
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </Col>
                    {/* <Col xs={24} sm={24} md={24} lg={8}>
                        <Card
                            cover={<img src='https://images.unsplash.com/photo-1573497491208-6b1acb260507?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80' />}
                        >
                            <Title level={3}>
                                Listen carefully to the interviewer
                            </Title>
                            <Text>
                                The researchers found that a "firm handshake" corresponded to personality traits that included extroversion and "openness to new experiences," while those with a weak handshake were more likely to show higher levels of shyness and anxiety on their psychological reports.
                            </Text>

                        </Card>
                    </Col> */}
                </Row>
            </div>
        );
    }
}

export default CareerAdvice;