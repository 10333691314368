import React, { Component } from 'react';
import { Col, Divider, Row } from 'antd';
import Education from '../profile/Candidate/Education';
import PersonalInformation from '../profile/Candidate/PersonalInformation';
import ProfileInformation from '../profile/Candidate/ProfileInformation';
import CareerSummary from '../profile/Candidate/CareerSummary';
import WorkExperience from '../profile/Candidate/WorkExperience';
import Skills from '../profile/Candidate/Skills';
import Languages from '../profile/Candidate/Languages';
import Hobby from '../profile/Candidate/Hobby';
import Referral from '../profile/Candidate/Referral';
import NoticePeriod from '../profile/Candidate/NoticePeriod';

class CandidateProfile extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <PersonalInformation
                            {...this.props}
                        />
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <ProfileInformation
                            {...this.props}
                        />
                    </Col>
                    <Col lg={11} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px', paddingRight: 5 }}>
                        <WorkExperience
                            {...this.props}
                        />
                    </Col>
                    <Col lg={1} md={0} sm={0} xs={0}>
                        {/* <Divider type='vertical' style={{height: '100%'}} /> */}
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px', paddingLeft: 5 }}>
                        <Education
                            {...this.props}
                        />
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <CareerSummary
                            {...this.props}
                        />
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <Skills
                            {...this.props}
                        />
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <Languages {...this.props} />
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <Hobby {...this.props} />
                    </Col>
                    <Col lg={11} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <NoticePeriod {...this.props} />
                    </Col>
                    <Col lg={1} md={0} sm={0} xs={0}>
                        {/* <Divider type='vertical' style={{height: '100%'}} /> */}
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <Referral {...this.props} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CandidateProfile;