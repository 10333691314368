import { Button, Col, Row, Table } from 'antd';
import React, { Component } from 'react';

class SearchResultsExpanded extends Component {
    render() {
        return (
            <div style={{ margin: -15 }}>
                <Table
                    className='searchResultsExpandedTable'
                    pagination={false}
                    style={{ fontWeight: 'normal' }}
                    showHeader={false}
                    columns={this.props.searchResultsTableDetailsColumns}
                    dataSource={this.props.details}
                />
                <Row gutter={[15, 15]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <div style={{ textAlign: 'center', margin: 10 }}>
                            <Button type='primary' style={{ width: '70%' }}>Apply</Button>
                        </div>

                    </Col>
                </Row>
            </div>
        );
    }
}

export default SearchResultsExpanded
