import React, { Component } from 'react';
import { Button, Col, Divider, Row, Spin, Select, Modal, Checkbox, Popconfirm, Tooltip } from 'antd';
import { languagesList, languageFluencyList, languagesLimit } from '../../constants';
import { AiFillCloseCircle, AiOutlineEdit } from 'react-icons/ai';
import Create_Language from '../modals/Create_Language';
import Edit_Language from '../modals/Edit_Language';

const { Option } = Select

class Languages extends Component {
    render() {
        return (
            <div className='language'>
                <h1 className='profile-section-title'>
                    Languages
                    <Button
                        type='primary'
                        style={{ marginLeft: 10 }}
                        size='small'
                        onClick={this.props.triggerModalAddNewLanguage}
                    >
                        Add Language
                    </Button>
                </h1>

                {/* Create Language */}
                <Create_Language {...this.props} />
                {/* Edit Language */}
                <Edit_Language {...this.props} />




                <Divider className='profile-section-title-divider' />
                <Row>
                    {this.props.loadingLanguage ?
                        <Spin />
                        :
                        this.props.candidateCV.languages.length === 0 ?
                            <Col lg={24} md={24} sm={24} xs={24} style={{ minHeight: 100, textAlign: 'center' }}>
                                <p style={{ color: 'gray', fontSize: 25 }}>You have not entered any language</p>
                            </Col>
                            :
                            this.props.candidateCV.languages.map((language, index) => {
                                return (
                                    <Col
                                        className='language-tag'
                                        lg={6} md={12} sm={24} xs={24}
                                        // style={{ padding: '5px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            alignItems: 'center',
                                            width: '100%',
                                            fontSize: 18,
                                            margin: '10px 45px 10px 0',
                                            padding: 10,
                                            borderRadius: 4,
                                            background: 'transparent',
                                            border: 'none',
                                            position: 'relative',
                                            margin: 0

                                        }}
                                    >
                                        <div>

                                            {language.languageName} ({language.languageFluency})
                                        </div>
                                        <Tooltip title='Edit'>

                                            <Button
                                                className='language-edit-btn'
                                                style={{ display: 'none', border: 'none', position: 'absolute', right: 45, top: 10 }}
                                                type='ghost'
                                                onClick={() => this.props.triggerModalEditNewLanguage(index)}
                                                icon={
                                                    <AiOutlineEdit size={20} color='blue' />
                                                }
                                            />
                                        </Tooltip>
                                        <Tooltip title='Delete'>
                                            <Popconfirm
                                                title='Are you sure you want to delete this langugae?'
                                                onConfirm={() => this.props.handleDeleteNewLanguage(index)}
                                                okText="Delete"
                                                cancelText="No"
                                                className='language-delete-confirm'
                                            >
                                                <Button
                                                    style={{ display: 'none', border: 'none', position: 'absolute', right: 10, top: 10 }}
                                                    type='ghost'
                                                    icon={
                                                        <AiFillCloseCircle color='red' />
                                                    }
                                                />
                                            </Popconfirm>
                                        </Tooltip>
                                    </Col>
                                )
                            })}
                </Row>
            </div >
        );
    }
}

export default Languages;