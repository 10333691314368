import { Col, Divider, Row, Select } from 'antd';
import React, { Component } from 'react';
import { careerLevels, educationLevel, experienceYears } from '../../constants';

const { Option } = Select

class CareerSummary extends Component {
    render() {
        return (
            <div>
                <h1 className='profile-section-title'>
                    Career Summary
                </h1>
                <Divider className='profile-section-title-divider' />


                <Row className='profile-section-row-information'>
                    <Col lg={1} md={24} sm={24} xs={24}>
                        <label>Level: </label>
                    </Col>
                    <Col lg={5} md={24} sm={24} xs={24}>
                        <Select
                            placeholder='Current career level'
                            style={{ width: '90%' }}
                            onChange={this.props.handleChangeCareerLevel}
                            value={this.props.candidateCV.careerLevel}
                            showSearch
                        >
                            {careerLevels.map((level, index) => {
                                return (
                                    <Option key={index} value={level}>{level}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                    <Col lg={3} md={24} sm={24} xs={24}>
                        <label>Level of education: </label>
                    </Col>
                    <Col lg={5} md={24} sm={24} xs={24}>
                        <Select
                            placeholder='Level of education'
                            style={{ width: '90%' }}
                            onChange={this.props.handleChangeCareerLevelOfEducation}
                            value={this.props.candidateCV.careerLevelOfEducation}
                            showSearch
                        >
                            {educationLevel.map((level, index) => {
                                return (
                                    <Option key={index} value={level}>{level}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                    <Col lg={3} md={24} sm={24} xs={24}>
                        <label>Total years of experience: </label>
                    </Col>
                    <Col lg={5} md={24} sm={24} xs={24}>
                        <Select
                            placeholder='No. of years of experience'
                            style={{ width: '90%' }}
                            onChange={this.props.handleChangeCareerTotalYearsExperience}
                            value={this.props.candidateCV.careerTotalYearsExperience}
                            showSearch
                        >
                            {experienceYears.map((experience, index) => {
                                return (
                                    <Option key={index} value={experience}>{experience}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default CareerSummary;