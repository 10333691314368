import { Col, Divider, Input, Row } from 'antd';
import React, { Component } from 'react';
import Edit_Referrals from '../modals/Edit_Referrals';

class Referral extends Component {
    render() {
        return (
            <div>
                <Edit_Referrals {...this.props} />
                <h1 className='profile-section-title'>
                    Referral
                </h1>
                <Divider className='profile-section-title-divider' />


                <Row className='profile-section-row-information'>
                    <Col lg={5} md={24} sm={24} xs={24}>
                        <label style={{ fontWeight: 'bold' }}>Full name: </label>
                    </Col>
                    <Col lg={14} md={24} sm={24} xs={24}>
                        <Input
                            placeholder='Enter full name of contact person'
                            style={{ width: '90%' }}
                            onChange={this.props.handleReferalFullName}
                        />
                    </Col>
                </Row>
                <Row className='profile-section-row-information'>
                    <Col lg={5} md={24} sm={24} xs={24}>
                        <label style={{ fontWeight: 'bold' }}>Title: </label>
                    </Col>
                    <Col lg={14} md={24} sm={24} xs={24}>
                        <Input
                            placeholder='Enter job title'
                            style={{ width: '90%' }}
                            onChange={this.props.handleReferalJobTitle}
                        />
                    </Col>
                </Row>
                <Row className='profile-section-row-information'>
                    <Col lg={5} md={24} sm={24} xs={24}>
                        <label style={{ fontWeight: 'bold' }}>Company name: </label>
                    </Col>
                    <Col lg={14} md={24} sm={24} xs={24}>
                        <Input
                            placeholder='Enter company name'
                            style={{ width: '90%' }}
                            onChange={this.props.handleReferalCompanyName}
                        />
                    </Col>
                </Row>
                <Row className='profile-section-row-information'>
                    <Col lg={5} md={24} sm={24} xs={24}>
                        <label style={{ fontWeight: 'bold' }}>Contact No.: </label>
                    </Col>
                    <Col lg={14} md={24} sm={24} xs={24}>
                        <Input
                            placeholder='Enter mobile no.'
                            style={{ width: '90%' }}
                            onChange={this.props.handleReferalMobileNum}
                        />
                    </Col>
                </Row>

            </div>
        );
    }
}

export default Referral;