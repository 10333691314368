import { Button, Card, Col, Divider, Form, Input, List, Row, Typography } from 'antd';
import React, { Component } from 'react';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { scrollUp, services_recruitment_list } from '../../constants';
import { GoLocation } from 'react-icons/go';
import { MdOutlineMailOutline } from 'react-icons/md';
import { GiPhone, GiRotaryPhone } from 'react-icons/gi';
import { GrFacebook } from 'react-icons/gr';
import { BsInstagram, BsLinkedin } from 'react-icons/bs';

const { Title, Text } = Typography

class Contact extends Component {
    async componentDidMount() {
        await this.props.onTopbarKeyChange('contact-us')
        scrollUp()
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Contact us',
                path: '/contact-us/'
            }
        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='Contact us' />
                <Row gutter={[10, 10]}>
                    <Col xs={24} sm={24} md={24} lg={8}>
                        <Card>
                            <Text style={{ fontSize: 18 }}>
                                <GoLocation />  Coastal Road Palmar, Belle Mare 41604
                            </Text>
                            <Divider style={{margin: 15}} />
                            <Text style={{ fontSize: 18 }}>
                                <MdOutlineMailOutline />  contact@boulot.mu
                            </Text>
                            <Divider style={{margin: 15}} />
                            <Text style={{fontSize: 18}}>
                                <GiPhone /> +230 59097777
                            </Text>
                            <Divider style={{margin: 15}} />
                            <Text style={{fontSize: 18}}>
                                <GiRotaryPhone />  685 9988
                            </Text>

                            <Divider />

                            <Row>
                                <Col span={3}>
                                    <GrFacebook size={22} color='#4267B2' />
                                </Col>
                                <Col span={3}>
                                    <BsInstagram size={22} color='#8a3ab9' />
                                </Col>
                                <Col span={3}>
                                    <BsLinkedin size={22} color='#0072b1' />
                                </Col>
                            </Row>

                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={14}>
                        <Card title={<div style={{ textAlign: 'center' }}><Title level={4}>Get in touch</Title></div>}>
                            <Form
                                // labelCol={{ span: 8 }}
                                labelWrap={true}
                                labelAlign='left'
                                wrapperCol={{ span: 14 }}
                                layout="horizontal"
                            // initialValues={{ disabled: componentDisabled }}
                            // onValuesChange={onFormLayoutChange}
                            // disabled={componentDisabled}
                            >
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={10}>
                                        <Form.Item label="Full Name: ">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={0} sm={0} md={0} lg={2}>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={10}>
                                        <Form.Item label="Email: ">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <Form.Item label="Message: ">
                                            <Input.TextArea rows={4} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: 'center' }}>
                                        <Button htmlType='submit' size='large' type='primary' style={{ borderRadius: 20 }}>Send Message</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8}></Col>
                </Row>
            </div>
        );
    }
}

export default Contact;