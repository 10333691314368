import react, { Component } from 'react';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import CandidateProfile from '../../components/Candidate/CandidateProfile';
import EmployerProfile from '../../components/Employer/EmployerProfile';
import HeaderTitle from '../../components/HeaderTitle';
import { AiOutlineEdit } from 'react-icons/ai';
import { Button, Col, DatePicker, Select, Divider, Input, Row, Upload, InputNumber, Modal, Typography } from 'antd';
import { districts, genders, nationalities, salaryRange, sectors } from '../../constants';
import moment from 'moment';
import { MdModeEdit } from 'react-icons/md';
import { scrollUp } from '../../constants';
import testpp from '../../images/MCB.jpg';
import { GoLocation } from 'react-icons/go';
import { AiOutlinePhone } from 'react-icons/ai';
import { MdAlternateEmail } from 'react-icons/md';
import { BsLinkedin } from 'react-icons/bs';
import { FcExternal } from 'react-icons/fc';
import SearchResults from '../../components/SearchResults';
import { TbWorld } from 'react-icons/tb';

class PublicCompany extends Component {

    async componentDidMount() {
        scrollUp()
        this.props.onTopbarKeyChange('')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            }
        ])
    }
    render() {
        return (
            <div>
                <HeaderTitle title='Business & Decision' />
                <Row gutter={[10, 10]}>
                    <Divider className='profile-section-title-divider' />

                    <Col lg={4} md={24} sm={24} xs={24}>
                        <img src={testpp} height={180} />
                    </Col>
                    <Col lg={20} md={24} sm={24} xs={24}>
                        <Row>
                            <Col lg={6} md={12} sm={24} xs={24}>
                                <Row>
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        <Row className='profile-section-row-information'>
                                            <Col lg={3} md={3} sm={3} xs={3}>
                                                <label><GoLocation /> </label>
                                            </Col>
                                            <Col lg={21} md={12} sm={12} xs={12}>
                                                Inova Riche Terre Business Park,<br />RICHE TERRE, Mauritius
                                            </Col>
                                        </Row>

                                        <Row className='profile-section-row-information'>
                                            <Col lg={3} md={3} sm={3} xs={3}>
                                                <label><AiOutlinePhone /></label>
                                            </Col>
                                            <Col lg={14} md={12} sm={12} xs={12}>
                                                665 0909
                                            </Col>
                                        </Row>

                                        <Row className='profile-section-row-information'>
                                            <Col lg={3} md={3} sm={3} xs={3}>
                                                <label><TbWorld /></label>
                                            </Col>
                                            <Col lg={14} md={12} sm={12} xs={12}>
                                                https://companywebsite.com
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={18} md={12} sm={24} xs={24}>
                                <p>
                                    aojsdo aodab douabdu basodubasodb asdbas database
                                    aojsdo aodab douabdu basodubasodb asdbas database
                                    aojsdo aodab douabdu basodubasodb asdbas database
                                    aojsdo aodab douabdu basodubasodb asdbas database
                                    aojsdo aodab douabdu basodubasodb asdbas database
                                    aojsdo aodab douabdu basodubasodb asdbas database

                                </p>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <br />
                <Row style={{ textAlign: 'center' }}>
                    <Typography.Title level={3}>
                        Vacancies at Business & Decision
                    </Typography.Title>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <SearchResults {...this.props} />
                    </Col>
                </Row>

            </div>
        )
    }
}

export default PublicCompany