import { Button, Col, Row, Table } from 'antd';
import React, { Component } from 'react';
import { IoAddCircleSharp } from 'react-icons/io5'
import { HiUsers } from 'react-icons/hi'
import Post_Job from '../../components/modals/Post_Job';
import SearchResults from '../../components/SearchResults';
import { Link } from 'react-router-dom';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { activeJobs, scrollUp } from '../../constants';

class ATS extends Component {
    async componentDidMount() {
        scrollUp()
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Dashboard',
                path: '/dashboard/'
            },
            {
                item: 'ATS',
                path: '/ats/'
            },
        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='Applicant Tracking System' />
                <Row gutter={[16, 16]}>
                    <Col>
                        <Button
                            style={{ height: 150, width: 150, whiteSpace: 'normal' }}
                        >
                            <HiUsers size={35} />
                            <br />
                            Closed
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            style={{ height: 150, width: 150, whiteSpace: 'normal' }}
                        >
                            <HiUsers size={35} />
                            <br />
                            Pending
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            style={{ height: 150, width: 150, whiteSpace: 'normal' }}
                        >
                            <HiUsers size={35} />
                            <br />
                            Unclosed
                        </Button>
                    </Col>
                    <Col>
                        {/* <Link to="/ats/active/" > */}
                        <Button style={{ height: 150, width: 150 }}>
                            <IoAddCircleSharp size={35} />
                            <br />
                            Active
                        </Button>
                        {/* </Link> */}
                        <Post_Job
                            {...this.props}
                        />
                    </Col>
                </Row>
                <Row style={{ textAlign: 'center', marginTop: '22px' }}>
                    <h2>All active jobs</h2>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Table
                            columns={activeJobs}
                            dataSource={this.props.searchResultsTableData}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        window.location.href = `#/ats/active/${record.jobtitle}/`;
                                    }, // click row
                                    onDoubleClick: event => { }, // double click row
                                    onContextMenu: event => { }, // right button click row
                                    onMouseEnter: event => { }, // mouse enter row
                                    onMouseLeave: event => { }, // mouse leave row
                                };
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ATS;