import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import react, { Component } from 'react';
import { BiSearch } from 'react-icons/bi'
import { sectors } from '../constants';
const { Option } = Select;


class Search extends Component {
    render() {
        return (


            <div>
                <Row gutter={[8, 8]} style={{ marginTop: 45 }}>
                    <Col lg={7} md={24} sm={24} xs={24} style={{ textAlign: 'left' }}>
                        <Input autoFocus  placeholder="Keyword" />
                    </Col>
                    <Col lg={13} md={24} sm={24} xs={24} style={{ textAlign: 'left', minWidth: 100 }}>
                        <Select
                            showSearch
                            // size='large'
                            placeholder="Select a sector"
                            optionFilterProp="children"
                            allowClear
                            // onChange={onChange}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ minWidth: '350px' }}
                        >
                            {
                                sectors.map((sect) => {
                                    return <Option key={sect.toString()} value={sect}>{sect}</Option>
                                })
                            }
                        </Select>
                    </Col>
                    <Col lg={4} md={24} sm={24} xs={24} style={{ padding: 5 }}>
                        <Button
                            style={{ background: '#2D3691', color: 'white', borderRadius: 2, width: '100%' }}
                            // size='large'
                            className='searchBtn'
                            type='primary'
                            // icon={<BiSearch size={18} />}
                        >
                            Search
                        </Button>
                    </Col>
                </Row>

            </div>

        )
    }
}

export default Search;