import React, { Component } from 'react';
import moment from 'moment';
import { Modal, Button, Checkbox, Row, Col, Input, Select, DatePicker, } from 'antd';
import { educationLevel, jobTypes } from '../../constants';

const { Option } = Select;
const { TextArea } = Input;

class Post_Job extends Component {
    disabledEndDate = (current) => {
        // Can not select days before today and today
        return (current && current < moment().endOf('day')) || (current < moment(this.props.employer.postNewJob.jobAddedDate).endOf('day'));
    }

    disabledStartDate = (current) => {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }
    render() {
        return (
            <div>
                <Modal
                    title="Post new job"
                    visible={this.props.modalPostNewJob}
                    destroyOnClose={true}
                    width={790}
                    footer={[
                        <Checkbox
                            defaultChecked={this.props.isPostAnotherJob}
                            onChange={this.props.handleChangeIsPostAnotherJob}
                        >
                            Create another
                        </Checkbox>,
                        <Button
                            type='default'
                            onClick={this.props.triggerModalPostNewJob}
                        >
                            Cancel
                        </Button>,
                        <Button
                            type='primary'
                            onClick={this.props.handleSubmitPostNewJob}
                        // disabled={
                        //     this.props.candidateCV.educationSchoolName !== '' &&
                        //         this.props.candidateCV.educationSchoolLevel !== null &&
                        //         this.props.candidateCV.educationSchoolStartDate !== '' &&
                        //         this.props.candidateCV.educationSchoolEndDate !== '' ?
                        //         false
                        //         :
                        //         true
                        // }
                        >
                            Post Job
                        </Button>
                    ]}
                    onCancel={this.props.triggerModalPostNewJob}
                >
                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>Job Title: </label>
                        </Col>
                        <Col lg={18} md={24} sm={24} xs={24}>
                            <Input
                                autoFocus
                                placeholder='Please enter the job title'
                                // style={{ width: '90%' }}
                                value={this.props.employer.postNewJob.jobTitle}
                                onChange={this.props.handleChangeEmployerJobTitle}
                            />
                        </Col>
                    </Row>

                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>Job Description: </label>
                        </Col>
                        <Col lg={18} md={24} sm={24} xs={24}>
                            <TextArea
                                placeholder='Please enter the job description'
                                // style={{ width: '90%' }}
                                value={this.props.employer.postNewJob.jobDescription}
                                onChange={this.props.handleChangeEmployerJobDescription}
                            />
                        </Col>
                    </Row>

                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>Job Company: </label>
                        </Col>
                        <Col lg={18} md={24} sm={24} xs={24}>
                            <Input
                                placeholder='Please enter the name of the company'
                                // style={{ width: '90%' }}
                                value={this.props.employer.postNewJob.jobCompany}
                                onChange={this.props.handleChangeEmployerJobCompany}
                            />
                        </Col>
                    </Row>

                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>Company Address: </label>
                        </Col>
                        <Col lg={18} md={24} sm={24} xs={24}>
                            <Input
                                placeholder='Please enter the name of the company'
                                // style={{ width: '90%' }}
                                value={this.props.employer.postNewJob.jobCompanyAddress}
                                onChange={this.props.handleChangeEmployerJobCompanyAddress}
                            />
                        </Col>
                    </Row>

                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>Salary: </label>
                        </Col>
                        <Col lg={18} md={24} sm={24} xs={24}>
                            <Input
                                placeholder='Please enter the salary'
                                // style={{ width: '90%' }}
                                value={this.props.employer.postNewJob.jobSalary}
                                onChange={this.props.handleChangeEmployerJobSalary}
                            />
                        </Col>
                    </Row>


                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>Job type: </label>
                        </Col>
                        <Col lg={18} md={24} sm={24} xs={24}>
                            <Select
                                placeholder='Please select type of job'
                                style={{ width: '90%' }}
                                onChange={this.props.handleChangeEmployerJobType}
                                value={this.props.employer.postNewJob.jobType}
                                showSearch
                            >
                                {jobTypes.map((type, index) => {
                                    return (
                                        <Option key={index} value={type}>{type}</Option>
                                    )
                                })}
                            </Select>
                        </Col>
                    </Row>

                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>Contact person: </label>
                        </Col>
                        <Col lg={18} md={24} sm={24} xs={24}>
                            <Input
                                placeholder='Please enter the name of a contact person'
                                // style={{ width: '90%' }}
                                value={this.props.employer.postNewJob.jobContactPerson}
                                onChange={this.props.handleChangeEmployerJobContactPerson}
                            />
                        </Col>
                    </Row>

                    <Row className='profile-section-row-information'>
                        <Col lg={4} md={24} sm={24} xs={24}>
                            <label>Date added: </label>
                        </Col>
                        <Col lg={8} md={24} sm={24} xs={24}>
                            <DatePicker
                                format='DD MMM YYYY'
                                // picker='month'
                                disabledDate={this.disabledStartDate}
                                onChange={this.props.handleChangeEmployerJobStartDate}
                                value={this.props.employer.postNewJob.jobAddedDate}
                            />
                        </Col>

                        <Col lg={4} md={24} sm={24} xs={24}>
                            <label>Closing date: </label>
                        </Col>
                        {this.props.candidateCV.isCurrentEducation ?
                            <p>Present</p>
                            :
                            <Col lg={8} md={24} sm={24} xs={24}>
                                <DatePicker
                                    format='DD MMM YYYY'
                                    // picker='month'
                                    disabledDate={this.disabledEndDate}
                                    onChange={this.props.handleChangeEmployerJobEndDate}
                                    value={this.props.employer.postNewJob.jobEndDate}
                                />
                            </Col>
                        }
                    </Row>

                    <Row className='profile-section-row-information'>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <label>Qualifications required: </label>
                        </Col>
                        <Col lg={18} md={24} sm={24} xs={24}>
                            <Select
                                placeholder='Please select type of job'
                                style={{ width: '90%' }}
                                onChange={this.props.handleChangeEmployerJobQualificationsRequired}
                                value={this.props.employer.postNewJob.jobQualificationsRequired}
                                showSearch
                            >
                                {educationLevel.map((level, index) => {
                                    return (
                                        <Option key={index} value={level}>{level}</Option>
                                    )
                                })}
                            </Select>
                        </Col>
                    </Row>


                </Modal>

            </div>
        );
    }
}

export default Post_Job;