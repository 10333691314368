import React, { Component } from 'react';
import testpp from '../../../images/MCB.jpg';
import { AiOutlineEdit } from 'react-icons/ai';
import { Button, Col, DatePicker, Select, Divider, Input, Row, Upload, InputNumber, Modal, Typography } from 'antd';
import { districts, genders, nationalities, salaryRange, sectors } from '../../../constants';
import moment from 'moment';
import { MdModeEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';

const { Option } = Select

class PersonalInformation extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col lg={18} md={24} sm={10} xs={10}>
                        <h1 className='profile-section-title'>
                            Personal Information
                        </h1>
                    </Col>

                    <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        <Link to={'/settings/account/'}>
                            <Button
                                style={{ fontSize: 18, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                Edit <MdModeEdit style={{ marginLeft: 10 }} />
                            </Button>
                        </Link>

                    </div>

                    <Divider className='profile-section-title-divider' />
                    <Col lg={24} md={24} sm={24} xs={24}>
                    </Col>
                    <Col lg={6} md={12} sm={24} xs={24}>
                        <img src={testpp} height={200} />
                    </Col>
                    <Col lg={18} md={12} sm={24} xs={24}>
                        <Typography.Title level={3}>
                            Mauritius Commercial Bank
                        </Typography.Title>
                        <Row>
                            <Col lg={12} md={24} sm={24} xs={24}>

                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Address: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        Inova Riche Terre Business Park, RICHE TERRE, Mauritius
                                    </Col>
                                </Row>

                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Phone No.: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        665 0909
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Email Address: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        testa@ajdasjdcom.com
                                    </Col>
                                </Row>

                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>LinkedIn: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        https://linkedin.com/company/test/
                                    </Col>
                                </Row>

                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Website: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        https://companywebsite.com
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>

                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Name of user: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        John Abraham
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Email address: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        JohnAbraham@sicom.mu
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default PersonalInformation