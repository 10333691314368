import { Col, Divider, Row, Select } from 'antd';
import React, { Component } from 'react';
import { noticePeriods } from '../../constants';

const { Option } = Select

class NoticePeriod extends Component {
    render() {
        return (
            <div>
                <h1 className='profile-section-title'>
                    Notice Period
                </h1>
                <Divider className='profile-section-title-divider' />


                <Row className='profile-section-row-information'>
                    <Col lg={15} md={24} sm={24} xs={24}>
                        <Select
                            placeholder='Select notice period for your current job'
                            style={{ width: '90%' }}
                            onChange={this.props.handleChangeNoticePeriod}
                            value={this.props.candidateCV.noticePeriod}
                            showSearch
                        >
                            {noticePeriods.map((period, index) => {
                                return (
                                    <Option key={index} value={period}>{period}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default NoticePeriod;