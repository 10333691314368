import React, { Component } from 'react';
import { Button, Col, DatePicker, Select, Divider, Input, Row, Upload, InputNumber } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { IoCloudUploadOutline } from 'react-icons/io5';
import defaultpp from '../../images/defaultpp.png'
import { districts, genders, nationalities, salaryRange, sectors } from '../../constants';

const { Option } = Select;


class PersonalInformation extends Component {
    render() {
        return (
            <div>
                <h1 className='profile-section-title'>
                    Personal Information
                </h1>
                <Divider className='profile-section-title-divider' />
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                    </Col>
                    <Col lg={6} md={12} sm={24} xs={24}>
                        {this.props.imageUrl ?
                            <img src={this.props.imageUrl} height={300} style={{ maxWidth: 200, marginLeft: '2px' }} />
                            :
                            <img src={defaultpp} height={300} style={{ maxWidth: 200, marginLeft: '2px' }} />
                        }

                        <Upload
                            name="avatar"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={() => this.props.handleBeforeUploadProfilePic}
                            onChange={(info) => this.props.handleUpdateProfilePic(info)}
                        >
                            <div style={{ marginTop: 5 }}>
                                <Button icon={<IoCloudUploadOutline />} loading={this.props.isLoadingProfilePicture}>Click to Upload</Button>
                            </div>

                        </Upload>
                    </Col>
                    <Col lg={18} md={12} sm={24} xs={24}>
                        <h1>
                            <Input
                                placeholder='Enter your full name'
                                style={{ fontSize: 28 }}
                                onChange={this.props.handleChangeFullName}
                            />
                        </h1>
                        <Row>
                            <Col lg={12} md={24} sm={24} xs={24}>

                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>Date of Birth: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <DatePicker
                                            // onChange={onChange}
                                            placeholder='Select Date of Birth'
                                            style={{ width: '90%' }}
                                            format='DD MMM YYYY'
                                            onChange={this.props.handleChangeDOB}
                                        />
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>Age: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <Input
                                            value={this.props.candidateCV.age}
                                            disabled
                                            placeholder='Age'
                                            style={{ width: '90%' }}
                                        />
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>Gender: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <Select
                                            placeholder='Please select'
                                            style={{ width: '90%' }}
                                            onChange={this.props.handleChangeGender}
                                        >
                                            {genders.map((gender, index) =>
                                                <Option value={gender}>{gender}</Option>
                                            )}
                                        </Select>

                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>ID: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <Input
                                            placeholder='Please enter your NIC'
                                            style={{ width: '90%' }}
                                            onChange={this.props.handleChangeID}
                                        />
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>Home Address: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <Input
                                            placeholder='Please enter your full address'
                                            style={{ width: '90%' }}
                                            onChange={this.props.handleChangeHomeAddress}
                                        />
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>District: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <Select
                                            placeholder='Please select your district'
                                            style={{ width: '90%' }}
                                            onChange={this.props.handleChangeDistrict}
                                            showSearch
                                        >
                                            {districts.map((district, index) => {
                                                return (
                                                    <Option key={index} value={district}>{district}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>Home Phone No.: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <InputNumber
                                            placeholder='Please enter your home phone number'
                                            style={{ width: '90%' }}
                                            type='number'
                                            controls={false}
                                            onChange={this.props.handleChangeHomePhoneNum}
                                        />
                                    </Col>
                                </Row>

                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>Mobile No.: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <InputNumber
                                            placeholder='Please enter your mobile number'
                                            style={{ width: '90%' }}
                                            type='number'
                                            controls={false}
                                            onChange={this.props.handleChangeMobileNum}
                                        />
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>Email Address: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <Input
                                            placeholder='Email Address'
                                            style={{ width: '90%' }}
                                            onChange={this.props.handleChangeEmailAddress}
                                        />
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>Driving License: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <Select
                                            placeholder='Do you have a driving license?'
                                            style={{ width: '90%' }}
                                            onChange={this.props.handleChangeDrivingLicense}
                                        >
                                            <Option key='y' value="y">Yes</Option>
                                            <Option key='n' value="n">No</Option>
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>Nationality: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <Select
                                            placeholder='Please select your nationality'
                                            style={{ width: '90%' }}
                                            onChange={this.props.handleChangeNationality}
                                            showArrow
                                            showSearch
                                        >
                                            {nationalities.map((nationality, index) => {
                                                return (
                                                    <Option key={index} value={nationality}>{nationality}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>LinkedIn: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <Input
                                            placeholder='Please enter your LinkedIn profile'
                                            style={{ width: '90%' }}
                                            onChange={this.props.handleChangeLinkedIn}
                                        // prefix='https://linkedin.com/in/'
                                        />
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>Job Preference: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <Select
                                            placeholder='Please select your job preference'
                                            style={{ width: '90%' }}
                                            onChange={this.props.handleChangeJobPreference}
                                            showSearch
                                        >
                                            {sectors.map((sector, index) => {
                                                return (
                                                    <Option key={index} value={sector}>{sector}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Col>
                                </Row>



                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={24} sm={24} xs={24}>
                                        <label style={{ fontWeight: 'bold' }}>Salary Range: </label>
                                    </Col>
                                    <Col lg={14} md={24} sm={24} xs={24}>
                                        <Select
                                            placeholder='Please enter your salary range'
                                            style={{ width: '90%' }}
                                            onChange={this.props.handleChangeSalaryRange}
                                        >
                                            {salaryRange.map((range, index) => {
                                                return (
                                                    <Option key={index} value={range}>{range}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            </div >
        );
    }
}

export default PersonalInformation;