import react, { Component } from 'react';
import { Badge, Col, Progress, Row, Statistic, Typography } from 'antd';
import { themeColor } from '../constants';

class Analytics extends Component {
    render() {
        return (
            <Row gutter={[16, 16]}>
                <Col lg={8} md={24} sm={24} xs={24}>
                    <Typography.Title level={3}>
                        CVs
                    </Typography.Title>
                    <Progress
                        type="circle"
                        strokeColor={themeColor}
                        percent={100}
                        width={100}
                        format={() => <div>
                            <Typography.Text style={{ color: themeColor, margin: 2 }}>
                                {Math.floor(Math.random() * 500000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography.Text>
                        </div>
                        } />
                </Col>

                <Col lg={8} md={24} sm={24} xs={24}>
                    <Typography.Title level={3}>
                        Active Jobs
                    </Typography.Title>
                    <Progress
                        type="circle"
                        strokeColor={themeColor}
                        percent={100}
                        width={100}
                        format={() => <div>
                            <Typography.Text level={3} style={{ color: themeColor, margin: 2 }}>
                                {Math.floor(Math.random() * 500000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography.Text>
                        </div>
                        } />
                </Col>

                <Col lg={8} md={24} sm={24} xs={24}>
                    <Typography.Title level={3}>
                        Companies
                    </Typography.Title>
                    <Progress
                        type="circle"
                        strokeColor={themeColor}
                        percent={100}
                        width={100}
                        format={() => <div>
                            <Typography.Text level={3} style={{ color: themeColor, margin: 2 }}>
                                {Math.floor(Math.random() * 500000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography.Text>
                        </div>
                        } />
                </Col>
            </Row>
        )
    }
}

export default Analytics