import { Typography } from "antd";

export const candidate = 'candidate';
export const employer = 'employer';
export const legal_status = [
    'Public Limited Company',
    'Private Limited Company',
    'Small Business except Company',
    'Central Government',
    'Non-profit Organisation',
    'Trade Union',
    'Government Owned Company',
    'Parastatal Body',
    'Private Household',
    'Local Government'
];
export const districts = [
    'Black River',
    'Flacq',
    'Grand Port',
    'Moka',
    'Pamplemousses',
    'Plaine Wilhems',
    'Port Louis',
    'Riviere du rempart',
    'Rodrigues',
    'Savanne'
];
export const sectors = [
    'Tourism / Travel',
    'ICT / IT / Web',
    'Food / Beverages / Catering',
    'Call Centers / BPO / Customer Service / Languages',
    'Marketing / Sales',
    'Accounting / Auditing / Tax Services / Finance',
    'Administrative / Clerical',
    'Logistics / Warehousing / Distribution / Transport',
    'Engineering / Electronics / Mechanics',
    'Manufacturing / Production',
    'Construction / Architecture / Property',
    'Management',
    'HR / Recruiting',
    'Telecommunications',
    'Environmental / Health and Safety / Quality',
    'Sports / Leisure / Arts',
    'Import / Export',
    'Public Relation / Communication',
    'Training',
    'Purchasing',
    'Textile',
    'Spa',
    'Graphic Design',
    'Education / Training Course',
    'Healthcare / Medical / Veterinary',
    'Insurance',
    'Security / Protective Services',
    'Consulting',
    'Legal',
    'Advertising',
    'Banking',
    'Agriculture / Fishing',
    'Pharmaceutical / Science',
    'Public Sector',
    'Social Services / Community',
    'Others'
];
export const nationalities = [
    'Afghan',
    'Algerian',
    'Angolan',
    'Argentine',
    'Austrian',
    'Australian',
    'Bangladeshi',
    'Belarusian',
    'Belgian',
    'Bolivian',
    'Bosnian/Herzegovinian',
    'Brazilian',
    'British',
    'Bulgarian',
    'Cambodian',
    'Cameroonian',
    'Canadian',
    'Central African',
    'Chadian',
    'Chinese',
    'Colombian',
    'Costa Rican',
    'Croatian',
    'Czech',
    'Congolese',
    'Danish',
    'Ecuadorian',
    'Egyptian',
    'Salvadoran',
    'English',
    'Estonian',
    'Ethiopian',
    'Finnish',
    'French',
    'German',
    'Ghanaian',
    'Greek',
    'Guatemalan',
    'Dutch',
    'Honduran',
    'Hungarian',
    'Icelandic',
    'Indian',
    'Indonesian',
    'Iranian',
    'Iraqi',
    'Irish',
    'Israeli',
    'Italian',
    'Ivorian',
    'Jamaican',
    'Japanese',
    'Jordanian',
    'Kazakh',
    'Kenyan',
    'Lao',
    'Latvian',
    'Libyan',
    'Lithuanian',
    'Malagasy',
    'Malaysian',
    'Malian',
    'Mauritanian',
    'Mauritian',
    'Mexican',
    'Moroccan',
    'Namibian',
    'New Zealand',
    'Nicaraguan',
    'Nigerien',
    'Nigerian',
    'Norwegian',
    'Omani',
    'Pakistani',
    'Panamanian',
    'Paraguayan',
    'Peruvian',
    'Philippine',
    'Polish',
    'Portuguese',
    'Congolese',
    'Romanian',
    'Russian',
    'Saudi, Saudi Arabian',
    'Scottish',
    'Senegalese',
    'Serbian',
    'Singaporean',
    'Slovak',
    'Somalian',
    'South African',
    'Spanish',
    'Sudanese',
    'Swedish',
    'Swiss',
    'Syrian',
    'Thai',
    'Tunisian',
    'Turkish',
    'Turkmen',
    'Ukranian',
    'Emirati',
    'American',
    'Uruguayan',
    'Vietnamese',
    'Welsh',
    'Zambian',
    'Zimbabwean'
];
export const salaryRange = [
    'Confidential',
    'Rs 10,000 - Rs 15,000',
    'Rs 15,000 - Rs 20,000',
    'Rs 20,000 - Rs 25,000',
    'Rs 25,000 - Rs 30,000',
    'Rs 30,000 - Rs 35,000',
    'Rs 35,000 - Rs 40,000',
    'Rs 40,000 - Rs 45,000',
    'Rs 45,000 - Rs 50,000',
    'Above Rs 50,000',
];

export const employerJobSalaryRange = [
    'Hidden',
    '< Rs 10,000',
    'Rs 10,000 - Rs 20,000',
    'Rs 20,000 - Rs 30,000',
    'Rs 30,000 - Rs 40,000',
    'Rs 40,000 - Rs 50,000',
    'Rs 50,000 - Rs 75,000',
    'Rs 75,000 - Rs 100,000',
    '> Rs 100, 000'
];
export const reNumbersOnly = /^[0-9\b]+$/;
export const educationLevel = [
    "Below SC",
    "SC",
    "HSC / Bac",
    "Certificate",
    "Diploma / Bac +2",
    "Bachelor's Degree / Bac + 3",
    "Postgradute / Bac + 4",
    "Master's degree / Bac + 5",
    "Doctorate"
];
export const experienceYears = [
    '< 1 Year',
    '1 - 3 Years',
    '3 - 5 Years',
    '3 - 4 Years',
    '5 - 10 Years',
    '> 10 Years',
];
export const careerLevels = [
    'Student',
    'Trainee',
    'Operatives/Manual Workers',
    'Staff/Clerk',
    'Supervisor',
    'Manager',
    'Managing Director',
    'Consultant',
    'Senior Management',
];
export const skillsLimit = 12;
export const languagesLimit = 4;
export const languagesList = [
    'English',
    'French',
    'Creole',
    'Hindi',
    'Spanish',
    'Arabic',
    'Bengali',
    'Russian'
]
export const languageFluencyList = [
    'Basic',
    'Fluent',
    'Spoken only',
    'Very fluent',
    'Written only'
]
export const hobbiesLimit = 4;
export const noticePeriods = [
    'Immediate effect',
    '1 week',
    '2 weeks',
    '1 month',
    '3 months',
    '> 3 months'
]
export const genders = [
    'Male',
    'Female',
    'Undisclosed'
]

export const jobTypes = [
    'Part time',
    'Full time',
    'Contract',
    'Internship'
]

export const bullet = "\u2022";
export const bulletWithSpace = `${bullet} `;

export const themeColor = '#2D3691';

export const ATS_Status = [
    'New',
    'In Review',
    'Phone Screening',
    'Assessment',
    'Interview',
    'Background check',
    'Offered',
    'Hired',
    'Disqualified'
]


export const Candidate_Status = [
    'Pofile does not match Job Criteria',
    'Candidate shortlisted for Review',
    'Candidate shortlisted for Phone Screening',
    'Unable to contact Candidate',
    'Candidate Interested in job Vacancy',
    'Candidate not Interested in Job Vacancy',
    'Candidate attended Assessment',
    'Candidate did not attend Assesssment',
    'Candidate Shortlisted for 1st Interview',
    'Candidate contacted for 1st Interview',
    'Candidate did not attend 1st Interview',
    '1st Interview Reschedule',
    'Candidate shortlisted for 2nd Interview',
    'Candidate contacted for 2nd Interview',
    'Candidate did not attend 2nd Interview',
    '2nd Interview Reschedule.',
    'Candidate shortlisted for Background check',
    'Candidate Background check - Positive',
    'Candidate Background check - Negative',
    'Potential Candidates for Job Offer',
    'Job Proposal Sent',
    'Job Proposal Rejected by Candidate',
    'Candidate accepted Job Offer',
    'Job Contract Issue',
    'Job Contract Signed',
    'Candidate Decline Employment',
    'Candidate not selected by Employer',
]

export const Candidate_Rating = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
]

export const activeJobs = [
    {
        title: 'Job title',
        dataIndex: 'jobtitle',
        key: 'jobtitle'
    },
    {
        title: 'Closing date',
        dataIndex: 'jobclosingdate',
        key: 'jobclosingdate'
    },
    {
        title: 'Created date',
        dataIndex: 'jobaddedon',
        key: 'jobaddedon'
    },
    {
        title: 'No. of Applications',
        dataIndex: 'applicantsNum',
        key: 'applicantsNum'
    },
]


export const numOfEmployees = [
    '1 - 10',
    '11 - 20',
    '21 - 50',
    '51 - 100',
    '101 - 200',
    '201 - 300',
    '301 - 400',
    '401 - 500',
    '500+'
]

export const services_recruitment_list = [
    'Racing car sprays burning fuel into crowd.',
    'Japanese princess to wed commoner.',
    'Australian walks 100km after outback crash.',
    'Man charged over missing wedding girl.',
    'Los Angeles battles huge wildfires.',
];

export const before_interview_tips = [
    'Dress professionally and make sure you are well groom.'
    , 'Be on time and allow some extra time in case you get run into traffic.'
    , 'Do your research on the company, position and interviewer.'
    , 'Practice your answers and main points without making them sound like rehearsed.'
    , 'Bring any additional materials or information such as CV, Qualifications, credential or attestation'
    , 'Make sure to put your phone on silent mood before the interview'
]

export const during_interview_tips = [
    'Make yourself comfortable',
    'Try to start with a nice and firm handshake.',
    'Be polite, friendly and remember to smile.',
    'Show confident in your body language',
    'Be persuasive when describing your professional experience',
    'Take your time to answer unplanned question',
    'Thank the interviewer for his or her time and finish on a final good impression.',
]

export const after_interview_tips = [
    'Thank you email',
]

export const pricings_packages_sme = [
    // {
    //     type: 'free',
    //     name: 'Package 1',
    //     price: 'Free',
    //     description: ['Unlimited Jobs']
    // },
    {
        type: 'free',
        name: 'SME - Free',
        price: 'Free',
        description: [
            <Typography.Text>
                <span style={{ fontWeight: 'bold' }}>1 </span>job per year,
            </Typography.Text>,
            'Valid for 1 year'
        ]
    },
    {
        type: 'paid',
        name: 'SME - Basic',
        price: 'Rs 6,000.00',
        description: [
            <Typography.Text>
                <span style={{ fontWeight: 'bold' }}>6 </span>jobs per year,
            </Typography.Text>,
            'Valid for 1 year'
        ]
    },
    {
        type: 'paid',
        name: 'SME - Standard',
        price: 'Rs 11,000.00',
        description: [
            <Typography.Text>
                <span style={{ fontWeight: 'bold' }}>12 </span>jobs per year,
            </Typography.Text>,
            'Valid for 1 year'
        ]
    },
    {
        type: 'paid',
        name: 'SME - Premium',
        price: 'Rs 20,000.00',
        description: [
            <Typography.Text>
                <span style={{ fontWeight: 'bold' }}>24 </span>jobs per year,
            </Typography.Text>,
            'Valid for 1 year'
        ]
    },
]

export const pricings_packages_platinum = [
    {
        type: 'paid',
        name: 'Introduction',
        price: 'Rs 13,000.00',
        description: ['Unlimited jobs posting', 'Valid for 3 months']
    },
    {
        type: 'paid',
        name: 'Intermediate',
        price: 'Rs 25,000.00',
        description: ['Unlimited jobs posting', 'Valid for 6 months']
    },
    {
        type: 'paid',
        name: 'Business',
        price: 'Rs 45,000.00',
        description: ['Unlimited jobs posting', 'Valid for 12 months']
    },
    {
        type: 'paid',
        name: 'Premium',
        price: 'Rs 60,000.00',
        description: ['Unlimited jobs posting', 'Valid for 12 months', 'Access to database']
    }
]


export function scrollUp() {
    window.scrollTo(0, 0);
}


export const sizeOfCompany = [
    '1 - 10',
    '11 - 20',
    '21 - 50',
    '51 - 100',
    '101 - 200',
    '201 - 300',
    '301 - 500',
    '501 - 750',
    '751 - 1000',
    'Above 1000'
]