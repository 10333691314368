import React, { Component } from 'react';
import testpp from '../../../images/testpp.jpeg';
import { AiOutlineEdit } from 'react-icons/ai';
import { Button, Col, DatePicker, Select, Divider, Input, Row, Upload, InputNumber, Modal } from 'antd';
import { districts, genders, nationalities, salaryRange, sectors } from '../../../constants';
import moment from 'moment';
import { MdModeEdit } from 'react-icons/md';

const { Option } = Select

class PersonalInformation extends Component {
    render() {
        return (
            <div>
                {/* Edit Personal Information */}
                <Modal
                    title="Edit Personal Information"
                    width={1000}
                    visible={this.props.modalEditPersonalInformation}
                    destroyOnClose={true}
                    footer={[
                        <Button
                            type='primary'
                            onClick={this.props.triggerModalEditPersonalInformation}
                        >
                            Done
                        </Button>
                    ]}
                    onCancel={this.props.triggerModalEditPersonalInformation}
                >
                    <Row>
                        <Col lg={23} md={12} sm={24} xs={24}>
                            <h1>
                                <Input
                                    placeholder='Enter your full name'
                                    style={{ fontSize: 28 }}
                                    defaultValue={this.props.candidateCV.fullName}
                                    onChange={this.props.handleChangeFullName}
                                />
                            </h1>
                            <Row>
                                <Col lg={12} md={24} sm={24} xs={24}>

                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>Date of Birth: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <DatePicker
                                                // onChange={onChange}
                                                placeholder='Select Date of Birth'
                                                style={{ width: '90%' }}
                                                format='DD MMM YYYY'
                                                defaultValue={this.props.candidateCV.dob ? moment(this.props.candidateCV.dob) : null}
                                                onChange={this.props.handleChangeDOB}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>Age: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <Input
                                                // value={this.props.candidateCV.age}
                                                disabled
                                                placeholder='Age'
                                                value={this.props.candidateCV.age}
                                                style={{ width: '90%' }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>Gender: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <Select
                                                placeholder='Please select'
                                                style={{ width: '90%' }}
                                                defaultValue={this.props.candidateCV.gender}
                                                onChange={this.props.handleChangeGender}
                                            >
                                                {genders.map((gender, index) =>
                                                    <Option value={gender}>{gender}</Option>
                                                )}
                                            </Select>

                                        </Col>
                                    </Row>
                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>ID: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <Input
                                                placeholder='Please enter your NIC'
                                                style={{ width: '90%' }}
                                                defaultValue={this.props.candidateCV.id}
                                                onChange={this.props.handleChangeID}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>Home Address: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <Input
                                                placeholder='Please enter your full address'
                                                style={{ width: '90%' }}
                                                defaultValue={this.props.candidateCV.homeAddress}
                                                onChange={this.props.handleChangeHomeAddress}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>District: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <Select
                                                placeholder='Please select your district'
                                                style={{ width: '90%' }}
                                                defaultValue={this.props.candidateCV.district}
                                                onChange={this.props.handleChangeDistrict}
                                                showSearch
                                            >
                                                {districts.map((district, index) => {
                                                    return (
                                                        <Option key={index} value={district}>{district}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>Home Phone No.: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <InputNumber
                                                placeholder='Please enter your home phone number'
                                                style={{ width: '90%' }}
                                                type='number'
                                                controls={false}
                                                defaultValue={this.props.candidateCV.homePhoneNo}
                                                onChange={this.props.handleChangeHomePhoneNum}
                                            />
                                        </Col>
                                    </Row>

                                </Col>
                                <Col lg={12} md={24} sm={24} xs={24}>
                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>Mobile No.: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <InputNumber
                                                placeholder='Please enter your mobile number'
                                                style={{ width: '90%' }}
                                                type='number'
                                                controls={false}
                                                defaultValue={this.props.candidateCV.mobileNo}
                                                onChange={this.props.handleChangeMobileNum}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>Email Address: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <Input
                                                placeholder='Email Address'
                                                style={{ width: '90%' }}
                                                defaultValue={this.props.candidateCV.emailAddress}
                                                onChange={this.props.handleChangeEmailAddress}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>Driving License: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <Select
                                                placeholder='Do you have a driving license?'
                                                style={{ width: '90%' }}
                                                defaultValue={this.props.candidateCV.drivingLicense}
                                                onChange={this.props.handleChangeDrivingLicense}
                                            >
                                                <Option key='y' value="Yes">Yes</Option>
                                                <Option key='n' value="Yes">No</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>Nationality: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <Select
                                                placeholder='Please select your nationality'
                                                style={{ width: '90%' }}
                                                defaultValue={this.props.candidateCV.nationality}
                                                onChange={this.props.handleChangeNationality}
                                                showArrow
                                                showSearch
                                            >
                                                {nationalities.map((nationality, index) => {
                                                    return (
                                                        <Option key={index} value={nationality}>{nationality}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>LinkedIn: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <Input
                                                placeholder='Please enter your LinkedIn profile'
                                                style={{ width: '90%' }}
                                                defaultValue={this.props.candidateCV.linkedIn}
                                                onChange={this.props.handleChangeLinkedIn}
                                            // prefix='https://linkedin.com/in/'
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>Job Preference: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <Select
                                                placeholder='Please select your job preference'
                                                style={{ width: '90%' }}
                                                defaultValue={this.props.candidateCV.jobPreference}
                                                onChange={this.props.handleChangeJobPreference}
                                                showSearch
                                            >
                                                {sectors.map((sector, index) => {
                                                    return (
                                                        <Option key={index} value={sector}>{sector}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Col>
                                    </Row>



                                    <Row className='profile-section-row-information'>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <label style={{ fontWeight: 'bold' }}>Salary Range: </label>
                                        </Col>
                                        <Col lg={14} md={24} sm={24} xs={24}>
                                            <Select
                                                placeholder='Please enter your salary range'
                                                style={{ width: '90%' }}
                                                defaultValue={this.props.candidateCV.salaryRange}
                                                onChange={this.props.handleChangeSalaryRange}
                                            >
                                                {salaryRange.map((range, index) => {
                                                    return (
                                                        <Option key={index} value={range}>{range}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal>


                <Row>
                    <Col lg={18} md={24} sm={10} xs={10}>
                        <h1 className='profile-section-title'>
                            Personal Information
                        </h1>
                    </Col>

                    <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        <Button
                            onClick={this.props.triggerModalEditPersonalInformation}
                            style={{ fontSize: 18, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            Edit <MdModeEdit style={{ marginLeft: 10 }} />
                        </Button>

                    </div>

                    <Divider className='profile-section-title-divider' />

                    <Col lg={24} md={24} sm={24} xs={24}>
                    </Col>
                    <Col lg={6} md={12} sm={24} xs={24}>
                        <img src={testpp} height={300} />
                    </Col>
                    <Col lg={18} md={12} sm={24} xs={24}>
                        <h1 style={{ fontSize: 28 }}>{this.props.candidateCV.fullName}</h1>
                        <Row>
                            <Col lg={12} md={24} sm={24} xs={24}>

                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Date of birth: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.dob}
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Age: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.age}
                                    </Col>
                                </Row>


                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Gender: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.gender}
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>ID: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.id}
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Home Address: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.homeAddress}
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>District: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.district}
                                    </Col>
                                </Row>

                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Home Phone No.: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.homePhoneNo}
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Mobile No.: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.mobileNo}
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Email Address: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.emailAddress}
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Driving License: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.drivingLicense}
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Nationality: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.nationality}
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>LinkedIn: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.linkedIn}
                                    </Col>
                                </Row>
                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Job Preference: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.jobPreference}
                                    </Col>
                                </Row>

                                <Row className='profile-section-row-information'>
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <label>Salary Range: </label>
                                    </Col>
                                    <Col lg={14} md={12} sm={12} xs={12}>
                                        {this.props.candidateCV.salaryRange}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default PersonalInformation