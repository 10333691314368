import { Button, Col, Divider, Input, Modal, Row } from 'antd';
import React, { Component } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdModeEdit } from 'react-icons/md';

const { TextArea } = Input

class ProfileInformation extends Component {
    render() {
        return (
            <div>
                <Modal
                    title="Edit Profile Description"
                    width={1000}
                    visible={this.props.modalEditProfileInformation}
                    destroyOnClose={true}
                    footer={[
                        <Button
                            type='primary'
                            onClick={this.props.triggerModalEditProfileInformation}
                        >
                            Done
                        </Button>
                    ]}
                    onCancel={this.props.triggerModalEditProfileInformation}
                >
                    <Row>
                        <Col lg={24} md={24} sm={24} xs={24} style={{ textAlign: 'justify' }}>
                            <TextArea
                                rows={4}
                                placeholder="Tell us a little bit about yourself"
                                maxLength={1200}
                                showCount
                                defaultValue={this.props.candidateCV.profile}
                                onChange={this.props.handleChangeProfileDescription}
                            />
                        </Col>
                    </Row>

                </Modal>

                <h1 className='profile-section-title'>Profile</h1>
                <Divider className='profile-section-title-divider' />
                <Row>
                    <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        <Button
                            onClick={this.props.triggerModalEditProfileInformation}
                            style={{ fontSize: 18, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            Edit <MdModeEdit style={{ marginLeft: 10 }} />
                        </Button>

                    </div>
                    <Col lg={24} md={24} sm={24} xs={24} style={{ textAlign: 'justify' }}>
                        {this.props.candidateCV.profile ?
                            <p>
                                {this.props.candidateCV.profile}
                            </p>
                            :
                            <p style={{ color: 'gray', fontSize: 25, textAlign: 'center' }}>You have not entered a profile description</p>
                        }
                    </Col>
                </Row>

            </div>
        );
    }
}

export default ProfileInformation;