import React, { Component } from 'react';
import { Button, Card, Checkbox, Col, DatePicker, Divider, Form, Input, List, Row, Select, Typography } from 'antd';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { careerLevels, districts, educationLevel, employerJobSalaryRange, jobTypes, scrollUp, sectors, services_recruitment_list, themeColor } from '../../constants';
import { BsCheck } from 'react-icons/bs';
import ReactQuill from "react-quill"


const { Title, Text } = Typography
const { Option } = Select;

class Service1 extends Component {
    async componentDidMount() {
        scrollUp()
        await this.props.onTopbarKeyChange('services')
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Services',
                path: '#'
            },
            {
                item: 'Recruitment',
                path: '/services/recruitment/'
            }
        ])
    }

    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='Recruitment' />
                <Card style={{ padding: '0 65px 0 65px' }}>
                    <Row gutter={[10, 10]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div style={{ padding: 10, textAlign: 'center' }}>
                                <Title level={4} style={{ color: themeColor }}>
                                    We detect, attract and recruit the best candidate while you focus on your core business. This will
                                    help your organization to save time and make the difference with your competitors. We offer
                                    two(2) type of recruitment services namely:
                                </Title>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={[10, 10]}>

                        <Col xs={24} sm={24} md={24} lg={9}>
                            <div style={{ background: themeColor, padding: 20 }}>
                                <Title level={4} style={{ textAlign: 'center', color: 'white' }}>
                                    Recruitment
                                </Title>

                                <Text style={{ color: 'white' }}>
                                    We take care and manage your entire
                                    recruitment process and only the best
                                    candidates will be forwarded to our clients
                                </Text>
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={6} />
                        <Col xs={24} sm={24} md={24} lg={9}>
                            <div style={{ background: themeColor, padding: 20 }}>
                                <Title level={4} style={{ textAlign: 'center', color: 'white' }}>
                                    CV Sourcing
                                </Title>

                                <Text style={{ color: 'white' }}>
                                    We take the commitment to sent you the
                                    best profiles from our database and
                                    networks within a delay of 7 days
                                </Text>
                            </div>

                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Row gutter={[10, 10]}>
                        <Col span={24}>
                            <Title level={5}>Our process</Title>
                            <List
                                dataSource={[
                                    'Upon submission of request with job description and person specification we meet the client to understand their requirement in details',
                                    'We instantly start the assignment once signature for term of service is finalise',
                                    'Our strategies include job listing on our board and social media, search of candidates in our database and network',
                                    'Diligent screening, interview, test, evaluation and background check are conducted.',
                                    'Progress report is provided for client awareness',
                                    'Only the best profiles who are willing to consider employment in your organization are submitted to clients.',
                                    'Shortlisted candidates are interviewed by client.',
                                    'The client only pay If job offer is successfully made'
                                ]}
                                renderItem={(item) => (
                                    <List.Item style={{ borderBottom: 0 }}>
                                        <BsCheck /> {item}
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Divider />
                    <Title
                        style={{ textAlign: 'center', color: themeColor }}
                        level={3}
                    >
                        Request a Quote
                    </Title>
                    <Form
                        name="hr-consulting-quotation"
                        layout='horizontal'
                        wrapperCol={{
                            lg: 14,
                            md: 12,
                            sm: 24,
                            xs: 24
                        }}
                        labelCol={{
                            lg: 5,
                            md: 8,
                            sm: 24,
                            xs: 24
                        }}
                        labelAlign='left'

                    // onFinish={}
                    >
                        <br />
                        <Title
                            style={{ textAlign: 'left', color: themeColor }}
                            level={4}
                        >
                            Employer's Information
                        </Title>

                        <Form.Item label='Company Name' name={'companyname'}>
                            <Input placeholder='Enter company name' />
                        </Form.Item>

                        <Form.Item label='Contact Name' name={'contactname'}>
                            <Input placeholder='Enter contact name' />
                        </Form.Item>

                        <Form.Item label='Email address' name={'emailaddr'}>
                            <Input placeholder='Enter email address' />
                        </Form.Item>

                        <Form.Item label='Phone No.' name={'phoneno'}>
                            <Input placeholder='Enter Phone No.' />
                        </Form.Item>

                        <Form.Item label='Company Website' name={'companywebsite'}>
                            <Input placeholder='Enter Phone No.' />
                        </Form.Item>

                        <br />

                        <Title
                            style={{ textAlign: 'left', color: themeColor }}
                            level={4}
                        >
                            Candidate's Profile
                        </Title>

                        <Form.Item label='Job title' name={'jobtitle'}>
                            <Input placeholder='Enter Job title' />
                        </Form.Item>

                        <Form.Item label='Job Summary' name={'jobsummary'}>
                            <Input.TextArea rows={4} placeholder='Enter Job summary' />
                        </Form.Item>

                        <Form.Item label='Reporting to:' name={'reportingto'}>
                            <Input placeholder='Enter Reporting line' />
                        </Form.Item>

                        <Form.Item label='Job Category' name={'jobcategory'}>
                            <Select
                                placeholder='Please select category of job'
                                style={{ width: '100%' }}
                                // onChange={this.props.handleChangeEmployerJobCategory}
                                // value={this.props.employer.postNewJob.jobCategory}
                                showSearch
                            >
                                {sectors.map((sector, index) => {
                                    return (
                                        <Option key={index} value={sector}>{sector}</Option>
                                    )
                                })}
                            </Select>

                        </Form.Item>

                        <Form.Item label='Job Type' name={'jobtype'}>
                            <Select
                                placeholder='Please select type of job'
                                style={{ width: '100%' }}
                                onChange={this.props.handleChangeEmployerJobType}
                                value={this.props.employer.postNewJob.jobType}
                                showSearch
                            >
                                {jobTypes.map((type, index) => {
                                    return (
                                        <Option key={index} value={type}>{type}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item label='Job Location' name={'joblocation'}>
                            <Select
                                placeholder='Please select the location of the job'
                                style={{ width: '100%' }}
                                value={this.props.employer.postNewJob.jobLocation}
                                onChange={this.props.handleChangeEmployerJobLocation}
                                showSearch
                            >
                                {districts.map((district, index) => {
                                    return (
                                        <Option key={index} value={district}>{district}</Option>
                                    )
                                })}
                            </Select>                        </Form.Item>

                        <Form.Item label='Minimum Salary' name={'minsalary'}>
                            <Select
                                placeholder='Please select the location of the job'
                                style={{ width: '100%' }}
                                value={this.props.employer.postNewJob.jobSalary}
                                onChange={this.props.handleChangeEmployerJobSalary}
                                showSearch
                            >
                                {employerJobSalaryRange.map((range, index) => {
                                    return (
                                        <Option key={index} value={range}>{range}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item label='Maximum Salary' name={'maxsalary'}>
                            <Select
                                placeholder='Please select the location of the job'
                                style={{ width: '100%' }}
                                value={this.props.employer.postNewJob.jobSalary}
                                onChange={this.props.handleChangeEmployerJobSalary}
                                showSearch
                            >
                                {employerJobSalaryRange.map((range, index) => {
                                    return (
                                        <Option key={index} value={range}>{range}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item label='Fridge Benefit if any:' name={'FridgeBenefit'}>
                            <Input placeholder='Enter Fridge Benefit' />
                        </Form.Item>

                        <Form.Item label='Career Level:' name={'CareerLevel'}>
                            <Select
                                placeholder='Please select the career level of the job'
                                style={{ width: '100%' }}
                                value={this.props.employer.postNewJob.jobCareerLevel}
                                onChange={this.props.handleChangeEmployerJobCareerLevel}
                                showSearch
                            >
                                {careerLevels.map((level, index) => {
                                    return (
                                        <Option key={index} value={level}>{level}</Option>
                                    )
                                })}
                            </Select>                        </Form.Item>

                        <Form.Item label='Qualification Required::' name={'QualificationRequired'}>
                            <Select
                                placeholder='Please select qualifications required for the job'
                                style={{ width: '100%' }}
                                onChange={this.props.handleChangeEmployerJobQualificationsRequired}
                                value={this.props.employer.postNewJob.jobQualificationsRequired}
                                showSearch
                            >
                                {educationLevel.map((level, index) => {
                                    return (
                                        <Option key={index} value={level}>{level}</Option>
                                    )
                                })}
                            </Select>                        </Form.Item>

                        <Form.Item label='Qualification / Experience:' name={'QualificationAndExperience'}>
                            {/* <Input placeholder='Enter Qualification / Experience' /> */}
                            <ReactQuill
                                theme='snow'
                                // value={'test'}
                                placeholder='Enter Qualification / Experience'
                            // onChange={setConvertedText}
                            // style={{ minHeight: '200px' }}
                            />
                        </Form.Item>

                        <Form.Item label='Roles / Responsibilities:' name={'RolesAndResponsibilities'}>
                            <ReactQuill
                                theme='snow'
                                // value={'test'}
                                placeholder='Enter Roles / Responsibilities'
                            // onChange={setConvertedText}
                            // style={{ minHeight: '200px' }}
                            />
                            {/* <Input placeholder='Enter Roles / Responsibilities' /> */}

                        </Form.Item>

                        <Form.Item label='Other requirements:' name={'OtherRequirements'}>
                            <Input.TextArea placeholder='Enter Other requirements' />
                        </Form.Item>

                        <Form.Item label='Remarks:' name={'Remarks'}>
                            <Input.TextArea placeholder='Enter Remarks' />
                        </Form.Item>

                        <Form.Item label='Tentative Starting Date:' name={'TentativeStartingDate'}>
                            <DatePicker
                                format='DD MMM YYYY'
                                // style={{ width: '100%' }}
                            // picker='month'
                            // disabledDate={this.disabledStartDate}
                            // onChange={this.props.handleChangeEmployerJobStartDate}
                            // value={this.props.employer.postNewJob.jobAddedDate}
                            />
                        </Form.Item>

                        <Form.Item label='Choose type of service:' name={'serviceType'}>
                            <Checkbox>
                                Recruitment
                            </Checkbox>
                            <br />
                            <Checkbox>
                                CV Sourcing
                            </Checkbox>
                        </Form.Item>


                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                            >
                                Submit Request
                            </Button>
                        </Form.Item>


                    </Form>
                </Card>
            </div>
        );
    }
}

export default Service1;