import { Col, Row, Table } from 'antd';
import React, { Component } from 'react';
import BreadcrumbNav from '../../components/BreadcrumbNav';
import HeaderTitle from '../../components/HeaderTitle';
import { activeJobs, scrollUp } from '../../constants';

class ActiveJobs extends Component {
    async componentDidMount() {
        scrollUp()
        await this.props.handleBreadCrumbItems([
            {
                item: 'Home',
                path: '/'
            },
            {
                item: 'Dashboard',
                path: '/dashboard/'
            },
            {
                item: 'Active jobs',
                path: '/dashboard/job/active/'
            },
        ])
    }
    render() {
        return (
            <div>
                <BreadcrumbNav {...this.props} />
                <HeaderTitle title='All active jobs' />
                <Row style={{ textAlign: 'center', marginTop: '22px' }}>
                    <h2>Select the job you want to edit</h2>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Table
                            columns={activeJobs}
                            dataSource={this.props.searchResultsTableData}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        window.location.href = `#/dashboard/job/active/edit/' + ${record.jobtitle}/`;
                                    }, // click row
                                    onDoubleClick: event => { }, // double click row
                                    onContextMenu: event => { }, // right button click row
                                    onMouseEnter: event => { }, // mouse enter row
                                    onMouseLeave: event => { }, // mouse leave row
                                };
                            }}
                        />
                    </Col>
                </Row>

            </div>
        );
    }
}

export default ActiveJobs;