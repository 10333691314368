import React, { Component } from 'react';
import { Button, Col, Row } from 'antd';
import PersonalInformation from '../../components/cv_create/PersonalInformation';
import ProfileInformation from '../../components/cv_create/ProfileInformation';
import WorkExperience from '../../components/cv_create/WorkExperience';
import Education from '../../components/cv_create/Education';
import CareerSummary from '../../components/cv_create/CareerSummary';
import Skills from '../../components/cv_create/Skills';
import Languages from '../../components/cv_create/Languages';
import Hobby from '../../components/cv_create/Hobby';
import NoticePeriod from '../../components/cv_create/NoticePeriod';
import Referral from '../../components/cv_create/Referral';

class CreateCV extends Component {
    componentDidMount() {
        this.props.onTopbarKeyChange('')
    }
    render() {
        return (
            <div>
                <h1 style={{ textAlign: 'center', fontSize: 30 }}>
                    Create Your CV
                </h1>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <PersonalInformation
                            {...this.props}
                        />
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <ProfileInformation
                            {...this.props}
                        />
                    </Col>
                    <Col lg={11} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px', paddingRight: 5 }}>
                        <WorkExperience
                            {...this.props}
                        />
                    </Col>
                    <Col lg={1} md={0} sm={0} xs={0}></Col>

                    <Col lg={12} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <Education
                            {...this.props}
                        />
                    </Col>

                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <CareerSummary
                            {...this.props}
                        />
                    </Col>

                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <Skills
                            {...this.props}
                        />
                    </Col>

                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <Languages
                            {...this.props}
                        />
                    </Col>

                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <Hobby
                            {...this.props}
                        />
                    </Col>

                    <Col lg={11} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px', paddingRight: 5 }}>
                        <NoticePeriod
                            {...this.props}
                        />
                    </Col>
                    <Col lg={1} md={0} sm={0} xs={0}>
                    </Col>

                    <Col lg={12} md={24} sm={24} xs={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <Referral
                            {...this.props}
                        />
                    </Col>

                    <Col lg={24} md={24} sm={24} xs={24} style={{textAlign: 'right'}}>
                        <Button
                            style={{ width: '100%', padding: 5, height: 'auto', fontSize: 22 }}
                            type='primary'
                            onClick={this.props.validateAndCreateCV}
                        >
                            Create CV
                        </Button>
                    </Col>

                </Row>
            </div>
        )
    }
}

export default CreateCV;